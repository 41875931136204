import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Debug } from '@app/core/debug';
import { NgbDateParserFormatter, NgbDateStruct, Placement } from '@ng-bootstrap/ng-bootstrap';
import { AbstractValueAccessor, makeProvider } from '../abstract-value-accessor';
import { SaneDateParserFormatter } from '../date-parser-formatter';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [makeProvider(() => DatePickerComponent), { provide: NgbDateParserFormatter, useClass: SaneDateParserFormatter }]
})
export class DatePickerComponent extends AbstractValueAccessor implements OnChanges, OnInit {

  @Input() name: string;
  @Input() placeholderLabel?: string;
  @Input() isRequired = false;
  @Input() min?: Date;
  @Input() max?: Date;
  @Input() placement: Partial<Placement>[] = ['bottom', 'top'];
  @Input() isDisabled?: boolean;
  @Input() isReadOnly = false;
  @Input() initialValue?: Date | string = null;

  dtpValue: NgbDateStruct;
  dtpMinDate: NgbDateStruct;
  dtpMaxDate: NgbDateStruct;

  constructor(private readonly parserFormatter: SaneDateParserFormatter) {
    super();

    this.placeholderLabel = SaneDateParserFormatter.outputFormat.toLowerCase();
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.onValueSet(this.initialValue);
    }
  }

  onValueSet(value: string | Date | NgbDateStruct) {
    if (value instanceof Date || typeof value == 'string') {
      const o = this.parserFormatter.createNgbDateStruct(value);
      // if any values are invalid or blank, reset to null
      this.dtpValue = !o.day || !o.year || !o.month ? null : o;
      if (!this.dtpValue) {
        Debug.debug('Invalid date detected, resetting dtpValue to null', o);
      }
    } else {
      Debug.debug('Updating dtpValue', value);
      this.dtpValue = value;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const min = changes.min;
    if (Object.isDefined(min)) {
      this.dtpMinDate = this.parserFormatter.createNgbDateStruct(min.currentValue);
    }

    const max = changes.max;
    if (Object.isDefined(max)) {
      this.dtpMaxDate = this.parserFormatter.createNgbDateStruct(max.currentValue);
    }
  }

  clearIfInvalid() {
    if (!this.value) {
      this.dtpValue = null;
    }
  }

  onDtpValueChanged() {
    const value = this.parserFormatter.toValidDate(this.dtpValue);
    // use changeValue instead of setting value directly so that we don't invoke onValueSet for this
    this.changeValue(value);
  }
}
