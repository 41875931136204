import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Address } from '../address';
import { CompleteAddressValidatorOptions } from './complete-address.validator';
import { AddressFieldComponentOptions, AddressFieldDisplayType, AddressFieldLabelOptions } from './options';

@Component({
  selector: 'address-field',
  templateUrl: './address-field.component.html',
  styleUrls: ['./address-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, deps: [NgForm], useExisting: NgForm }]
})
export class AddressFieldComponent implements OnChanges {

  /* Implementing ControlValueAccessor to make NgModel work with this component had some strange
   * side effects and had to be abandoned. For the time being, a value binding is being used
   * instead.
   **/
  @Input() value;
  @Output() valueChange: EventEmitter<Address>;

  @Input() name: string;
  @Input() isRequired?: boolean;
  @Input() isDisabled?: boolean;
  @Input() options?: AddressFieldComponentOptions;
  @Input() labels?: AddressFieldLabelOptions;

  completeAddressOptions: CompleteAddressValidatorOptions;
  address: Address;
  displayType: AddressFieldDisplayType;
  // needed for ngSwitch statement
  allDisplayTypes = AddressFieldDisplayType;

  constructor() {
    this.displayType = AddressFieldDisplayType.TextOnly;
    this.labels = { unitNumber: 'address.unit-number' };
    this.address = {} as Address;
    this.valueChange = new EventEmitter<Address>();
  }

  ngOnChanges(changes: SimpleChanges) {
    const optChange = changes.options;
    if (Object.isDefined(optChange)) {
      const opts: AddressFieldComponentOptions = optChange.currentValue || {};
      this.options = opts;
      this.displayType = Object.isDefined(opts.displayType) ? opts.displayType : AddressFieldDisplayType.TextOnly;
      this.completeAddressOptions = {
        active: opts.requireCompleteAddress,
        requireDistrict: opts.requireDistrict,
        requireUnitNumber: opts.requireUnitNumber
      };
    }

    const lblChange = changes.labels;
    if (Object.isDefined(lblChange)) {
      this.labels = this.labels || {};
      this.labels.unitNumber = this.labels.unitNumber || 'address.unit-number';
    }

    const valChange = changes.value;
    if (Object.isDefined(valChange)) {
      this.address = Object.isDefined(valChange.currentValue) ? valChange.currentValue : {};
    }
  }

  get displayTypeCanBeChangedByUser() {
    return !this.isDisabled && (this.options.allowDisplayTypeChange);
  }

  get requireUnitNumber() {
    return this.isRequired && (this.options.requireUnitNumber);
  }

  setManualDisplay() {
    this.displayType = AddressFieldDisplayType.Manual;
  }

  setAutocompleteDisplay() {
    this.displayType = AddressFieldDisplayType.AutoComplete;
  }

  handleAddressChange(value: Address) {
    this.address = Object.isDefined(value) ? this.value : {} as Address;
    // emit the actual value regardless of whether or not it's null
    this.value = value;
    this.valueChange.emit(value);
  }

  handleUnitNumberChange() {
    this.value = this.address;
    this.valueChange.emit(this.value);
  }
}
