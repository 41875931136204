import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements AfterViewInit, OnDestroy {
  private static focusTypes: ReadonlyArray<string> = ['INPUT', 'SELECT', 'TEXTAREA', 'A', 'AREA', 'BUTTON'];

  private static focusQuery = 'input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), a[href], area';

  @Input() autofocus: boolean | '' = true;

  private handler: () => void;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    if (Object.isDefined(this.autofocus) && this.autofocus === false) {
      return;
    }

    this.handler = () => this.focus(this.el.nativeElement);
    this.el.nativeElement.addEventListener('refocus', this.handler);

    this.handler();
  }

  ngOnDestroy() {
    this.el.nativeElement.removeEventListener('refocus', this.handler);
  }

  private focus(elem: any) {
    const tagName = String(elem.tagName).toLocaleUpperCase(),
      isDisabled = Object.isDefined(elem.attributes.disabled);

    if (tagName === 'A' && String.isNotEmpty(elem.attributes.href)) {
      return;
    }

    if (AutofocusDirective.focusTypes.some(x => x === tagName) && !isDisabled) {
      this.el.nativeElement.focus();
      return;
    }

    const childElem = elem.querySelector(AutofocusDirective.focusQuery);
    if (Object.isDefined(childElem)) {
      childElem.focus();
      return;
    }
  }
}
