import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Debug } from '../debug';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  static readonly defaultGroupKey: string = 'default';

  private resources: Map<string, GroupCache>;
  private promises: Map<string, Promise<any>>;

  constructor(private readonly http: HttpClient) {
    this.promises = new Map<string, Promise<any>>();
    this.resources = new Map<string, GroupCache>();
    this.load();
  }

  load(groupKey?: string): Promise<any> {
    groupKey = String.isNotEmpty(groupKey) ? groupKey : ResourceService.defaultGroupKey;

    // was it already loaded?
    if (this.resources.has(groupKey)) {
      return Promise.resolve();
    } else if (!this.promises.has(groupKey)) {
      this.promises.set(groupKey, this.loadGroup(groupKey));
    }
    /* Note that in all instances load does not need to return the resource/group object. The expectation is
     * to call either getResource or getFormattedResource.
     **/
    return this.promises.get(groupKey);
  }

  private loadGroup(group: string) {
    const remoteUri = `${environment.contentApiUri}/resource/${environment.clientId}/en-us/${encodeURIComponent(group)}/`;
    const localUri = `assets/resources/${group}.json`;

    const catchHandler = catchError((err) => {
      Debug.log(`[resource] "${group}" load failed: ${err.url}`);
      // if no data, set empty object to prevent future requests for this group
      return of(<any>{});
    });

    const obvs = [
      this.http.get<JsonObject>(remoteUri).pipe(catchHandler),
      this.http.get<ResourceFile>(localUri).pipe(
        map((results) => results.values),
        catchHandler
      )
    ];

    return forkJoin(obvs)
      .pipe(
        tap((results) => {
          this.resources.set(group, { remote: results[0], local: results[1] });
          this.promises.delete(group);
        })
      )
      .toPromise();
  }

  getResource(key: string, groupKey?: string) {
    if (String.isUndefinedOrEmpty(key)) {
      return '';
    }

    groupKey = String.isNotEmpty(groupKey) ? groupKey : ResourceService.defaultGroupKey;
    const group = this.resources.get(groupKey);
    // check if the group was loaded
    if (Object.isDefined(group)) {
      // if it was, prefer remote version over local version or, if all else fails, return the key
      const remote = group.remote[key];
      if (String.isNotEmpty(remote)) {
        return remote;
      }

      const local = group.local[key];
      if (Object.isDefined(local)) {
        return typeof local === 'string' ? local : local.value;
      }
    } else if (!this.promises.has(groupKey)) {
      // start load. Since filter is stateful, it will be updated eventually
      this.load(groupKey);
    }
    // always return key as placeholder when request is pending or value wasn't found
    return key;
  }

  getFormattedResource(key: string, groupKey: string, formatVariables: string | number | Array<string | number>) {
    const resource: string = this.getResource(key, groupKey);

    if (Object.isUndefined(formatVariables) || resource === key) {
      return resource;
    }

    const replacements = Array.isArray(formatVariables) ? formatVariables : [formatVariables];

    return resource.replace(/{(\d+)}/g, (match: string, num: number) => {
      const replacement = replacements[num];
      return Object.isDefined(replacement) ? replacement.toString() : '';
    });
  }
}

interface GroupCache {
  remote: JsonObject;
  local: ResourceCollection;
}
interface ResourceFile {
  clientId: string;
  group: string;
  values: ResourceCollection;
}
interface ResourceCollection {
  [key: string]: string | ResourceValue;
}
interface ResourceValue {
  value: string;
  description: string;
}
