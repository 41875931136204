import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractValueAccessor, makeProvider } from '@app/core/forms/abstract-value-accessor';
import { Resource } from '@app/core/text-resources';

@Component({
  selector: 'hasbro-choice',
  templateUrl: './hasbro-choice.component.html',
  styleUrls: ['./hasbro-choice.component.scss'],
  providers: [
    makeProvider(() => HasbroChoiceComponent)
  ]
})
export class HasbroChoiceComponent extends AbstractValueAccessor implements OnInit {

  private static DefaultChoices: HasbroChoice[] = [
    { label: { key: 'Yes', group: 'default' }, iconClass: 'fa-check icon-yes', value: true, selectionClass: 'selected-yes' },
    { label: { key: 'No', group: 'default' }, iconClass: 'fa-times icon-no', value: false, selectionClass: 'selected-no' }
  ];

  private static licensedAndBondedName = 'is-licensed-and-bonded';

  @ViewChild('choiceRow', { static: false }) choiceRow: ElementRef;
  @Input() name: string;
  @Input() label: string | Resource;
  @Input() subLabel: string | Resource;
  @Input() useMarkdownLabel?: boolean;
  @Input() tooltipTitle?: string | Resource;
  @Input() tooltipMessage?: string | Resource;
  @Input() choices?: HasbroChoice[];
  @Input() required?: any;
  @Input() mini = false;

  selected: HasbroChoice;
  allowedValues: any[];
  touched = false;
  hasValueError = false;

  constructor() {
    super();
  }

  ngOnInit() {
    if (Object.isUndefined(this.choices)) {
      this.choices = HasbroChoiceComponent.DefaultChoices;
    }
    this.allowedValues = this.choices.map(x => x.value);
  }

  writeValue(value) {
    if (Object.isUndefined(this.selected) || (value !== this.selected.value)) {
      super.writeValue(value);
      for (const choice of this.choices) {
        if (choice.value === value) {
          this.selected = choice;
          break;
        }
      }
    }
  }

  select(value: HasbroChoice, index: number) {
    this.selected = value;
    this.value = value.value;

    const inputs = this.choiceRow.nativeElement.querySelectorAll('input');
    inputs[index].focus();
  }

  getSelectedCss(choice: HasbroChoice): string {
    if (choice === this.selected) {
      return choice.selectionClass || 'selected';
    } else {
      return '';
    }
  }

  handleBlur() {
    this.touched = true;
    this.fireOnTouched();
  }

  get hasTooltip() {
    return Object.isDefined(this.tooltipMessage);
  }

  get isRequired() {
    return Object.isDefined(this.required);
  }

  get hasSuccess() {
    return !this.hasError && Object.isDefined(this.selected); // It may not be an error to be empty, but it's not success either
  }

  get hasError() {
    return this.hasRequiredError || this.hasValueError;
  }

  get hasRequiredError() {
    return this.touched && Object.isUndefined(this.selected) && this.isRequired;
  }

  get isLicensedAndBonded() {
    return Object.isDefined(this.name) && (String.equalsIgnoreCase(this.name, HasbroChoiceComponent.licensedAndBondedName));
  }
}

export interface HasbroChoice {
  label: string | Resource;
  value: any;
  iconClass?: string;
  imgSrc?: string;
  selectionClass?: string;
}
