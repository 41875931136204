import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@env';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription, firstValueFrom, from, of, timer } from 'rxjs';
import { catchError, filter, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Debug } from '../debug';
import { NotificationService } from '../notification-service';
import { UserType } from '../user';
import { WindowService } from '../window.service';
import { UserProfile } from './user-profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService implements OnDestroy {
  private static readonly refreshStorageKey = 'devRefreshKey';

  private profile: UserProfile;
  private request: Observable<UserProfile>;
  private requestSub: Subscription;
  private timerSub: Subscription;

  // This is used to determine if a redirect to login is needed if polling fails
  // If the user started portal-client with credentials then they will be redirected if polling fails
  // If the user started without credentials then they don't need to be redirected if they are on an unprotected route such as onboarding/admin-profile
  private readonly enteredPortalClientWithLogin: boolean = false;
  private readonly protectedRouteReset: Subscription;
  private devOAuthReady = false;
  private readonly loginUrl = `${environment.portalApiUri}authentication/login`;

  /**
   * We can use the dev mode login instead of portal if we are in dev mode
   * or if we are running locally pointed to portal since cookies don't work across domains.
   */
  readonly useDevModeLogin: boolean = false;

  isProtectedRoute: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private readonly notify: NotificationService,
    private readonly windowService: WindowService,
    private readonly cookies: CookieService,
    private readonly router: Router,
    private readonly gtmService: GoogleTagManagerService,
    private readonly oAuthService: OAuthService,
    private readonly location: LocationStrategy) {

    const portalDomain = new URL(environment.portalApiUri).hostname;
    const currentDomain = window.location.hostname;

    // Dev logins are allowed when the dev bar is shown or when the portal domain doesn't match portal-client
    // This happens when running portal-client locally but pointed to mydev portal which causes an infinite redirect
    this.useDevModeLogin = this.windowService.isLocalhost() || environment.showDevBar || portalDomain !== currentDomain;

    this.enteredPortalClientWithLogin = this.useDevModeLogin ? Object.isDefined(this.getRefreshToken()) : this.hasLoginCookies();

    this.protectedRouteReset = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        this.isProtectedRoute = false; // reset, guard will set to true if route is protected
      });
  }

  ngOnDestroy(): void {
    this.protectedRouteReset.unsubscribe();
  }

  /**
   * Checks whether or not MYRPS login cookies are present
   * @returns true if MYRPS login cookies are present, false otherwise
   */
  hasLoginCookies() {
    const cookiePattern = /^.MYRPS_\d+$/;
    return Object.keys(this.cookies.getAll()).some(k => cookiePattern.test(k));
  }

  isLocalhost() {
    return this.windowService.isLocalhost();
  }

  getProfile(): Promise<UserProfile> {
    if (Object.isUndefined(this.request)) {
      const prof = this.profile || this.createEmptyProfile();
      return Promise.resolve(prof);
    }
    return firstValueFrom(this.request);
  }

  /**
   * Shows a toast and redirects to either dev login or portal if the token is invalid
   */
  expiredSessionRedirect(): void {
    Debug.debug('ProfileService', 'Session has expired, redirecting to login..');
    this.notify.showToast('Session expired,\n logging out..').onHidden.pipe(take(1)).subscribe(() => this.redirectToLogin());
  }

  /**
   * Redirects to portal login and sets portal client as the return url.
   * @param employee true if the user is an employee; false otherwise
   */
  redirectToLogin(employee = false) {
    // only allow oauth login from localhost - we should not be using this in dev/stage/prod
    if (this.useDevModeLogin && this.windowService.isLocalhost()) {
      this.devOAuthLogin(employee);
    } else {
      let destination = this.loginUrl + `?returnUrl=${window.location.href}`;
      if (employee) {
        destination += '&employee=true';
      }
      Debug.debug('ProfileService', `Redirecting to ${destination}`);
      window.location.assign(destination);
    }
  }

  async isInternalUser() {
    const profile = await this.getProfile();
    return profile.isAuthenticated && profile.userType === UserType.Internal;
  }

  async isExternalUser() {
    return !(await this.isInternalUser());
  }

  /**
   * Starts the portal polling process.
   */
  startPolling() {
    if (environment.bypassAuth) {
      return; // Don't poll if we're not authenticating
    }
    this.stopPolling();
    this.poll();
  }

  /**
   * Stops the portal polling process.
   */
  stopPolling() {
    this.clearTimer();
    this.clearRequest();
    this.profile = null;
  }

  /**
   * Refreshes the token from portal and schedules the next poll before the token expires.
   */
  private poll() {
    let interval = 60000;
    this.request = this.http.get<UserProfile>(environment.portalApiUri + 'api/profile', { withCredentials: true })
      .pipe(
        tap((profile: UserProfile) => {
          if (profile.accessToken?.expiresAt) {
            const initializedAt = new Date(profile.accessToken.initializedAt);
            const expiresAt = new Date(profile.accessToken.expiresAt);
            const secondsUntilExpiration = (expiresAt.getTime() - initializedAt.getTime()) / 1000;
            if (secondsUntilExpiration <= 0) {
              this.expiredSessionRedirect(); // already expired, redirect to portal login
            } else {
              interval = this.calculatePollingInterval(secondsUntilExpiration); // determine the next polling interval
            }
          }
          return profile;
        }),
        catchError((err: HttpErrorResponse) => {
          Debug.log(`[Profile Service] polling request failed; returned a ${err.status} status code`, err);
          return of<UserProfile>(this.createEmptyProfile());
        }),
        finalize(() => {
          this.clearTimer();
          this.timerSub = timer(interval).subscribe(() => this.poll()); // schedule the next poll from the interval calculated above
        }),
        shareReplay(1)
      );

    this.requestSub = this.request.subscribe(profile => {
      this.profile = profile || this.createEmptyProfile();

      this.clearRequest();

      if (!this.profile.permissions && !environment.bypassAuth && !this.useDevModeLogin) {
        if (this.enteredPortalClientWithLogin || this.isProtectedRoute) {
          this.expiredSessionRedirect();
        }
      }

      // Send the user ID to google for tracking
      if (profile && profile.id && profile.isAuthenticated) {
        const dataLayer = this.gtmService.getDataLayer();
        dataLayer.push({ userId: profile.id });
      }
    });
  }

  private clearRequest() {
    if (Object.isDefined(this.requestSub)) { this.requestSub.unsubscribe(); }
    this.request = null;
    this.requestSub = null;
  }

  private clearTimer() {
    if (Object.isDefined(this.timerSub)) { this.timerSub.unsubscribe(); }
    this.timerSub = null;
  }

  private createEmptyProfile(): UserProfile {
    return {
      id: null,
      isAuthenticated: false,
      isImpersonated: false,
      firstName: null,
      lastName: null,
      username: null,
      permissions: null,
      roles: null,
      accessToken: null,
      organizationCode: null,
      isAccountExec: null,
      aimUserId: null
    };
  }

  /**
   * Calculates the next polling interval as 80% of the time until the token expires
   * @param expiresIn seconds until token expiration
   * @returns next polling interval in ms
   */
  private calculatePollingInterval(expiresIn: number) {
    if (expiresIn < 0) {
      Debug.debug('[Profile Service] Next poll in 60 seconds');
      return 60000;
    }
    const intervalInSeconds = expiresIn * 0.8; // poll before expiration (80% of expire time)
    Debug.debug('[Profile Service] Next poll in ' + Math.round(intervalInSeconds) + ' seconds');
    return intervalInSeconds * 1000; // interval in ms
  }

  /* #############################################################################
   * Everything below this point is intended for DEV USE ONLY
   * #############################################################################
   */

  // called at initial app load to configure oauth and login if possible
  devStartup(attemptLogin = true): Observable<UserProfile> {
    if (environment.production) {
      throw new Error('devStartup should not be called in production');
    }

    // configure oauth client
    this.oAuthService.configure({
      issuer: environment.securityIdentityServerUrl,
      redirectUri: window.location.origin + this.location.getBaseHref(),
      clientId: environment.clientId,
      responseType: 'code',
      scope: environment.clientScope,
      showDebugInformation: true,
      dummyClientSecret: environment.clientSecret,
      logoutUrl: environment.securityIdentityServerUrl + '/connect/endsession',
      postLogoutRedirectUri: window.location.origin
    });

    if (!attemptLogin) {
      // override attemptLogin if we have an oauth callback
      attemptLogin = String.startsWith(this.windowService.nativeWindow.location.search, '?code=');
    }

    // pull the discovery document and try to login as we could be here due to a callback
    return from(this.oAuthService.loadDiscoveryDocument())
      .pipe(
        // load discovery
        switchMap((oAuthEvt) => {
          this.devOAuthReady = oAuthEvt.type == 'discovery_document_loaded';
          return attemptLogin && this.devOAuthReady ? from(this.oAuthService.tryLogin()) : of(false);
        }),
        // check login and return profile
        switchMap((success) => {
          if (success) {
            this.request = from(this.handleOAuthCallback());
            return this.request;
          }
          return of(null);
        }),
        catchError((err) => {
          Debug.info('Unable to load security OIDC discovery document', err);
          return of(null);
        }));
  }

  // initiates the oauth login process
  devOAuthLogin(employee = false) {
    if (environment.production) {
      throw new Error('devOAuthLogin should not be called in production');
    }

    if (!this.devOAuthReady) {
      Debug.warn('[Profile Service] devOAuthLogin called but oAuth is not ready');
      return;
    }

    this.devOAuthLogout(true);
    /* this will cause a redirect to security's login
     * security will then redirect back to portal which will cause devStartup to be called once again
     * devStartup will handle the callback and log the user in (if successful)
      **/
    const params = employee ? { 'acr_values': 'idp:AJG' } : {};
    this.oAuthService.initCodeFlow(null, params);
  }

  devOAuthLogout(noRedirect = false) {
    if (environment.production) {
      throw new Error('devOAuthLogout should not be called in production');
    }

    this.stopPolling();
    this.storeRefreshToken(null);
    this.profile = this.createEmptyProfile();
    this.oAuthService.logOut(noRedirect);
  }

  private async handleOAuthCallback(): Promise<UserProfile> {
    if (!this.oAuthService.hasValidAccessToken()) {
      Debug.info('[Profile Service] OAuth login unsuccessful; access token missing or invalid');
      return this.createEmptyProfile();
    }

    let response: UserInfoResponse = null;
    try {
      // loadUserProfile returns an object that has an info property and this value matches our UserInfoResponse interface
      response = ((await this.oAuthService.loadUserProfile()) as any)?.info as UserInfoResponse;
    }
    catch (e) {
      Debug.error('[Profile Service] Error loading oauth profile', e);
      return this.createEmptyProfile();
    }

    // oauthService will handle token storage so we can clear out what we have
    this.storeRefreshToken(null);

    const prof = this.createProfile(response);

    // calculate token expiration using values from the service
    const expiresAt = this.oAuthService.getAccessTokenExpiration();
    prof.accessToken = {
      value: this.oAuthService.getAccessToken(),
      // we don't have an init date but we do know that tokens last for 5 minutes
      initializedAt: new Date(expiresAt - 5 * 60 * 1000),
      expiresAt: new Date(expiresAt),
      expiresIn: 0
    };
    prof.accessToken.expiresIn = (prof.accessToken.expiresAt.getTime() - prof.accessToken.initializedAt.getTime()) / 1000;

    Debug.info('[Profile Service] profile created', prof);
    this.trackUserInGoogleAnalytics(prof.id);
    this.profile = prof;
    this.startDevTokenRefreshPolling();
    return prof;
  }

  /* devInitUser is used only by onboarding. if this can be refactored out, we can remove our dev refreshing code in
   * favor of letting the OAuth service handle it. it has a built in refreshing process and events we can listen to
   * to get the new token when it is refreshed.
   **/
  devInitUser(accessToken: string, refreshToken: string): Observable<UserProfile> {
    if (environment.production) {
      throw new Error('devInitUser should not be called in production');
    }

    this.request = this.refreshToken(refreshToken).pipe(
      switchMap((token) => {
        const info = this.tryGetUserInfoFromJwt(token.access_token);
        if (info) {
          return of({ user: info, token: token });
        }
        return this.getUserInfo(token.access_token).pipe(
          map(user => {
            return { user: user, token: token };
          }));
      }),
      switchMap((response) => {
        this.storeRefreshToken(refreshToken);
        const prof = this.createProfile(response.user);
        prof.accessToken = {
          value: response.token.access_token,
          initializedAt: new Date(response.user.auth_time * 1000),
          expiresAt: new Date(response.user.exp * 1000),
          expiresIn: response.user.exp - response.user.auth_time
        };
        return of(prof);
      }),
      catchError((err) => {
        Debug.error('[Profile Service] error initializing user', err);
        return of(this.createEmptyProfile());
      })
    );

    this.requestSub = this.request.subscribe((profile) => {
      Debug.info('[Profile Service] profile created', profile);
      this.profile = profile;
      this.trackUserInGoogleAnalytics(profile.id);
      this.startDevTokenRefreshPolling();
    });

    return this.request;
  }

  private trackUserInGoogleAnalytics(id: number) {
    const dataLayer = this.gtmService.getDataLayer();
    dataLayer.push({ userId: id });
  }

  private createProfile(response: UserInfoResponse) {
    const prof = this.createEmptyProfile();
    prof.id = parseInt(response.sub);
    prof.username = response.email;
    prof.firstName = response.given_name;
    prof.lastName = response.family_name;
    prof.isAuthenticated = true;
    prof.permissions = response.permission;
    prof.roles = response.persona;
    if (Object.isDefined(response.organization_code)) {
      prof.userType = UserType.External | parseInt(response[`${response.organization_code}_type`]);
      prof.organizationCode = response.organization_code;
    } else {
      prof.userType = UserType.Internal;
      prof.aimUserId = response.aim_id;
      prof.isAccountExec = response.account_exec;
    }
    return prof;
  }

  private storeRefreshToken(value: string) {
    if (value) {
      this.windowService.nativeWindow.localStorage.setItem(ProfileService.refreshStorageKey, value);
    }
    else {
      this.windowService.nativeWindow.localStorage.removeItem(ProfileService.refreshStorageKey);
    }
  }

  private getRefreshToken() {
    return this.windowService.nativeWindow.localStorage.getItem(ProfileService.refreshStorageKey);
  }

  /**
   * Refreshes an access token
   * @param creds The refresh token
   * @returns observable of the token response
   */
  private refreshToken(refreshToken: string) {
    const form = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: environment.clientId,
      client_secret: environment.clientSecret
    };

    const uri = environment.securityIdentityServerUrl + '/connect/token';
    const body = Object.keys(form).map(x => `${encodeURIComponent(x)}=${encodeURIComponent(form[x])}`).join('&');
    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    return this.http.post<TokenResponse>(uri, body, options);
  }

  private tryGetUserInfoFromJwt(accessToken: string): UserInfoResponse {
    const data = accessToken.split('.');
    if (data.length == 3) {
      try {
        const decoded = this.windowService.nativeWindow.atob(data[1]);
        const obj = JSON.parse(decoded);
        return obj.sub && obj.permission?.length ? obj : null;
      }
      catch (e) { }
    }
    return null;
  }

  /**
   * Calls the IdentityServer user info endpoint
   */
  private getUserInfo(accessToken: string) {
    const uri = environment.securityIdentityServerUrl + '/connect/userinfo';
    const options = {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
    return this.http.get<UserInfoResponse>(uri, options);
  }

  /**
   * Starts the dev login polling.
   */
  private startDevTokenRefreshPolling() {
    const interval = this.calculatePollingInterval(this.profile.accessToken.expiresIn);
    this.timerSub = timer(interval).subscribe(() => this.refreshDevToken());
  }

  private oauthRefresh() {
    Debug.log('refreshing token');
    return this.oAuthService.refreshToken();
  }

  /**
   * This function uses security service directly unlike the portal polling logic which calls a custom endpoint on portal.
   */
  private refreshDevToken() {
    let hasError = false;

    // if we have stored a refresh token use it, otherwise let the oauth service do the work
    let token = this.getRefreshToken();
    let request: Observable<any> = token
      ? this.refreshToken(token)
      : from(this.oauthRefresh());

    this.request = request
      .pipe(
        map((token: TokenResponse) => {
          this.profile.accessToken.value = token.access_token;
          const now = new Date();
          this.profile.accessToken.initializedAt = now;
          this.profile.accessToken.expiresAt = new Date(now.getTime() + token.expires_in * 1000);
          this.profile.accessToken.expiresIn = token.expires_in;
          return this.profile;
        }),
        catchError((err: HttpErrorResponse) => {
          Debug.error(`[Profile Service] refresh dev token request failed; returned a ${err.status} status code`, err);
          if (this.enteredPortalClientWithLogin || this.isProtectedRoute) {
            hasError = true;
            this.expiredSessionRedirect();
          }
          return of<UserProfile>(this.createEmptyProfile());
        }),
        finalize(() => {
          this.clearTimer();
          if (!hasError) {
            this.startDevTokenRefreshPolling();
          }
        }),
        shareReplay(1)
      );

    this.requestSub = this.request.subscribe(() => {
      this.clearRequest();
    });
  }
}

interface TokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  error?: string;
  error_description?: string;
}

interface UserInfoResponse {
  sub: string;
  given_name: string;
  family_name: string;
  email: string;
  permission: string[];
  persona: string[];
  organization_code?: string;
  aim_id?: number;
  account_exec?: boolean;
  // date authenticated as a timestamp number
  auth_time?: number;
  // expiration date as a timestamp number
  exp?: number;
}
