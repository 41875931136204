import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NotificationService, Resource, ResourceService } from '@core/index';
import { isEqual } from 'lodash';

@Component({
  selector: 'multi-check-selector',
  templateUrl: './multi-check-selector.component.html',
  styleUrls: ['./multi-check-selector.component.scss']
})
export class MultiCheckSelectorComponent implements OnChanges {

  @Input() name: string;
  @Input() items = [];
  @Input() labelProperty?: string;
  @Input() selectionName?: string;
  @Input() includeAllOption = true;
  @Input() useDropList = true;
  @Input() selections = [];
  @Input() tooltips?: Map<string | number, string>;  // If key is string, label value is used as a key, if number, index is used
  @Output() selectionsChange: EventEmitter<any[]>;

  selectAll: boolean;
  options: Option[];

  constructor(
    private readonly notify: NotificationService,
    private readonly resourceService: ResourceService
  ) {
    this.selectionsChange = new EventEmitter<any[]>();
    this.options = [];
    this.selectAll = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    const itemsChange = changes.items;
    if (Object.isDefined(itemsChange)) {
      this.updateOptions(itemsChange.currentValue);
    }

    const selectionChanges = changes.selections;
    if (Object.isDefined(selectionChanges)) {
      if (Object.isUndefined(selectionChanges.currentValue)) {
        this.selections = [];
      }

      if (selectionChanges.currentValue !== selectionChanges.previousValue) {
        this.updateSelections(selectionChanges.currentValue);
      }
    }
  }

  private updateSelections(newSelected: any[]) {
    if (Object.isUndefined(newSelected)) {
      this.selectAll = false;
      this.resetOptionState();
    } else if (this.includeAllOption && newSelected.length === this.options.length) {
      this.selectAll = true;
      this.resetOptionState(false, true);
    } else {
      this.selectAll = false;
      for (const o of this.options) {
        let found = false;
        for (const val of newSelected) {
          if (isEqual(o.value, val)) {
            found = true;
            break;
          }
        }
        o.checked = found;
        o.selected = found;
      }
    }
  }

  private updateOptions(newItems: any[]) {
    if (!newItems?.length) {
      this.options = [];
      return;
    }

    let useLabelProp = false;
    if (typeof newItems[0] === 'object') {
      if (String.isUndefinedOrEmpty(this.labelProperty)) {
        throw new Error('labelProperty is required when item values are objects');
      }
      useLabelProp = true;
    }

    this.options = newItems.map((value, i) => {
      // labels can be either strings or objects, if it's a string the id can match
      let label = value;
      let id = value;

      if (useLabelProp) {
        // value is typed any so we need to check the label property value
        label = value[this.labelProperty];
        if (Object.isDefined(label)) {
          // we need to check if it's a string or a resource
          id = typeof label === 'string'
            ? label
            // and if it's a resource, it should have a defined key
            : String.isNotEmpty(label.key) ? label.key : i.toString();
        } else {
          // if the prop returns undefined/null, we still need an id
          id = i.toString();
        }
      }

      return {
        // normalize the id so that it looks like an id
        // if the value was a string this could contain spaces, apostrophes, and other symbols
        id: id.replace(/[^a-zA-Z0-9]/g, (match: string) => (match === ' ' || match === '.') ? '_' : '').toLowerCase(),
        value,
        label,
        checked: false,
        selected: false
      } as Option;
    });
  }

  onToggleAll() {
    this.selectAll = !this.selectAll;
    this.resetOptionState(false, this.selectAll);
    this.updateSelected();
  }

  onOptionToggle(evt, item) {

    if (this.selectAll) {
      this.selectAll = false;
      for (const o of this.options) {
        o.checked = (item === o);
        o.selected = o.checked;
      }
      this.updateSelected();
    } else if (item.checked && (this.selections.length === this.options.length - 1)) {
      // if the item is checked and current selections are one less than the options
      // then we've selected all options

      // the checked item triggered the event so in order for us to update its value
      // it has to be done after the event finishes processing hence setTimeout
      setTimeout(() => this.onToggleAll());
    } else {
      item.selected = item.checked;
      this.updateSelected();
    }
  }

  getButtonText() {
    const selLength = Object.isDefined(this.selections) ? this.selections.length : 0;
    const totalLength = Object.isDefined(this.options) ? this.options.length : 0;

    if (selLength > 0) {
      const name = this.selectionName || 'selected';
      return (this.selectAll ? totalLength : selLength) + ' of ' + totalLength + ' ' + name;
    } else {
      return this.selectionName ? 'Select ' + this.selectionName + '...' : 'Select...';
    }
  }

  // Alternative to mouse over - hidden optional.
  protected async openTooltip(label: string | Resource, content: string) {
    const title = typeof label === 'string' ? label : this.resourceService.getResource(label.key);
    await this.notify.showMarkdownModalMessage(title, content);
  }

  protected onOptionSelected(event, item) {
    item.checked = !item.checked;
    this.onOptionToggle(null, item);
    event.stopPropagation();
    event.preventDefault();
  }

  protected onOptionAllSelected(event) {
    this.onToggleAll();
    event.stopPropagation();
    event.preventDefault();
  }

  private updateSelected() {
    const result = [];
    for (const option of this.options) {
      if (option.selected) { result.push(option.value); }
    }
    this.selections = result;
    this.selectionsChange.emit(result);
  }

  private resetOptionState(checked?: boolean, selected?: boolean) {
    for (const o of this.options) {
      o.checked = Object.isDefined(checked) && checked;
      o.selected = Object.isDefined(selected) && selected;
    }
  }
}

interface Option {
  id: string;
  value: any;
  label: string | Resource;
  checked: boolean;
  selected: boolean;
}
