import { FilterBuilder, TransformBuilder } from './odata.interfaces';

/**
 * Provides the base functionality for an ODATA query builder. Extend
 * to add custom functions or to use a specialized filter builder.
 * @example See the app query builder in fulfillment's chubb product for a custom implementation
 */
export abstract class ODataQueryBuilderBase<F extends FilterBuilder, T extends TransformBuilder> {
  private parts: string[] = [];
  private filterBuilder!: F;
  private transformBuilder!: T;

  readonly separator = '&';

  constructor() { }

  get filter(): F {
    return this.filterBuilder;
  }

  protected set assignFilter(value: F) {
    this.filterBuilder = value;
  }

  // returns a transform builder for creating an odata $apply
  get apply(): T {
    return this.transformBuilder;
  }

  protected set assignApply(value: T) {
    this.transformBuilder = value;
  }

  skip(num: number): this {
    if (num > 0) {
      this.appendOperatorJoin();
      this.parts.push('$skip=');
      this.parts.push(num.toString());
    }
    return this;
  }

  count() {
    this.appendOperatorJoin();
    this.parts.push('$count=true');
    return this;
  }

  top(num: number): this {
    if (num > 0) {
      this.appendOperatorJoin();
      this.parts.push('$top=');
      this.parts.push(num.toString());
    }
    return this;
  }

  order(field: string, asc = true): this {
    if (field) {
      this.appendOperatorJoin();
      this.parts.push('$orderby=');
      this.parts.push(field);
      if (!asc) {
        this.parts.push(' desc');
      }
    }
    return this;
  }

  build() {
    const result: string[] = [];

    // add the $apply first if there is one
    if (this.transformBuilder.hasTransform) {
      result.push('$apply=');
      this.transformBuilder.copy(result);
    }

    // add our "parts"
    if (this.parts.length) {
      this.appendOperatorJoin(result);
      Array.prototype.push.apply(result, this.parts);
    }

    // append the filter at the end
    if (this.filterBuilder.hasFilter) {
      if (this.filterBuilder.hasOpenGroups) {
        throw new Error('$filter contains mismatched groups; query invalid');
      }
      this.appendOperatorJoin(result);
      result.push('$filter=');
      this.filterBuilder.copyFilter(result);
    }

    if (result.length) {
      return result.join('');
    } else {
      return '';
    }
  }

  private appendOperatorJoin(parts?: string[]) {
    const target = parts ?? this.parts;
    if (target!.length) {
      target!.push(this.separator);
    }
  }
}