import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Resource } from '@core/index';
import { Placement } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  // can't use normal encapsulation because our css targets the bootstrap component
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent {

  @Input() title: string | Resource;
  @Input() tip: string | Resource;
  @Input() position: Partial<Placement>[] = ['right', 'left', 'top', 'bottom'];
  @Input() white = false;

  constructor() { }
}
