<div class="modal-header">
  <h5 class="modal-title text-center">{{ title | humanReadable }}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal(true)"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div *ngIf="dateRangeToApply && dateRangeMatch" class="d-flex justify-content-center">
      <div class="date-range-match rounded-pill px-2">
        {{ dateRangeToApply.label }} Selected
      </div>
    </div>
    <div class="alert alert-warning" *ngIf="helpMessage">{{ helpMessage }}</div>
    <div class="faux-material-form-group">
      <label for="beginDate">{{beginDateLabel}}</label>
      <date-picker [(ngModel)]="beginDate" (ngModelChange)="isValid(beginDate, endDate)" name="begin-date"
                   id="beginDate" [placeholderLabel]="placeholderText" [initialValue]="beginDate" [min]="minDate"
                   [max]="endDate"></date-picker>
    </div>
    <div class="faux-material-form-group">
      <label for="endDate">{{endDateLabel}}</label>
      <date-picker [(ngModel)]="endDate" (ngModelChange)="isValid(beginDate, endDate)" name="end-date" id="endDate"
                   [placeholderLabel]="placeholderText" [initialValue]="endDate" [min]="beginDate"
                   [max]="maxDate"></date-picker>
    </div>
  </div>
</div>
<div class="modal-footer revert-btn mx-3">
  <button *ngIf="dateRangeToApply" [disabled]="dateRangeMatch" type="button" class="btn btn-action me-auto"
          (click)="setDates()" title="{{dateRangeToApply.label}}" id="dtp_btn_apply">{{dateRangeToApply.label}}</button>
  <button type="button" class="btn btn-action" (click)="clear()" title="Clear values" id="dtp_btn_clear">Clear</button>
  <button *ngIf="initBeginDate || initEndDate" type="button" class="btn btn-action" (click)="reset()"
          [disabled]="resetDateRangeMatch" title="Reset to initial values" id="dtp_btn_reset">Reset</button>
  <button type="button" ngbAutofocus class="btn btn-action" (click)="closeModal()"
          title="Save range values and continue" id="dtp_btn_submit">Submit</button>
</div>