import { Pipe, PipeTransform } from '@angular/core';
import { ResourceService } from './resource.service';

@Pipe({
  name: 'getgroupresource',
  pure: false
})
export class GetGroupResourcePipe implements PipeTransform {

  constructor(private readonly resourceSvc: ResourceService) { }

  transform(key: string, groupKey: string, ...args: Array<string | number>): any {
    if (String.isUndefinedOrEmpty(groupKey)) {
      groupKey = ResourceService.defaultGroupKey;
    }
    return this.resourceSvc.getFormattedResource(key, groupKey, args);
  }

}
