import { Component } from '@angular/core';
import { environment } from '@env';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'user-modal',
  templateUrl: './user-modal.component.html'
})
export class UserModalComponent {

  personas: Persona[];
  uri: string;

  constructor(private readonly modal: NgbActiveModal) {
    this.personas = environment.personas.map(x => ({ ...x } as Persona));
    this.uri = environment.securityIdentityServerUrl;
  }

  login() {
    this.modal.close();
  }

  cancel() {
    this.modal.dismiss();
  }
}

interface Persona {
  name: string;
  username: string;
  password: string;
}