<ng-container *ngIf="showResults">
  <div class="pill borderless-pill deemphasized px-0" id="search-result-count">{{resultsLabel}}</div>
  <div class="d-flex flex-nowrap align-self-stretch" *ngIf="hasFilters">
    <div class="d-flex flex-wrap" id="filter-container">
      <span class="pill deemphasized me-2 mb-2" *ngFor="let item of internalItems; index as idx">
        {{item.label | getresource}}
        <a class="fa fa-times" id="remove_filter_{{idx}}" (click)="unselectItem(item)"></a>
      </span>
      <span class="mt-1">
        <a class="pill borderless-pill deemphasized" id="clear-all-categories" (click)="unselectAll()">
          {{unselectAllText ? unselectAllText : 'Clear All'}}
        </a>
      </span>
    </div>
  </div>
</ng-container>
