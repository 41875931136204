import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';

@Directive({
  selector: '[maxValue]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxValueValidator),
    multi: true
  }]
})
export class MaxValueValidator implements Validator {
  @Input() maxValue?: number;

  validate(control: UntypedFormControl): { [key: string]: any; } {
    if (Object.isUndefined(this.maxValue)) {
      return null;
    }

    if ((isNaN(control.value)) || (!isFinite(control.value)) || (control.value > this.maxValue)) {
      return { maxValue: { valid: false } };
    }

    return null;
  }

}
