<div class="dev-bar-spacer"></div>
<nav class="navbar navbar-expand-sm navbar-dark bg-dark fixed-bottom">
  <a class="navbar-brand" href="#">RPS Dev</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="devNavMenu" aria-expanded="false"
          aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav">
      <li class="nav-item" ngbDropdown placement="top-end">
        <a id="options-menu-item" ngbDropdownToggle class="nav-link" data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">Options</a>
        <div ngbDropdownMenu>
          <label ngbDropdownItem>
            <input id="suppress-reload-prompt" type="checkbox" [(ngModel)]="suppressReloadPrompt"
                   (ngModelChange)="updatePromptSuppression($event)" />
            Suppress Reload/Exit Prompt
          </label>
          <label ngbDropdownItem *ngIf="showLocalOptions">
            <input id="use-local-data" type="checkbox" [(ngModel)]="useLocalData"
                   (ngModelChange)="updateLocalDataUse($event)" />
            Use Local Data
          </label>
          <ng-container *ngIf="showLocalOptions">
            <hr>
            <label class="ps-4">Use Local Services:</label>
            <label ngbDropdownItem class="ps-5">
              <input id="use-local-aim-api" type="checkbox" [(ngModel)]="useLocalAimApi"
                     (ngModelChange)="updateLocalAimApiUse($event)" />
              AIM Api
            </label>
            <label ngbDropdownItem class="ps-5">
              <input id="use-local-document-api" type="checkbox" [(ngModel)]="useLocalDocumentApi"
                     (ngModelChange)="updateLocalDocumentApiUse($event)" />
              Document Service
            </label>
            <label ngbDropdownItem class="ps-5">
              <input id="use-local-Statement-api" type="checkbox" [(ngModel)]="useLocalStatementApi"
                     (ngModelChange)="updateLocalStatementApiUse($event)" />
              Statement Service
            </label>
            <label ngbDropdownItem class="ps-5">
              <input id="use-local-certificate-api" type="checkbox" [(ngModel)]="useLocalCertificateApi"
                     (ngModelChange)="updateLocalCertificateApiUse($event)" />
              Certificate Service
            </label>
            <label ngbDropdownItem class="ps-5">
              <input id="use-local-portal" type="checkbox" [(ngModel)]="useLocalPortal"
                     (ngModelChange)="updateLocalPortalUse($event)" />
              MyRpsv2 (Portal)
            </label>
            <label ngbDropdownItem class="ps-5">
              <input id="use-local-producers" type="checkbox" [(ngModel)]="useLocalProducers"
                     (ngModelChange)="updateLocalProducersUse($event)" />
              Producers Service
            </label>
            <label ngbDropdownItem class="ps-5">
              <input id="use-local-products" type="checkbox" [(ngModel)]="useLocalProducts"
                     (ngModelChange)="updateLocalProductsUse($event)" />
              Products Service
            </label>
            <label class="dropdown-item ps-5">
              <input id="use-local-onboarding" type="checkbox" [(ngModel)]="useLocalOnboarding"
                     (ngModelChange)="updateLocalOnboardingUse($event)" />
              Onboarding Service
            </label>
          </ng-container>
        </div>
      </li>
      <li class="nav-item" ngbDropdown placement="top-end">
        <a ngbDropdownToggle class="nav-link" id="login-dropdown-toggle" data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">Switch User <span *ngIf="activeUser">({{activeUser}})</span></a>
        <div ngbDropdownMenu>
          <a ngbDropdownItem class="link-color-inherit" (click)="login()">Login</a>
          <a ngbDropdownItem class="link-color-inherit" (click)="cookieLogin()" id="cookie-login">Login with
            .MYRPSV2 Cookie</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem class="link-color-inherit" (click)="logout()" [disabled]="!activeUser">Logout</a>
        </div>
      </li>
      <li class="nav-item">
        <a [routerLink]="'retailer/'+retailerId+'/home'" class="nav-link">Retailer</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="'producer/home'" class="nav-link">Producer</a>
      </li>
      <li class="nav-item" ngbDropdown placement="top-end">
        <a ngbDropdownToggle class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true"
           aria-expanded="false">Management</a>
        <div ngbDropdownMenu>
          <a *ngFor="let area of managementAreas" ngbDropdownItem class="link-color-inherit"
             [routerLink]="'management/' + area.route">
            {{area.description}}
          </a>
        </div>
      </li>
      <li class="nav-item" ngbDropdown placement="top-end">
        <a ngbDropdownToggle class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true"
           aria-expanded="false">Fulfillment</a>
        <div ngbDropdownMenu>
          <a ngbDropdownItem class="link-color-inherit" routerLink="fulfillment/list/quotes">Quotes/Policies</a>
          <a *ngFor="let product of products" ngbDropdownItem class="link-color-inherit"
             [routerLink]="'fulfillment/products/' + product.path + '/workflow/resume'"
             id="product-switch-{{product.path}}">
            {{product.description}}
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>