<div class="set-password">

  <div *ngIf="loading" class="text-center mb-5">
    Validating...
    <br /><img src="/assets/images/spinner_squares_circle.gif" alt="Validating..." />
  </div>

  <form *ngIf="displayForm" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <h4 class="text-center w-100 p-2">Set a new password</h4>
    <div>
      <label class="form-label">Email address</label>
      <input class="form-control revert" type="email" autocomplete="off" formControlName="email"
             [readonly]="emailLocked" />
      <form-errors [input]="passwordForm?.get('email')"></form-errors>
    </div>
    <div>
      <label class="form-label">New Password</label>
      <input class="form-control revert" type="password" autocomplete="new-password" formControlName="passwordOne"
             (input)="validatePassword()" />
      <form-errors [input]="passwordForm?.get('passwordOne')"></form-errors>
    </div>
    <div class="mb-4">
      <label class="form-label">Confirm Password</label>
      <input class="form-control revert" type="password" autocomplete="new-password" formControlName="passwordTwo"
             (input)="validatePassword()" />
      <form-errors [input]="passwordForm?.get('passwordTwo')"></form-errors>
    </div>
    <div class="mb-2">
      <span class="mx-3" role="status" [class.bi-check-circle-fill]="passwordValidation.upper"
            [class.bi-circle]="!passwordValidation.upper"></span>
      <span class="password-check">Includes at least 1 uppercase letter</span>
    </div>
    <div class="mb-2">
      <span class="mx-3" role="status" [class.bi-check-circle-fill]="passwordValidation.lower"
            [class.bi-circle]="!passwordValidation.lower"></span>
      <span class="password-check">Includes at least 1 lowercase letter</span>
    </div>
    <div class="mb-2">
      <span class="mx-3" role="status" [class.bi-check-circle-fill]="passwordValidation.number"
            [class.bi-circle]="!passwordValidation.number"></span>
      <span class="password-check">Includes at least 1 number</span>
    </div>
    <div class="mb-2">
      <span class="mx-3" role="status" [class.bi-check-circle-fill]="passwordValidation.length"
            [class.bi-circle]="!passwordValidation.length"></span>
      <span class="password-check">At least 8 characters long</span>
    </div>
    <div class="mb-2">
      <span class="mx-3" role="status" [class.bi-check-circle-fill]="passwordValidation.matches"
            [class.bi-circle]="!passwordValidation.matches"></span>
      <span class="password-check">Passwords match</span>
    </div>
    <div class="mb-1 mt-4 row">
      <button class="btn btn-action col mx-4" type="submit"
              [disabled]="!passwordForm.valid && passwordForm.touched || !isPasswordValid || formSubmitted">
        <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm" role="status"></span>
        Set password
      </button>
    </div>
  </form>

  <div *ngIf="!displayForm && formSubmitted">
    <div class="alert alert-warning">
      <p class="fw-bold">Password updated</p>
      <p>Your password was successfully updated. Be sure to update any password managers you use.</p>
    </div>
    <div class="mb-1 mt-5 row">
      <button type="button" class="btn btn-action col mx-4" (click)="redirectToLogin()">
        Continue to login
      </button>
    </div>
  </div>

  <div class="links text-center w-100">
    For assistance contact RPS Customer Care<br />
    <a class="d-block" href="phone:1-866-582-2547">1-866-582-2547</a>
    <a class="d-block" href="mailto:customercare@rpsins.com">customercare@rpsins.com</a>
  </div>

  <div class="links text-center w-100">
    <a class="d-block p-3" [routerLink]="['/']">Show Login Options</a>
  </div>
</div>