import { Pipe, PipeTransform } from '@angular/core';
import { Resource, ResourceConverter } from './resource';
import { ResourceService } from './resource.service';

@Pipe({
  name: 'getresource',
  pure: false
})
export class GetResourcePipe implements PipeTransform {

  constructor(private readonly resourceSvc: ResourceService) { }

  transform(resource: string | Resource, ...args: Array<string | number>): any {
    const value = ResourceConverter.convert(resource);
    return this.resourceSvc.getFormattedResource(value.key, value.group, args);
  }
}
