import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { HumanReadablePipe } from '../pipes';

@Injectable({
  providedIn: 'root'
})
export class UrlBasedTitleStrategy extends TitleStrategy {

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(route: RouterStateSnapshot) {
    let title = this.buildTitle(route);
    if (!title) {
      // A-code removal (makes Google Analytics less cluttered)
      const filteredUrl = route.url.replace(/\/a[0-9]{7}\//i, '/');
      const titleParts = filteredUrl.split('/');
      title = titleParts.join(' ');
    }
    this.title.setTitle(HumanReadablePipe.prototype.transform(title, true, false));
  }
}
