<ng-template #tooltipBody>
  <div>
    <div *ngIf="title" class="tooltip-title">{{title | getresource}}</div>
    <div class="tooltip-body">
      <markdown ngPreserveWhitespaces [data]="tip | getresource"></markdown>
    </div>
  </div>
</ng-template>
<div class="custom-tooltip">
  <i class="fa fa-info-circle icon-md icon-info" [class.text-white]="white" [ngbTooltip]="tooltipBody"
     [placement]="position">
  </i>
</div>