import { Component, Input } from '@angular/core';
import { AbstractValueAccessor, makeProvider } from '../../abstract-value-accessor';

@Component({
  selector: 'address-display',
  templateUrl: './display.component.html',
  providers: [makeProvider(() => DisplayComponent)]
})
export class DisplayComponent extends AbstractValueAccessor {

  @Input() name: string;
  @Input() singleLine?: boolean;

  constructor() {
    super();
  }

  hasStreetAddress() {
    return Object.isDefined(this.value) && String.isNotEmpty(this.value.addressLineOne);
  }

  hasAddressLineTwo() {
    return Object.isDefined(this.value) && String.isNotEmpty(this.value.addressLineTwo);
  }

  hasCityStateZip() {
    return Object.isDefined(this.value) &&
      (String.isNotEmpty(this.value.locality)
        || String.isNotEmpty(this.value.region)
        || String.isNotEmpty(this.value.postalCode));
  }

  getStreetAddress() {
    if (!this.hasStreetAddress())
      return '';

    let result = this.value.addressLineOne;
    if (String.isNotEmpty(this.value.unitNumber)) {
      result += isNaN(parseInt(this.value.unitNumber)) ? ' ' : ' #';
      result += this.value.unitNumber;
    }

    return result;
  }

  getAddressLineTwo() {
    return this.hasAddressLineTwo() ? this.value.addressLineTwo : '';
  }

  getCityStateZip() {
    if (!this.hasCityStateZip()) {
      return '';
    }

    let result = String.isNotEmpty(this.value.locality) ? this.value.locality : '';

    if (String.isNotEmpty(this.value.region)) {
      if (String.isNotEmpty(result)) result += ', ';
      result += this.value.region;
    }

    if (String.isNotEmpty(this.value.postalCode)) {
      if (String.isNotEmpty(result)) result += ' ';
      result += this.value.postalCode;
    }

    return result;
  }

  getSingleLineFormat() {
    const street = this.getStreetAddress();
    const csz = this.getCityStateZip();
    return street + (String.isNotEmpty(csz) ? ' ' + csz : '');
  }
}
