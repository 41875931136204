import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const queryIdx = url.indexOf('?');
    if (queryIdx >= 0) {
      let urlWithoutQuery = url.substring(0, queryIdx).toLowerCase();
      return super.parse(urlWithoutQuery + url.substring(queryIdx));
    } else {
      return super.parse(url.toLowerCase());
    }
  }
}
