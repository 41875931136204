export { };
declare global {
  /* extend the interfaces  */

  interface StringConstructor {
    /** returns true if the value is undefined, null, or has a length of 0
     */
    isUndefinedOrEmpty(value: string | undefined): boolean;
    /** returns true if the value is defined and has a length
     */
    isNotEmpty(value: string | undefined): boolean;
    /** case insensitive string equality comparison
         @returns true if both values are equal or both values are undefined, false otherwise
        */
    equalsIgnoreCase(a: string | undefined, b: string | undefined): boolean;

    /** determines if a string ends with another
     * @returns true if the value ends with another, false otherwise
     */
    endsWith(value: string, endsWith: string): boolean;

    /** determines if a string starts with another
     * @returns true if the value starts with another, false otherwise
     */
    startsWith(value: string, startsWith: string): boolean;

    /**
     * determines if a string contains another string
     * @param {string} value the value to check
     * @param {string} search the substring to search for
     * @returns {boolean} true if a match was found, false otherwise
     */
    contains(value: string, search: string): boolean;

    /**
     * Removes all instances of a character from the end of the string
     * @param {string} value the value to trim
     * @param {string} char the character to remove
     */
    trimCharEnd(value: string, char: string): string;

    /**
     * Adds a string to the start of another string if it doesn't already exist
     * @param value The string to prepend a value to
     * @param prepend The value to be added at the beginning of the string
     */
    prepend(value: string, prepend: string): string;
  }

  interface ObjectConstructor {
    /** returns true if the value is either undefined or null
     */
    isUndefined(value: any): boolean;
    /** returns true if the value isn't undefined or null
     */
    isDefined(value: any): boolean;
  }

  interface ArrayConstructor {
    firstOrDefault<T>(array: T[], func: (item: T, index?: number) => boolean): T | null;
    isEmpty<T>(array: T[]): boolean;

    isNotEmpty<T>(array: T[]): boolean;
  }

  export interface JsonObject {
    [key: string]: any;
  }
}

/* strings */

String.isUndefinedOrEmpty = (value: string): boolean => {
  return Object.isUndefined(value) || !value.length;
};

String.isNotEmpty = (value: string): boolean => !String.isUndefinedOrEmpty(value);

String.equalsIgnoreCase = (a: string, b: string): boolean => {
  const aDefined = Object.isDefined(a);
  const bDefined = Object.isDefined(b);
  if (aDefined) {
    return bDefined && a.toLowerCase() === b.toLowerCase();
  } else {
    return !bDefined;
  }
};

String.endsWith = (value: string, endsWith: string): boolean => {
  if (String.isUndefinedOrEmpty(value) && String.isUndefinedOrEmpty(endsWith)) return true;
  else if (String.isUndefinedOrEmpty(endsWith)) return false;
  else if (value.length < endsWith.length) return false;

  let j = endsWith.length - 1;
  for (let i = value.length - 1; i >= 0 && j >= 0; i--) {
    if (value.charAt(i) === endsWith.charAt(j)) j--;
    else break;
  }
  return j < 0;
};

String.startsWith = (value: string, startsWith: string): boolean => {
  if (String.isUndefinedOrEmpty(value) && String.isUndefinedOrEmpty(startsWith)) return true;
  else if (String.isUndefinedOrEmpty(startsWith)) return false;
  else if (value.length < startsWith.length) return false;

  let j = startsWith.length;
  for (let i = 0; i < value.length && j > 0; i++) {
    if (value.charAt(i) === startsWith.charAt(i)) j--;
    else break;
  }
  return j == 0;
};

String.contains = (value: string, search: string): boolean => {
  if (String.isUndefinedOrEmpty(value) && String.isUndefinedOrEmpty(search)) return true;
  else if (String.isUndefinedOrEmpty(search)) return false;
  else if (value.length < search.length) return false;
  else return value.indexOf(search) > -1;
};

String.trimCharEnd = (value: string, char: string): string => {
  if (String.isUndefinedOrEmpty(value) || String.isUndefinedOrEmpty(char)) return value;

  let end = -1;
  for (let i = value.length - 1; i >= 0; i--) {
    if (value.charAt(i) === char) end = i;
    else break;
  }

  return end >= 0 ? value.substring(0, end) : value;
};

String.prepend = (value: string, prepend: string) => {
  if (String.isUndefinedOrEmpty(value) || String.isUndefinedOrEmpty(prepend)) return value;
  return value.startsWith(prepend) ? value : prepend + value;
};

/* objects */

Object.isUndefined = (value: any): boolean => {
  return value === undefined || value === null;
};

Object.isDefined = (value: any): boolean => {
  return !Object.isUndefined(value);
};

/* Arrays */

// Welcome to C#

/**
 * Checks if the array object is undefined or contains no items
 * @param array the array to check
 * @returns true if undefined, null, or length is zero; false otherwise
 */
Array.isEmpty = <T>(array: T[]): boolean => {
  return Object.isUndefined(array) || array.length == 0;
};

/**
 * Checks if the array object is defined and has at least one item
 * @param array the array to check
 * @returns true if defined and has at least one item; false otherwise
 */
Array.isNotEmpty = <T>(array: T[]): boolean => {
  return Object.isDefined(array) && array.length > 0;
};
/**
 * Iterates through an array and returns the first item that matches a condition
 * @param array The array to search through
 * @param func A predicate function that returns true when a match is found
 * @returns The first value encountered that matches the predicate or null
 */
Array.firstOrDefault = <T>(array: T[], func: (item: T, index?: number) => boolean): T | null => {
  if (Array.isEmpty(array)) {
    return null;
  } else if (Object.isUndefined(func)) {
    throw new Error('a predicate function is expected');
  }

  for (let i = 0, len = array.length; i < len; i++) {
    if (func(array[i], i)) {
      return array[i];
    }
  }
  return null;
};
