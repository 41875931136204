import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateUserRequest, CreateUserResponse, Debug, OrganizationChangeRequest, OrganizationSearchResult, QueryResult, Role, User, UserSearchResult, WindowService } from '@core/index';
import { environment } from '@env';
import { Observable, firstValueFrom, map, of } from 'rxjs';
import { Activity } from '../../management/users/core/activities';
import { ApiKey } from '../../management/users/core/api-keys';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly httpOptions = {
    headers: {
      'addAuthorization': 'true',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    }
  };

  constructor(
    private readonly http: HttpClient,
    private readonly window: WindowService
  ) {
  }

  get(userId: number): Promise<User | null> {
    return firstValueFrom(this.getWithObservable(userId));
  }

  getWithObservable(userId: number): Observable<User | null> {
    if (userId) {
      return this.http.get<User>(this.getUserUrl(userId), this.httpOptions)
        .pipe(map(resp => {
          if (resp?.lastChangedAt && typeof resp.lastChangedAt == 'string') {
            resp.lastChangedAt = this.appendZeroOffsetSpecifier(resp.lastChangedAt);
          }
          return resp;
        }));
    } else {
      return of(null);
    }
  }

  search(odataQuery: string): Promise<UserSearchResult> {
    return firstValueFrom(this.searchWithObservable(odataQuery));
  }

  searchWithObservable(odataQuery: string): Observable<UserSearchResult> {
    odataQuery = String.prepend(odataQuery, '?');
    const url = this.createUrl(`user${odataQuery}`);
    return this.http.get<UserSearchResult>(url, this.httpOptions);
  }

  getOrgByCode(code: string): Promise<OrganizationSearchResult> {
    let url = this.createUrl('organization/?code=' + code);
    return firstValueFrom(this.http.get<OrganizationSearchResult>(
      url,
      this.httpOptions
    ));
  }

  resetPassword(email: string): Promise<ApiKey> {
    return firstValueFrom(this.http.post<ApiKey>(
      this.createUrl('Password/ForgottenPassword'),
      { email: email },
      this.httpOptions
    ));
  }

  toggleActivation(url: string): Promise<User> {
    if (url) {
      return firstValueFrom(this.http.put<User>(url, {}, this.httpOptions));
    } else {
      return Promise.resolve(null);
    }
  }

  async deactivate(userId: number): Promise<void> {
    if (Object.isDefined(userId) && (userId > 0)) {
      const url = `${this.getUserUrl(userId)}/deactivate`;
      await firstValueFrom(this.http.put(url, null, this.httpOptions));
    }
    else {
      return Promise.reject();
    }
  }

  async getActivity(odataQuery = ''): Promise<QueryResult<Activity>> {
    odataQuery = String.prepend(odataQuery, '?');
    const result = await firstValueFrom(this.http.get<QueryResult<Activity>>(
      [environment.securityApiUri, 'activity', odataQuery].join(''),
      this.httpOptions
    ));
    if (result?.items?.length) {
      for (let item of result.items) {
        item.loggedAt = this.appendZeroOffsetSpecifier(item.loggedAt);
      }
    }
    return result;
  }

  // most dates returned by security are a UTC date without a timeoffset and no Z specifier. the lack of a Z causes issues with the date pipe
  // as it assumes the dates are local
  private appendZeroOffsetSpecifier(date: string) {
    const timeoffset = /([+-][0-2]\d:[0-5]\d|Z)/;
    return String.isNotEmpty(date) && !date.match(timeoffset)
      ? date + 'Z'
      : date;
  }

  async updateUser(userDetails: User, roles: Role[]): Promise<void> {
    if (Object.isUndefined(userDetails)) {
      return;
    }

    const body = { ...userDetails };
    body.roles = roles.map(x => x.name);
    delete body.routes;
    const url = this.getUserUrl(userDetails.id);
    await firstValueFrom(this.http.put(url, body, this.httpOptions));
  }

  async updateRoles(userId: number, roles: Role[]): Promise<void> {
    if (Object.isUndefined(roles)) {
      return;
    }
    const url = this.getUserUrl(userId) + '/roles';
    const body = {
      roles: roles.map(x => ({ id: x.id, name: x.name }))
    };
    await firstValueFrom(this.http.put(url, body, this.httpOptions));
  }

  changeOrganization(request: OrganizationChangeRequest): Promise<any> {
    if (Object.isDefined(request)) {
      return firstValueFrom(this.http.put(this.getUserUrl(request.userId), request, this.httpOptions));
    }
    else {
      return Promise.reject();
    }
  }

  create(user: User, roles: Role[]): Promise<CreateUserResponse> {
    const body: CreateUserRequest = {
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.email,
      email: user.email,
      organizationCode: user.organizationCode,
      userType: user.userType,
      roles: roles.map(x => x.name)
    };
    return this.createUser(this.createUrl('user'), body);
  }

  import(email: string): Promise<CreateUserResponse> {
    const body = { email: email };
    return this.createUser(this.createUrl('user/import'), body);
  }

  private async createUser(url: string, body: any): Promise<CreateUserResponse> {
    try {
      const response = await firstValueFrom(this.http.post(url, body, {
        headers: this.httpOptions.headers,
        observe: 'response'
      }));

      if (response.status == 200 || response.status == 201) {
        const location = response.headers.get('location');
        const id = parseInt(location.substring(location.lastIndexOf('/') + 1));
        return { status: 'success', id: id };
      } else {
        return { status: 'failure', id: null };
      }
    }
    catch (err) {
      Debug.warn('New user creation failed', err);
      const httpError = err as HttpErrorResponse;
      if (httpError.status == 409) {
        return { status: 'conflict', id: null };
      } else if (httpError.status == 404) {
        return { status: 'not_found', id: null };
      } else {
        return { status: 'failure', id: null };
      }
    }
  }

  private getUserUrl(userId: number) {
    return this.createUrl(`user/${userId}`);
  }

  private createUrl(path: string) {
    let baseUrl = String.trimCharEnd(environment.securityApiUri, '/');
    return `${baseUrl}/${path}`;
  }

  impersonateUser(userId: number) {
    this.window.nativeWindow.location.href = environment.portalApiUri + 'authentication/impersonate/' + userId;
  }
}