import { Injectable, NgZone } from '@angular/core';
import { WindowService } from '../../window.service';
import { Address } from '../address';
import {
  AddressComponentType,
  AutocompleteInput,
  AutocompleteType,
  IAddressComponent,
  IAutocompleteOptions,
  IAutocompleteService,
  IPlaceDetail,
  IPlacePredictionRequest,
  IPlacesService,
  IQueryPrediction
} from './autocomplete';

@Injectable()
export class GooglePlacesApiFacadeService {

  private autocompleteSvc: IAutocompleteService;
  private placesSvc: IPlacesService;
  private placesApi: JsonObject;

  constructor(
    private readonly zone: NgZone,
    private readonly windowService: WindowService) {

    const google = windowService.nativeWindow.google;
    this.placesApi = (google && google.maps) ? google.maps.places : {};
  }

  createAutocomplete(element: HTMLElement, options: IAutocompleteOptions = null): AutocompleteInput {
    if (Object.isUndefined(element)) {
      throw new Error('an html element is required');
    }

    options = options || { types: [AutocompleteType.Address], componentRestrictions: { country: 'us' } };
    const ac = new this.placesApi.Autocomplete(element, options);
    return new AutocompleteInput(ac, this.zone);
  }

  getPlacePredictions(input: string | IPlacePredictionRequest): Promise<IQueryPrediction[]> {
    if (Object.isUndefined(input)) {
      return Promise.resolve(null);
    }

    if (Object.isUndefined(this.autocompleteSvc)) {
      this.autocompleteSvc = new this.placesApi.AutocompleteService();
    }

    const request: IPlacePredictionRequest = typeof input === 'string' ? { input } : input;
    return new Promise(resolve => {
      this.autocompleteSvc.getPlacePredictions(request, results => {
        resolve(results);
      });
    });
  }

  getPlaceById(placeId: string): Promise<IPlaceDetail> {
    if (String.isUndefinedOrEmpty(placeId)) {
      return Promise.resolve(null);
    }

    if (Object.isUndefined(this.placesSvc)) {
      this.placesSvc = new this.placesApi.PlacesService(this.windowService.nativeWindow.document.createElement('div'));
    }

    return new Promise(resolve => {
      this.placesSvc.getDetails({ placeId }, (result, status) => {
        resolve((status === 'OK') ? result : null);
      });
    });
  }

  changePlaceToAddress(place: IPlaceDetail): Address {
    if (Object.isUndefined(place) || Object.isUndefined(place.address_components)) {
      return null;
    }

    const address = this.objectizeComponents(place);
    this.assignMissingDistrict(address);

    const output: Address = {} as Address;
    output.region = address.region;
    output.postalCode = address.postalCode;
    output.district = address.district;
    output.locality = Object.isDefined(address.locality) ? address.locality : address.sublocality;

    if (Object.isDefined(address.streetNumber) || Object.isDefined(address.streetName)) {
      output.addressLineOne = ((address.streetNumber || '') + ' ' + (address.streetName || '')).trim();
    }

    return output;
  }

  private objectizeComponents(place: IPlaceDetail) {
    const result: JsonObject = {};
    const hasType = (part: IAddressComponent, type: AddressComponentType) => part.types.indexOf(type) > -1;

    for (let part of place.address_components) {
      if (hasType(part, AddressComponentType.StreetNumber)) {
        result.streetNumber = part.short_name;
      } else if (hasType(part, AddressComponentType.Route)) {
        result.streetName = part.short_name;
      } else if (hasType(part, AddressComponentType.Locality)) {
        result.locality = part.short_name;
      } else if (hasType(part, AddressComponentType.Region)) {
        result.region = part.short_name;
      } else if (hasType(part, AddressComponentType.Sublocality)) {
        result.sublocality = part.short_name;
      } else if (hasType(part, AddressComponentType.PostalCode)) {
        result.postalCode = part.short_name;
      } else if (hasType(part, AddressComponentType.District)) {
        result.district = this.normalizeDistrictName(part.short_name);
      }
    }

    return result;
  }

  private assignMissingDistrict(address: JsonObject) {
    if (Object.isDefined(address.district)) return;

    switch (address.region) {
      case 'VA':
      case 'MD':
        address.district = 'City of ' + address.locality;
        break;
      case 'MO':
        if (address.locality === 'St. Louis') {
          address.district = 'St. Louis City';
        }
        break;
      case 'NV':
        if (address.locality === 'Carson City') {
          address.district = address.locality;
        }
        break;
      default:
        // TODO: Notify us when unexpected things happen
        // Need error handler service (either Angular service we write or 3rd party library)
        break;
    }
  }

  private normalizeDistrictName(district: string) {
    district = district.replace(/^(.+)( County| Parish| Borough)$/, '$1');

    if (district.substr(0, 3) === 'St ') {
      district = district.substr(0, 2) + '.' + district.substr(2);
    } else if (district.substr(0, 4) === 'Ste ') {
      district = district.substr(0, 3) + '.' + district.substr(3);
    }

    return district;
  }

}
