export interface Retailer {
  id: string;
  name: string;
  status: string;
}

export enum RetailerOrganizationStatus {
  Active = 'A',
  CashWithApp = 'C',
  Deleted = 'D',
  Inactive = 'I',
  Suspended = 'S',
  Terminated = 'T'
}
