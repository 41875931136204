import { Resource } from '@app/core/text-resources';

export interface ErrorMessageConfig {
  [key: string]: string | Resource;
}

/*
 * The key is the value of the error type returned by Angular's form control validation.
 * The value is the text resource to be used for the error message.
 */
export const defaultErrorMessages: ErrorMessageConfig = {
  required: { key: 'validation.errors.required', group: 'default' },
  valueList: { key: 'validation.errors.value-list', group: 'default' },
  email: { key: 'validation.errors.email', group: 'default' },
  phoneNumber: { key: 'validation.errors.phone-number', group: 'default' },
  incompleteAddress: { key: 'validation.errors.incomplete-address', group: 'default' },
  street: { key: 'validation.errors.street-number', group: 'default' },
  unitNumber: { key: 'validation.errors.unit-number', group: 'default' }
};
