import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env';

@Pipe({
  name: 'accounting'
})
export class AccountingPipe implements PipeTransform {

  transform(value: number, showDecimals = false) {
    if (value == null) { return null; }

    let digitsInfo = '1.0-0';
    if (showDecimals) {
      digitsInfo = '1.2-2';
    }

    let result = formatCurrency(value, environment.locale ?? 'en-US', '$', 'USD', digitsInfo);

    if (value < 0) {
      result = `(${result.substring(1)})`; // Convert to accounting format eg: -$10 --> ($10)
    }

    return result;
  }
}
