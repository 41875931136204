<div id="notification-message-modal">
  <div class="modal-header">Redirect</div>
  <div class="modal-body">
    <p>
      You will be redirect to {{uri}} in order to log in and redirected back once authenticated.
    </p>
    <table class="table">
      <thead>
        <th>Username</th>
        <th>Password</th>
      </thead>
      <tbody>
        <tr *ngFor="let persona of personas">
          <td>{{persona.username}}</td>
          <td>{{persona.password}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer d-flex align-items-end">
    <button id="notification-message-modal-cancel" class="btn btn-default" (click)="cancel()">Cancel</button>
    <button id="notification-message-modal-close" class="btn btn-action" (click)="login()">Login</button>
  </div>
</div>