<div>
  <div class="modal-header">{{title | getresource}}</div>
  <div class="modal-body">
    <p>Your request could not be processed:</p>
    <p>
      {{message | getresource}}
    </p>
    <div *ngIf="details?.length" class="card p-3">
      <div>
        <p>
          <i>Additional Details:</i>
        </p>
        <ul>
          <li *ngFor="let msg of details">{{ msg }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex align-items-end">
    <button class="btn btn-action" (click)="close()">Close</button>
  </div>
</div>