import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReactiveFormValidationService {

  constructor() { }

  // eg: Apt. D || c/o Tommy Thomas || PO Box 124 || Apartment A || Suite F || Ste. 23 || #12 || #A || 125 Main St. || 12 E 50 S || Apt. #12
  validateAddressLineTwo(control: AbstractControl) {
    if (!control.value) return null;
    const validate = /^ *(?:[a-z0-9][,.]? |(?!(?<=[^1-9])0)[a-z#/0-9]['-]?)+(?!0)[a-z0-9]\.? *$/i.test(control.value);
    return validate ? null : { addressLineTwo: { value: control.value } };
  }

  validatePhoneNumber(control: AbstractControl) {
    if (!control.value) return null;
    const validate = /^(([1])[- ]?)?\(?(\d{3})[-)]?[- ]?(\d{3})[- ]?(\d{4})$/.test(control.value);
    return validate ? null : { phone: { value: control.value } };
  }

  validateZip(control: AbstractControl) {
    if (!control.value) return null;
    const validate = /^(?:\d{5}|\d{5}-\d{4})$/.test(control.value);
    return validate ? null : { zip: { value: control.value } };
  }

  // ! Only checks length, not state codes
  validateState(control: AbstractControl) {
    if (!control.value) return null;
    const validate = /^[a-z]{2}$/i.test(control.value);
    return validate ? null : { state: { value: control.value } };
  }

  validateUrl(control: AbstractControl) {
    if (!control.value) return null;
    const validate = /^(([a-z0-9]+:\/\/)|(\/\/))*[a-z0-9.-]{2,}\.[a-z0-9]{2,}((\/)?|((\/+[a-z0-9/]*([a-z0-9#~-]*)$)+)?)$/i.test(control.value);
    return validate ? null : { url: { value: control.value } };
  }

  // Within form group, at least one checkbox to be selected
  checkboxRequired(form: FormGroup | any) {
    if (!form.value) return null;
    let controls = Object.values(form.controls) as Array<FormControl>;
    return controls.some(s => s.value === true) ? null : { checkboxRequired: { value: form.value } };
  }

  // Where's email? For email, use Angular's built-in validator

  autoFormatPhone(form: FormGroup | AbstractControl, controlName: string) {
    return this.monitorFormControl(form, controlName, this.formatPhoneNumber);
  }

  autoFormatZip(form: FormGroup | AbstractControl, controlName: string) {
    return this.monitorFormControl(form, controlName, this.formatZip);
  }

  private monitorFormControl(form: FormGroup | AbstractControl, controlName: string, methodToCall: Function) {
    return form.get(controlName)
      .valueChanges
      .pipe(
        debounceTime(1500), // To allow for editing before reformat triggers again (formatting sends the cursor to the end)
        distinctUntilChanged()
      )
      .subscribe(value => { form.get(controlName).patchValue(methodToCall(value)); });

  }

  formatPhoneNumber(value: string): string {
    if (!value) return '';

    value = value.replace(/[^0-9]/g, '');

    switch (value.length) {
      case 10:
        return value.replace(/^(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      case 11:
        return value.replace(/^(\d?)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
      default:
        return value;
    }
  }

  formatZip(value: string): string {
    if (!value) return '';

    value = value.replace(/[^-0-9]/g, '');

    switch (value.length) {
      case 9:
        return value.replace(/^(\d{5})(\d{4})/, '$1-$2');
      default:
        return value;
    }
  }

}
