import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';

@Directive({
  selector: '[email]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EmailValidator),
    multi: true
  }]
})
export class EmailValidator implements Validator {
  private readonly regEx = /^[^@]+@[^.]+(\.[^.]+)+$/;

  validate(control: UntypedFormControl): { [key: string]: any; } {
    if (String.isUndefinedOrEmpty(control.value)) {
      return null;
    }

    return this.regEx.test(control.value)
      ? null
      : { email: true };
  }

}
