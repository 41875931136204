import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: 'th[sortable]'
})
export class TableSortDirective {
  @Input('sortable') fieldName!: string;
  @Input('direction') sortOrder: 'asc' | 'desc' | '' = '';
  @Output() sort = new EventEmitter<SortBy>();
  rotate: { [key: string]: 'asc' | 'desc' | ''; } = { asc: 'desc', desc: '', '': 'asc' };

  @HostBinding('class.bi-chevron-expand') get isUnsorted(): boolean { return this.sortOrder === ''; }
  @HostBinding('class.bi-caret-up-fill') get isAsc(): boolean { return this.sortOrder === 'asc'; }
  @HostBinding('class.bi-caret-down-fill') get isDesc(): boolean { return this.sortOrder === 'desc'; }
  @HostListener('click') toggleSortDirection() {
    if (!this.fieldName) {
      throw new Error('No field-name specified for sortable column');
    }
    this.sortOrder = this.rotate[this.sortOrder]; // Set new direction
    this.sort.emit({ name: this.fieldName, direction: this.sortOrder });
  }
}

// The field and direction to sort by
export interface SortBy {
  name: string;
  direction: SortDirection;
}

export type SortDirection = 'asc' | 'desc' | '';
