import { Injectable } from '@angular/core';

@Injectable()
export class SuppressReloadService {
  private static key: string = 'suppressReloadPrompt';

  constructor() { }

  getValue(): boolean {
    try {
      const storedVal = sessionStorage.getItem(SuppressReloadService.key);
      return JSON.parse(storedVal);
    } catch (err) {
      return false; // Probably a JSON parse error from the value not being defined - assume it's not set
    }
  }

  setValue(val: boolean) {
    sessionStorage.setItem(SuppressReloadService.key, val.toString());
  }
}
