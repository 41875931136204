import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// port of uibTypeaheadHighlight from https://github.com/angular-ui/bootstrap/blob/master/src/typeahead/typeahead.js
@Pipe({
  name: 'textHighlight'
})
export class TextHighlightPipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) { }

  transform(text: string, match: string): SafeHtml {
    // Replaces the capture string with a the same string inside of a "strong" tag
    text = match ? ('' + text).replace(new RegExp(this.escapeRegexp(match), 'gi'), '<strong>$&</strong>') : text;
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  private escapeRegexp(queryToEscape) {
    return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  }
}
