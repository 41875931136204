import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

@Injectable()
export class SaneDateParserFormatter extends NgbDateParserFormatter {
  public static readonly parsingFormats = ['M-D-YYYY', 'MM-DD-YYYY', 'M/D/YYYY', 'MM/DD/YYYY'];
  public static readonly outputFormat = 'MM/DD/YYYY';

  parse(value: string): NgbDateStruct {
    if (String.isUndefinedOrEmpty(value)) {
      return null;
    }

    // dayjs will consider values like 1/013/20223 valid because of how it parses the string
    const parts = value.split(/\D/);
    if (parts.length < 3 || parts[0].length > 2 || parts[1].length > 2 || parts[2].length != 4) {
      return null;
    }

    const date = dayjs(value, SaneDateParserFormatter.parsingFormats, true);
    return date.isValid()
      ? { day: date.date(), month: date.month() + 1, year: date.year() }
      : null;
  }

  format(date: NgbDateStruct): string {
    if (Object.isDefined(date) && (date.month > 0) && (date.year > 0)) {
      const jsDate = new Date(date.year, date.month - 1, date.day > 0 ? date.day : 1);
      return dayjs(jsDate).format(SaneDateParserFormatter.outputFormat);
    } else {
      return null;
    }
  }

  /**
   * Attempts to create an instance of Date based on the values stored in the NgbDateStruct
   * @param date An NgbDateStruct created by this parser (ideally anyway)
   * @returns A Date instance if the values are valid, null otherwise.
   */
  toValidDate(date: NgbDateStruct): Date {
    if (date) {
      const d = new Date(date.year, date.month - 1, date.day);
      // 9999 is arbitrary but it's done to avoid 5+ digit years which are technically valid
      if (d.getFullYear() == date.year && date.year >= 1000 && date.year <= 9999 && d.getMonth() + 1 == date.month && d.getDate() == date.day) {
        return d;
      }
    }
    return null;
  }

  createNgbDateStruct(value: string | Date): NgbDateStruct {
    if (value) {

      if (value instanceof Date) {
        return {
          day: value.getDate(),
          month: value.getMonth() + 1,
          year: value.getFullYear()
        };
      }

      const parsed = this.parse(value);
      if (parsed) {
        return parsed;
      }
    }
    return { day: 0, month: 0, year: 0 };
  }
}