<div class="modal-header">
  <h5 class="modal-title text-center">Select one or more {{ options?.plurals.plural| humanReadable }}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal(true)"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="list-container">
      <input type="text" [(ngModel)]="searchText" placeholder="Search" class="mb-3 full-width" />
      <ul [class.scrollable]="filteredOptions.length > 10">
        <li *ngFor="let option of filteredOptions">
          <label>
            <input type="checkbox" [(ngModel)]="option.checked" (change)="onCheckboxChange()" />
            {{ option.value }}
          </label>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="modal-footer revert-btn mx-3">
  <div class="count-info" class="me-auto">
    <ng-container *ngIf="filteredOptions.length === listOptions.length; else showFilteredCount">
      Displaying {{ listOptions.length }}
      {{ (listOptions.length === 1 ? options?.plurals.singular : options.plurals.plural) | humanReadable }}
      ({{checkedCount}} selected)
    </ng-container>
    <ng-template #showFilteredCount>
      Displaying {{ filteredOptions.length }} of {{ listOptions.length }}
      {{ (listOptions.length === 1 ? options?.plurals.singular : options?.plurals.plural) | humanReadable  }}
      ({{checkedCount}} selected)
    </ng-template>
  </div>
  <div class="d-flex flex-wrap align-items-center">
    <button type="button" class="btn btn-action" (click)="clear()" title="Clear values"
            id="dtp_btn_clear">Clear</button>
    <button type="button" ngbAutofocus class="btn btn-action" (click)="closeModal()"
            title="Save range values and continue" id="dtp_btn_submit">Submit</button>
  </div>
</div>