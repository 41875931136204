import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Resource } from '@core/index';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'notification-error-modal',
  templateUrl: './error-modal.component.html'
})
export class NotificationErrorModalComponent implements OnDestroy {
  title: string | Resource = 'Error';
  message: string | Resource;
  details: string[] = [];
  private readonly sub: SubscriptionLike;

  constructor(private readonly instance: NgbActiveModal, location: Location) {
    this.sub = location.subscribe(() => instance.dismiss());
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  close() {
    this.instance.close();
  }
}
