import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  // maxLength *includes* the '...' chars, if applicable
  transform(value: string, maxLength = 30, showEllipsis = true) {
    const truncateLength = (showEllipsis) ? maxLength - 3 : maxLength;
    if (value.length > maxLength) {
      return value.substring(0, truncateLength) + ((showEllipsis) ? '...' : '');
    } else {
      return value;
    }
  }
}
