export interface Platform {
  id: number;
  name: string;
  url?: string;
  ssoUrl?: string;
}

export enum PlatformNames {
  Artisans = 'RPS Artisans',
  FirstRate = 'First Rate',
  InsureZone = 'RPS Standard Lines',
  PersonalExpress = 'E&S Personal Express',
  SmallBusiness = 'RPS Small Business',
  Usli = 'USLI'
}
