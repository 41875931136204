import { Component, OnInit } from '@angular/core';
import { DirectoryService, NotificationService, ProfileService } from '@app/core';
import { environment } from '@env';
import { RequestLoginModalComponent } from '../request-login-modal/request-login-modal.component';

@Component({
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  maintenanceMessage: string;
  loginDisabled = false;
  enableEmployeeLogin = false;

  constructor(
    private readonly directory: DirectoryService,
    private readonly profile: ProfileService,
    private readonly notify: NotificationService
  ) { }

  ngOnInit() {
    const maintenance = this.directory.getDefinition('maintenanceMessage', '^1.0.0');
    this.maintenanceMessage = maintenance?.maintenanceMessage || null;
    this.loginDisabled = maintenance?.disableLoginButton == 'true';
    this.enableEmployeeLogin = environment.enableEmployeeLogin && !this.loginDisabled;
  }

  login(employee = false) {
    this.profile.redirectToLogin(employee);
  }

  async requestLogin() {
    try {
      await this.notify.showModalTemplate(RequestLoginModalComponent);
    }
    catch (e) {
      // cancel
    }
  }
}