import { ODataQueryFilterBuilder } from './odata-filter-builder';
import { ODataQueryBuilderBase } from './odata-query-builder-base';
import { ODataTransformBuilder } from './odata-transform-builder';

// default implementation of a query builder - doesn't add anything new or special
export class ODataQueryBuilder extends ODataQueryBuilderBase<ODataQueryFilterBuilder<ODataQueryBuilder>, DefaultTransformBuilder> {
  constructor(allowEmptyStrings = false) {
    super();
    this.assignFilter = new ODataQueryFilterBuilder(this, allowEmptyStrings);

    // transform.query is assigned to "this" for chaining (qb.apply.query would return "this")
    const transform = new DefaultTransformBuilder(this);
    // filter is assigned to the transform for chaining (filter.query would return transform)
    transform.filter = new ODataQueryFilterBuilder(transform);
    this.assignApply = transform;
  }
}

// this class is just a marker interface to make the generics easier on the eyes for ODataQueryBuilder
export class DefaultTransformBuilder extends ODataTransformBuilder<ODataQueryBuilder, ODataQueryFilterBuilder<DefaultTransformBuilder>> { }