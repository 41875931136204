import { AfterContentInit, Component, ContentChildren, ElementRef, Input, OnChanges, OnDestroy, QueryList } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Resource } from '@app/core/text-resources';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { defaultErrorMessages, ErrorMessageConfig } from './default-error-messages';

@Component({
  selector: 'validateInput',
  templateUrl: './input-validation.component.html'
})
export class InputValidationComponent implements AfterContentInit, OnChanges, OnDestroy {
  @Input() validateInput: ErrorMessageConfig = {};
  @Input() noErrors = false;

  @ContentChildren(NgModel) childModels: QueryList<NgModel>;

  hasErrors = false;
  hasSuccess = false;
  errorMessages: (string | Resource)[] = [];

  private errorMessageResources: ErrorMessageConfig;
  private readonly debounceTime = 500;
  private contentSub = new Subscription();
  private ngModelSub = new Subscription();
  private observer: MutationObserver;

  constructor(private readonly element: ElementRef) { }

  ngAfterContentInit() {
    this.contentSub = this.childModels.changes.subscribe(() => this.updateContentSubscriptions());
    this.updateContentSubscriptions(); // kick it off the first time

    /*
     * Sometimes the content thingy doesn't get updated (e.g., *ngFor updates seems to get completely
     * missed, probably because it's not modifying a direct child of the container). We're falling back
     * on MutationObserver, which fortunately for us was added to IE11 before they quit adding stuff.
     * I mean, FF support for it goes back to version 14, so it's not like IE11 finally getting their
     * act together for a single browser API should be cause for celebration, but that's life as a web
     * developer.
     */
    this.observer = new MutationObserver(() => {
      this.childModels.notifyOnChanges();
    });
    this.observer.observe(this.element.nativeElement, { childList: true, subtree: true });
  }

  ngOnChanges() {
    this.errorMessageResources = {
      ...defaultErrorMessages,
      ...this.validateInput
    };
  }

  ngOnDestroy() {
    this.contentSub.unsubscribe();
    this.ngModelSub.unsubscribe();
    this.observer.disconnect();
  }

  private updateContentSubscriptions() {
    this.childModels.forEach(model => {
      if (String.isUndefinedOrEmpty(model.name)) { return; }
      // IE11 Does not support forEach() on NodeList objects.
      const elemList: Array<Node> = Array.from(this.element.nativeElement.querySelectorAll('input,select,textarea,button'));
      if (elemList.length === 0) { return; }

      const elems: HTMLElement[] = [];
      elemList.forEach(node => {
        const el = node as HTMLElement;
        elems.push(el); // arrays have more built in methods for finding things
        el.addEventListener('blur', () => this.updateModelStatusClasses(model, elems));
      });

      const vSub = model.valueChanges
        .pipe(debounceTime(this.debounceTime))
        .subscribe(() => this.updateModelStatusClasses(model, elems));

      this.ngModelSub.add(vSub);
    });
  }

  private updateModelStatusClasses(model: NgModel, elems: HTMLElement[]) {
    if (Object.isDefined(model) && this.shouldShowClasses(model, elems)) {
      this.hasErrors = this.getModelErrorState(model);
      this.hasSuccess = this.getModelSuccessState(model, elems);
      this.mapModelErrors(model);
    } else {
      this.hasErrors = false;
      this.hasSuccess = false;
      this.errorMessages.length = 0;
    }
  }

  private shouldShowClasses(model: NgModel, elems: HTMLElement[]) {
    return model.touched ||
      (Object.isDefined(model.formDirective) && model.formDirective.submitted) ||
      this.hasViewValue(elems);
  }

  private getModelErrorState(model: NgModel) {
    const hasErrors = Object.isDefined(model.errors)
      ? Object.keys(model.errors).length > 0
      : false;

    return model.invalid || hasErrors;
  }

  private getModelSuccessState(model: NgModel, elems: HTMLElement[]) {
    return model.valid && this.hasViewValue(elems); // Skipping non-required fields isn't "success"
  }

  private hasViewValue(elems: HTMLElement[]) {
    return elems.some(el => {
      const tagName = el.tagName.toLowerCase();
      const inputType = (el.getAttribute('type') || '').toLowerCase();

      if (tagName === 'button') {
        return false;
      } else if (tagName === 'input' && (inputType === 'checkbox' || inputType === 'radio')) {
        return this.isChecked(el);
      } else {
        const val = (el as any).value;
        return Object.isDefined(val) &&
          val.length > 0 &&
          !(inputType === 'number' && isNaN(val));
      }
    });
  }

  private isChecked(el: HTMLElement) {
    return (el as HTMLInputElement).checked;
  }

  private mapModelErrors(model: NgModel) {
    this.errorMessages.length = 0;
    if (this.noErrors) { return; }
    if (Object.isUndefined(model.errors)) { return; }

    for (const key in model.errors) {
      if (!model.errors.hasOwnProperty(key)) { continue; }
      if (Object.isDefined(this.errorMessageResources[key])) {
        this.errorMessages.push(this.errorMessageResources[key]);
      }
    }
  }
}
