import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { cloneDeep } from 'lodash';
import { RegionData } from './region-data';

@Injectable()
export class GeoService {

  private static readonly Territories = ['as', 'mp', 'gu', 'fm', 'mh', 'pw', 'pr', 'vi'];

  private request: Promise<RegionData>;
  private data: RegionData;
  private readonly uri: string;

  constructor(private readonly http: HttpClient) {
    this.uri = environment.geoApiUri + 'region';
  }

  getRegionList() {
    if (Object.isDefined(this.request)) {
      return this.request;
    }

    if (Object.isDefined(this.data) && Object.keys(this.data).length > 0) {
      return Promise.resolve(cloneDeep(this.data));
    }
    // No states, so let's go get some!
    this.request = this.http.get<RegionData>(this.uri).toPromise()
      .then((data) => {
        for (const terr of GeoService.Territories) {
          delete data[terr];
        }
        this.data = data;
        this.request = null;
        return cloneDeep(data);
      });

    return this.request;
  }
}
