// https://developers.google.com/maps/documentation/javascript/reference#AutocompleteService

import { NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IAutocomplete {
  addListener(eventName: string, callback: Function): IEventListenerHandle;
  getPlace(): IPlaceDetail;
}

export interface IEventListenerHandle {
  remove(): void;
}

export interface IAutocompleteOptions {
  /** restricts predictions to one or more autocomplete types
   */
  types?: AutocompleteType[];
  /** restricts predictions to one or more countries
   */
  componentRestrictions?: { country: string | string[]; };
}

export interface IPlacesService {
  getDetails(request: { placeId: string; }, callback: (result: IPlaceDetail, status: string) => void): void;
}

export interface IAutocompleteService {
  getPlacePredictions(request: IPlacePredictionRequest, callback: (result: IQueryPrediction[]) => void): void;
}

export interface IPlacePredictionRequest extends IAutocompleteOptions {
  /** the user input to get predictions for
   */
  input: string;
}

export interface IQueryPrediction {
  description: string;
  place_id: string;
  terms: Array<{ offset: number, value: string; }>;
}

export interface IPlaceDetail {
  /** this is either the raw text entered by the user or the name of the place depending upon the source
   */
  name: string;
  place_id?: string;
  address_components?: IAddressComponent[];
  formatted_address?: string;
}

export interface IAddressComponent {
  long_name: string;
  short_name: string;
  types: AddressComponentType[];
}

// https://developers.google.com/places/supported_types

export enum AutocompleteType {
  Geocode = 'geocode',
  Address = 'address',
  Establishment = 'establishment',
  Regions = '(regions)',
  Cities = '(cities)'
}

export enum AddressComponentType {
  StreetNumber = 'street_number',
  Route = 'route',
  Locality = 'locality',
  District = 'administrative_area_level_2',
  Region = 'administrative_area_level_1',
  Country = 'country',
  PostalCode = 'postal_code',
  Sublocality = 'sublocality'
}

export class AutocompleteInput {

  private _googleHandle: IEventListenerHandle;
  private _subject: Subject<IPlaceDetail>;
  private _event: Observable<IPlaceDetail>;

  constructor(
    private readonly input: IAutocomplete,
    private readonly zone: NgZone) {
  }

  get onPlaceChanged(): Observable<IPlaceDetail> {
    if (Object.isUndefined(this._subject)) {
      this._subject = new Subject<IPlaceDetail>();
      this._event = this._subject.asObservable();
      // google events happen out of band for angular so zone is needed to bring them back in
      this._googleHandle = this.input.addListener('place_changed', () => {
        this.zone.run(() => this._subject.next(this.input.getPlace()));
      });
    }
    return this._event;
  }

  /**
   * detaches the event pipe for OnPlaceChanged events
   */
  dispose() {
    if (Object.isDefined(this._subject)) {
      this._googleHandle.remove();
      this._subject.complete();
      this._subject = null;
      this._event = null;
    }
  }

  /**
   * gets the currently selected place in the auto complete
   */
  getPlace() {
    return this.input.getPlace();
  }
}
