import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorService } from '@app/core/api-error.service';
import { environment } from '@env';
import { Debug } from '../debug';
import { UserProfile } from '../profile';
import { ProfileService } from '../profile/profile.service';
import { UserType } from '../user';
import { AimUser } from './aim.interfaces';
import { AimService } from './aim.service';

@Injectable({
  providedIn: 'root'
})
export class AimUserProfileService {

  private readonly aimUserCache = new Map<number, AimUser>();

  constructor(
    private readonly profile: ProfileService,
    private readonly error: ApiErrorService,
    private readonly aim: AimService
  ) { }

  /**
   * Convenience function - returns the profile from the ProfileService
   * @returns Current users profile
   */
  getProfile(): Promise<UserProfile> {
    return this.profile.getProfile();
  }

  /**
   * Gets the aim user's information associated with the logged in internal user.
   * This function will attempt to cache the result to reduce http requests.
   * @param userId: rps security's userId (number) to look up instead of the logged in user's id
   * @returns Aim User's information or null if aim user not set in security (InternalUser table)
   */
  async getAimUserForActiveProfile(): Promise<AimUser> {
    const userProfile = await this.profile.getProfile();
    if (userProfile.userType !== UserType.Internal) {
      // only internal users (employees) will have aim user details
      return null;
    }

    if (environment.useLocalData) {
      Debug.log('Returning local aim security user data');
      const prof = this.createAimUserFromProfile(userProfile);
      prof.id = prof.id || 1;
      return Promise.resolve(prof);
    }

    if (userProfile.aimUserId) {
      return await this.getAimUser(userProfile.aimUserId);
    } else {
      return null;
    }
  }

  /**
   * Gets an aim user from a local cache or requests the user information from the API, caches it,
   * and returns it.
   * @param aimUserId The Aim User ID. Note that this is the Aim User ID and NOT the Security User ID
   * @returns The user or null if it doesn't exist.
   */
  public async getAimUser(aimUserId: number): Promise<AimUser> {
    let user: AimUser = this.aimUserCache.get(aimUserId);
    if (user) {
      return user;
    }

    try {
      user = await this.aim.getUser(aimUserId);
    } catch (e) {
      const response = e as HttpErrorResponse;
      if (response.status == 404) {
        Debug.warn(`aim user ${aimUserId} not found`);
        this.aimUserCache.set(aimUserId, null);
      } else {
        this.error.processError(e, 'getAimUser');
      }
      return null;
    }

    this.aimUserCache.set(aimUserId, user);
    return user;
  }

  private createAimUserFromProfile(user: UserProfile): AimUser {
    return {
      id: user.aimUserId,
      email: user.username,
      name: `${user.firstName} ${user.lastName}`,
      isAccountExec: user.isAccountExec,
      isMarketingRep: null,
      teamId: null,
      isActive: true
    };
  }
}