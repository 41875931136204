<div *ngIf="show()" class="alert alert-danger p-1 mt-1 mb-0">
  <div class="small" *ngIf="input.errors?.['required'] && !hasSpecificError()">
    This field is required.
  </div>
  <div class="small" *ngIf="input.errors?.['minlength'] && !hasSpecificError()">
    Value is too short. It must be at least {{input.errors?.['minlength'].requiredLength}} characters long
  </div>
  <div class="small" *ngIf="input.errors?.['maxlength'] && !hasSpecificError()">
    Value is too long. It must be at most {{input.errors?.['maxlength'].requiredLength}} characters long
  </div>
  <div class="small" *ngIf="input.errors?.['min']">
    Value is too low. It must be at least {{input.errors?.['min'].min}}
  </div>
  <div class="small" *ngIf="input.errors?.['max']">
    Value is too high. It must be at most {{input.errors?.['max'].max}}
  </div>
  <div class="small" *ngIf="input.errors?.['isalphanumeric'] && !hasSpecificError()">
    Must be alphanumeric (no special characters or accents)
  </div>
  <div class="small" *ngIf="input.errors?.['pattern'] && !hasSpecificError()">
    Value must contain only {{humanReadableRegex(input.errors?.['pattern'].requiredPattern)}}
  </div>
  <div class="small" *ngIf="input.errors?.['email']">
    Must be a valid email address.
  </div>
  <div class="small" *ngIf="input.errors?.['name']">
    Must contain valid name-style words (no special characters or accents)
  </div>
  <div class="small" *ngIf="input.errors?.['businessName']">
    Please limit name to letters, numbers, and these characters: ! @ # $ & * , . - : % (no accents)
  </div>
  <div class="small" *ngIf="input.errors?.['address']">
    Must contain a valid street address, including box number
  </div>
  <div class="small" *ngIf="input.errors?.['addressLineTwo']">
    Must contain a c/o, PO Box, or Apt/Suite number
  </div>
  <div class="small" *ngIf="input.errors?.['phone']">
    Phone number must be in the format of ### ### #### or # ### ### ####
  </div>
  <div class="small" *ngIf="input.errors?.['state']">
    State abbreviation must be two letters. (eg: CA or TX)
  </div>
  <div class="small" *ngIf="input.errors?.['zip']">
    Zip code must be in the format of ##### or #####-####
  </div>
  <div class="small" *ngIf="input.errors?.['mustMatch']">
    {{input.errors?.['mustMatch']}} must match
  </div>
  <div class="small" *ngIf="input.errors?.['checkboxRequired']">
    Please select at least one item.
  </div>
  <div class="small" *ngIf="input.errors?.['url']">
    Url is invalid
  </div>
</div>