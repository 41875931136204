import { ResourceService } from './resource.service';

export interface Resource {
  key: string;
  group: string;
}

export class ResourceConverter {
  /** helper function that converts string or resource typed values to resource objects
   * @param value the value to convert
   * @param defaultGroup the default group name to use when the value is a string or the group is missing.
   * the default value is ResourceService.defaultGroupKey.
   * @returns a resource
   */
  static convert(value: string | Resource, defaultGroup?: string): Resource {
    const group = String.isNotEmpty(defaultGroup) ? defaultGroup : ResourceService.defaultGroupKey;
    if (typeof value === 'string' || Object.isUndefined(value)) {
      // any type assertion to get around transpiler error because of the or in the if statement
      return { key: value as any, group };
    }
    value.group = String.isNotEmpty(value.group) ? value.group : group;
    return value;
  }

  /** helper function that checks if a value is either a non-empty string or a resource with a non-empty key
   * @returns true if it's a valid string or resource with key; false otherwise
   */
  static hasTextOrKey(value: string | Resource) {
    if (Object.isDefined(value)) {
      return typeof value === 'string'
        ? String.isNotEmpty(value)
        : String.isNotEmpty(value.key);
    }
    return false;
  }
}
