<div id="notification-message-modal">
  <div class="modal-header">{{title | getresource}}</div>
  <div class="modal-body">
    <p>
      {{message | getresource}}
    </p>
  </div>
  <div class="modal-footer d-flex align-items-end">
    <button id="notification-message-modal-cancel" class="btn btn-default" (click)="cancel()"
            *ngIf="cancelVisible">{{cancelTitle | getresource}}</button>
    <button id="notification-message-modal-close" class="btn btn-action"
            (click)="confirm()">{{confirmTitle | getresource}}</button>
  </div>
</div>