import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { HomeRedirectService } from './home-redirect.service';


@Injectable({
  providedIn: 'root'
})
export class WelcomeGuard implements CanActivate, CanActivateChild {

  constructor(
    private readonly homeRedirect: HomeRedirectService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.redirectAuthenticatedUser();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.redirectAuthenticatedUser();
  }

  // this is basically the opposite of the standard route guard. if they are authenticated, we want to send them to their landing page.
  private async redirectAuthenticatedUser() {

    const profile = await this.homeRedirect.activeUser();
    if (!profile.isAuthenticated) {
      return true;
    }

    if (this.homeRedirect.isLocalhost()) {
      return true;
    }

    await this.homeRedirect.redirect(profile);

    return false;
  }
}
