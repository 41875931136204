import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Debug, MessageModalButtonMode, ModalSpinnerService, NotificationService } from '@app/core';
import { environment } from '@env';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthHttpInterceptor } from '../profile';
import { WindowService } from '../window.service';
import { InsureZoneService, InsureZoneSsoToken } from './insure-zone.service';
import { Platform, PlatformNames } from './platform';
import { NavigationInformation, PlatformAccessResult } from './platform-access-result';
import { PlatformList } from './platform-list';
import { Retailer, RetailerOrganizationStatus } from './retailer';

@Injectable()
export class PlatformService {

  private readonly platformCache: Map<number, Platform> = new Map<number, Platform>();

  private getAllPlatforms$: Observable<Platform[]>;

  static readonly usliId = 4;

  constructor(
    private http: HttpClient,
    private windowService: WindowService,
    private readonly insureZoneService: InsureZoneService,
    private readonly notificationService: NotificationService,
    private readonly spinner: ModalSpinnerService,
    // private readonly router: Router
  ) { }

  getAllPlatforms(): Observable<Platform[]> {
    if (!this.getAllPlatforms$) {
      this.getAllPlatforms$ = this.http.get<PlatformList<any>>(environment.catalogApiUri + '/list/platforms', AuthHttpInterceptor.AddAuthHttpHeader).pipe(
        switchMap(data => {
          return of(Object.isUndefined(data) || data.items.length === 0 ? null : data.items.map<Platform>(item => {
            const result = { ...item };
            if (result.platformUrl) {
              result.url = result.platformUrl;
              delete result.platformUrl;
            }
            delete result.uri;
            return result;
          }));
        }),
        shareReplay(1)
      );
    }
    return this.getAllPlatforms$;
  }

  getAllPlatformsWithUrl(): Observable<Platform[]> {
    return this.getAllPlatforms().pipe(
      switchMap(data => {
        return of(Object.isUndefined(data) || data.length === 0 ? null : this.filterPlatforms(data));
      })
    );
  }

  getPlatform(id: number): Observable<Platform> {
    const platform = this.platformCache.get(id);
    if (Object.isDefined(platform)) {
      return of(platform);
    }
    return this.http.get<Platform>(environment.catalogApiUri + '/platform/' + id, AuthHttpInterceptor.AddAuthHttpHeader)
      .pipe(tap(value => this.platformCache.set(value.id, value)));
  }

  checkRetailerPlatformAccess(platform: Platform, retailer: Retailer): Observable<PlatformAccessResult> {
    if (!platform || !retailer) return of(null);

    if (!retailer.status) {
      return of({ hasAccess: true, platformName: platform.name, navInfo: this.createNavInfo(platform.ssoUrl, platform.url), allowSelfGrant: false });
    }

    if (platform.name === PlatformNames.InsureZone) {
      return this.insureZoneService.getSsoToken(platform.ssoUrl).pipe(
        switchMap(res => {
          const token = res as InsureZoneSsoToken;
          return of({ hasAccess: true, platformName: platform.name, navInfo: this.createNavInfo(null, token.URL), allowSelfGrant: false });
        }),
        catchError(err => {
          Debug.log(`${err.errorNumber}: ${err.userMessage}`, err);

          return of({ hasAccess: false, platformName: platform.name, messageTemplate: 'retailer.standardlines.redirect.error', allowSelfGrant: (err.errorNumber == 403 || err.errorNumber == 404) });
        })
      );
    }

    if (retailer.status == RetailerOrganizationStatus.Terminated) {
      return of({ hasAccess: false, platformName: platform.name, messageTemplate: 'retailer.account-terminated.text', allowSelfGrant: false });
    }

    if (!(retailer.status == RetailerOrganizationStatus.Active || retailer.status == RetailerOrganizationStatus.CashWithApp)) {
      return of({ hasAccess: false, platformName: platform.name, messageTemplate: 'retailer.account-status.text', navInfo: { openInNewWindow: false, isSsoUrl: false, redirectUrl: environment.myrpsUri + '/RAMP/Agency' }, allowSelfGrant: false });
    }

    return of({ hasAccess: true, platformName: platform.name, navInfo: this.createNavInfo(platform.ssoUrl, platform.url), allowSelfGrant: false });
  }

  async grantAccess(platformName: string) {
    if (platformName != PlatformNames.InsureZone) return;
    this.spinner.start();
    const proceed = await this.verifySelfGrant();
    if (proceed) {
      try {
        await this.insureZoneService.setupAccess();
        const okOpts = {
          mode: MessageModalButtonMode.Close,
          confirmTitle: 'Ok'
        };
        await this.notificationService.showModalMessage({ key: 'retailer.standardlines-setup-success.title', group: 'retailer' }, { key: 'retailer.standardlines-setup-success.message', group: 'retailer' }, okOpts);
        // TODO: Revert back to /logout in myrps-803
        // await this.router.navigateByUrl('/logout');
        window.location.assign(environment.portalApiUri + 'Account/SignOut');
        return;
      } catch (err) {
        this.notificationService.showModalMessage('', { key: 'retailer.standardlines-setup.error', group: 'retailer' });
      }
    }
    this.spinner.stop();
  }

  static isUsli(platform: Platform) {
    return platform.id === PlatformService.usliId;
  }

  private async verifySelfGrant() {
    try {
      await this.insureZoneService.canGrantAccess();
      const confirmOpts = {
        mode: MessageModalButtonMode.ConfirmCancel,
        cancelTitle: 'Cancel',
        confirmTitle: 'Request'
      };
      return await this.notificationService.showModalMessage(
        { key: 'retailer.standardlines-request.title', group: 'retailer' },
        { key: 'retailer.standardlines-request.message', group: 'retailer' },
        confirmOpts)
        .catch(() => false);
    } catch (e) {
      const error = e as HttpErrorResponse;
      if (error.status != 400) {
        this.notificationService.showModalMessage('', { key: 'retailer.redirect.error', group: 'retailer' });
      } else {
        this.notificationService.showModalMessage('', error.error.message);
      }
    }
    return false;
  }

  private createNavInfo(ssoUrl: string, url: string): NavigationInformation {
    if (String.isUndefinedOrEmpty(ssoUrl) && String.isUndefinedOrEmpty(url)) return null;

    const isSso = String.isNotEmpty(ssoUrl);
    const redirectUrl = isSso ? ssoUrl : url;
    const info = this.inspectAndTransformUrl(redirectUrl);
    info.isSsoUrl = isSso;
    return info;
  }

  /* checks the url's origin to see if it is a local path and, if so, removes the unnecessary parts so that
   * angular routing can be used.
   */
  inspectAndTransformUrl(url: string): NavigationInformation {
    if (String.isUndefinedOrEmpty(url)) return null;

    const origin = this.windowService.nativeWindow.location.origin;
    const isLocal = String.contains(origin, 'localhost');
    const targetRoot = '/portal-client';
    let actualRoot = '';

    if (!isLocal) {
      const rootParts: string[] = this.windowService.nativeWindow.location.pathname.split('/');
      // defensive: should always get at least 2 segments - an empty one for the starting / and one for our root
      actualRoot = rootParts.length > 1
        ? `/${String.isUndefinedOrEmpty(rootParts[0]) ? rootParts[1] : rootParts[0]}`
        : targetRoot;

    } else {
      // local testing there is no site root but we can assume that urls received from dev/stage will use targetRoot
      actualRoot = targetRoot;
    }

    // check to see if the app is hosted in a directory that is not portal-client
    if (actualRoot != targetRoot) {
      url = url.replace(targetRoot, actualRoot);
    }

    if (String.contains(url, origin + actualRoot)) {
      // path within our app; need to remove host + root dir so that we can route properly
      return { openInNewWindow: false, isSsoUrl: false, redirectUrl: url.replace(origin + actualRoot, '') };
    } else if (isLocal && String.contains(url, actualRoot)) {
      // same deal but... for local testing, the host will typically be mydev if we're pointed at the mydev catalog service
      return { openInNewWindow: false, isSsoUrl: false, redirectUrl: url.replace(`https://mydev${actualRoot}`, '') };
    } else {
      return { openInNewWindow: true, isSsoUrl: false, redirectUrl: url };
    }
  }

  private filterPlatforms(arr: Platform[]): Platform[] {
    let filteredPlatforms: Platform[] = arr.filter(x => x.ssoUrl || x.url);
    return filteredPlatforms.sort((a, b) => a.name.localeCompare(b.name));
  }
}
