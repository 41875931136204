import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountLayoutComponent } from './account/account-layout/account-layout.component';
import { PasswordResetComponent } from './account/password-reset/password-reset.component';
import { SetPasswordComponent } from './account/set-password/set-password.component';
import { WelcomeGuard } from './account/welcome.guard';
import { WelcomeComponent } from './account/welcome/welcome.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    component: AccountLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: WelcomeComponent,
        canActivate: [WelcomeGuard]
      },
      {
        path: 'set-password/:token',
        pathMatch: 'full',
        component: SetPasswordComponent
      },
      {
        path: 'set-password/:token/:email',
        pathMatch: 'full',
        component: SetPasswordComponent
      },
      {
        path: 'reset-password',
        pathMatch: 'full',
        component: PasswordResetComponent
      },
      {
        path: 'reset-password/:email',
        pathMatch: 'full',
        component: PasswordResetComponent
      }
    ]
  }
];

routes.push(
  { path: 'fulfillment', pathMatch: 'prefix', loadChildren: () => import('app/fulfillment/fulfillment.module').then(m => m.FulfillmentModule) },
  { path: 'payments', pathMatch: 'prefix', loadChildren: () => import('app/payments/payments.module').then(m => m.PaymentsModule) },
  { path: 'management', pathMatch: 'prefix', loadChildren: () => import('app/management/management.module').then(m => m.ManagementModule) },
  { path: 'onboarding', pathMatch: 'prefix', loadChildren: () => import('app/onboarding/onboarding.module').then(m => m.OnboardingModule) },
  { path: 'retailer', pathMatch: 'prefix', loadChildren: () => import('app/retailer/retailer.module').then(m => m.RetailerModule) },
  { path: 'finance', pathMatch: 'prefix', loadChildren: () => import('app/finance/finance.module').then(m => m.FinanceModule) },
  { path: 'request', pathMatch: 'prefix', loadChildren: () => import('app/request/request.module').then(m => m.RequestModule) },
  { path: 'producer', pathMatch: 'prefix', loadChildren: () => import('./producer/producer.module').then(m => m.ProducerModule) },
  { path: 'ulp', pathMatch: 'prefix', redirectTo: 'retailer' }
);

@NgModule({
  // to debug routing issues add { enableTracing: true } after routes
  imports: [RouterModule.forRoot(routes)],
  exports: []
})
export class AppRoutingModule { }