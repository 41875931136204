import { Injectable } from '@angular/core';
import { AimService } from '@app/core/aim/aim.service';
import { ApiErrorService } from '@app/core/api-error.service';
import { ProfileService } from '../profile/profile.service';
import { UserType } from '../user';
import { AimRetailer } from './aim.interfaces';

@Injectable({
  providedIn: 'root'
})
export class RetailerProfileService {

  private retailerCache = new Map<string, AimRetailer>();

  constructor(
    private readonly aim: AimService,
    private readonly profile: ProfileService,
    private readonly error: ApiErrorService
  ) { }

  /**
   * Convenience function - returns the profile from the ProfileService
   * @returns Current users profile
   */
  getProfile() {
    return this.profile.getProfile();
  }

  /**
   * Gets the retailer information associated with the logged in user.
   * This function will attempt to cache the result to reduce http requests.
   * retailerId: string [null] Optional retailerId to look up instead of the logged in user's organizationCode
   * @returns Retailer information or null if the user can't access that info (or there was an error)
   */
  async getRetailer(retailerId: string = null) {
    const userProfile = await this.profile.getProfile();
    retailerId = retailerId?.toUpperCase() || userProfile.organizationCode?.toUpperCase() || null;

    // Internal users don't have retailer info
    if (!retailerId && userProfile.userType == UserType.Internal) {
      return null;

      // External users fetching their own data, or internal users fetching data of external users
    } else if (retailerId && (userProfile.userType == UserType.Internal || retailerId == userProfile.organizationCode?.toUpperCase())) {

      // If the data already exists in cache, return it
      if (this.retailerCache.has(retailerId)) {
        return this.retailerCache.get(retailerId);
      }

      // Else fetch it the old fashioned way
      try {
        this.retailerCache.set(retailerId, await this.aim.getProducer(retailerId));
        return this.retailerCache.get(retailerId);
      } catch (e) {
        this.error.processError(e, 'getRetailer');
      }
    }

    // External users don't have access to other retailers' data (or there was an error)
    return null;
  }

}
