import { Location } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike } from 'rxjs';
import { MultiCheckSearchOptions, MultiCheckSearchResult } from './multi-check-search-selector.interface';

@Component({
  selector: 'multi-check-search-selector',
  templateUrl: './multi-check-search-selector.component.html',
  styleUrls: ['./multi-check-search-selector.component.scss']
})
export class MultiCheckSearchSelectorComponent implements OnInit, OnChanges, OnDestroy {

  private readonly defaultOptions: MultiCheckSearchOptions = {
    plurals: { singular: 'item', plural: 'items' },
    items: []
  };

  @Input() options?: MultiCheckSearchOptions;

  protected searchText = '';
  protected listOptions: Option[];
  protected checkedCount: number = 0;
  protected initSelectedItems: string[] = [];
  protected initSearchText: string;
  private readonly sub: SubscriptionLike;

  constructor(public modalInstance: NgbActiveModal, location: Location) {
    this.sub = location.subscribe(() => this.closeModal(true));
    this.options = this.defaultOptions;
    this.listOptions = [];
  }

  get filteredOptions() {
    return this.listOptions.filter(option =>
      option.value?.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  get checkedItems() {
    return this.listOptions.filter(option => option.checked);
  }

  ngOnInit() {

    if (this.options.searchText) {
      this.searchText = this.options.searchText;
    }
    if (this.options.selectedItems) {
      this.initSelectedItems = this.options.selectedItems;
    }
    this.initSearchText = this.options.searchText;
    this.listOptions = this.options.items.map((value) => {
      return {
        id: value.name,
        value: value.value,
        label: value.name,
        checked: this.options.selectedItems && this.options.selectedItems.includes(value.name)
      };
    });
    this.updateCheckedCount();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.listOptions = this.options.items.map((value) => {
        return {
          id: value.name,
          value: value.value,
          label: value.name,
          checked: false
        };
      });
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  // Clear inputs
  clear() {
    this.searchText = '';
    for (let i = 0; i < this.listOptions.length; i++) {
      this.listOptions[i].checked = false;
    }
    this.updateCheckedCount();
  }

  // Close modal and return search text and selected items, if any
  closeModal(cancelled = false) {
    if (cancelled) {
      this.modalInstance.close({
        searchText: this.initSearchText,
        selectedItems: this.initSelectedItems,
        isChanged: false
      } as MultiCheckSearchResult);
    }
    else {
      const selectedOptions = this.listOptions
        .filter(option => option.checked)
        .map(option => option.id);
      this.modalInstance.close({
        searchText: this.searchText,
        selectedItems: selectedOptions,
        isChanged: selectedOptions.length !== this.initSelectedItems.length || !selectedOptions.every(item => this.initSelectedItems.includes(item))
      } as MultiCheckSearchResult);
    }
  }

  // Method to handle checkbox change event
  onCheckboxChange(): void {
    this.updateCheckedCount();
  }

  updateCheckedCount(): void {
    this.checkedCount = this.listOptions.filter(option => option.checked).length;
  }
}

interface Option {
  id: string;
  value: any;
  checked: boolean;
}
