// Response from security/api/user/get/{id}
export interface User {
  id: number;
  securityId: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isLocked: boolean;
  lastChangedAt: string | Date;
  organizationCode: string;
  organizationName: string;
  userType: UserType;
  roles: string[]; // These are URIs to fetch each individual role name, NOT the actual role info
  routes: JsonObject;
  userPrincipalName: string;
  aimUserId?: number;
  isAccountExec?: boolean;
}

export enum UserType {
  Internal = 1,
  External = 2,
  Carrier = 4,
  Retailer = 8,
  Vendor = 16,
  ExternalRetailer = External | Retailer,
  ExternalCarrier = External | Carrier,
  ExternalVendor = External | Vendor
}

export interface Organization extends JsonObject {
  name: string;
}

export interface OrganizationChangeRequest {
  userId: number,
  organizationCode: string;
}

export interface OrganizationSearchResult {
  code: string,
  type: number;
}

export interface CreateUserRequest {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  organizationCode: string;
  userType: UserType;
  roles: string[];
}

export interface CreateUserResponse {
  id: number,
  status: 'success' | 'conflict' | 'failure' | 'not_found';
}