import { Component, Input } from '@angular/core';

@Component({
  selector: 'form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent {
  @Input() input!: any;

  private readonly handledErrors: string[] = [
    'required',
    'min',
    'minlength',
    'max',
    'maxlength',
    'isalphanumeric',
    'email',
    'pattern',
    'name',
    'businessName',
    'address',
    'addressLineTwo',
    'phone',
    'state',
    'zip',
    'mustMatch',
    'checkboxRequired',
    'url'];

  constructor() { }

  humanReadableRegex(regex: string): string {
    const searchables = [
      { match: '0-9', description: 'numbers' },
      { match: ['a-z', 'A-Z'], description: 'letters' },
      { match: 'a-z', description: 'lowercase letters' },
      { match: 'A-Z', description: 'uppercase letters' },
      { match: '-', description: 'hyphens' },
      { match: '\'', description: 'apostrophes' },
      { match: ',', description: 'commas' },
      { match: '.', description: 'periods' },
      { match: ' ', description: 'spaces' },
      { match: ['(', ')'], description: 'parentheses' },
      { match: ['[', ']'], description: 'brackets' },
    ];
    regex = regex.slice(2, -3); // Remove the outside containers eg: [...]* or (...)+
    let message = '';
    for (let i = 0; i < searchables.length; i++) {
      const item = searchables[i];
      if (Array.isArray(item.match)) {
        if (regex.includes(item.match[0]) && regex.includes(item.match[1])) {
          regex = regex.replace(item.match[0], '');
          regex = regex.replace(item.match[1], '');
          message += `${item.description}, `;
        }
      } else if (regex.includes(item.match)) {
        regex = regex.replace(item.match, '');
        message += `${item.description}, `;
      }
    }
    message = message.slice(0, -2); // Remove the final comma and space
    if (regex.length) {
      message += ' as well as these characters: ' + regex;
    }
    return message;
  }

  protected show() {
    return this.input
      && this.input.errors
      && (this.input.touched || this.input.dirty)
      && this.input.invalid
      && this.handledErrors.some(x => this.input.errors[x]);
  }

  // Returns true if there is a specific error
  protected hasSpecificError() {
    return (
      this.input.errors?.['email'] ||
      this.input.errors?.['name'] ||
      this.input.errors?.['businessName'] ||
      this.input.errors?.['address'] ||
      this.input.errors?.['addressLineTwo'] ||
      this.input.errors?.['phone'] ||
      this.input.errors?.['state'] ||
      this.input.errors?.['zip'] ||
      this.input.errors?.['checkboxRequired'] ||
      this.input.errors?.['url']
    );
  }
}
