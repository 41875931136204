<div id="markdown-message-modal">
  <div *ngIf="markDownHeaderKey" class="modal-header">{{markDownHeaderKey | getresource}}</div>
  <div class="modal-body">
    <markdown [data]="markDownBodyKey | getresource"></markdown>
  </div>
  <div class="modal-footer d-flex align-items-end">
    <button id="markdown-message-modal-cancel" class="btn btn-default" (click)="cancel()"
            *ngIf="cancelVisible">{{cancelTitle | getresource}}</button>
    <button id="markdown-message-modal-close" class="btn btn-action"
            (click)="confirm()">{{confirmTitle | getresource}}</button>
  </div>
</div>