import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts camelCase, PascalCase, snake_case, dot.case, and kabob-case into user-presentable form
 * eg: bankAccountNumber --> Bank Account Number
 * @param string String to convert to readable form
 * @param titleCase (false) Capitalize every word - Set to false to only capitalize the first word
 */
@Pipe({
  name: 'humanReadable'
})
export class HumanReadablePipe implements PipeTransform {
  transform(value: string, titleCase = false, separateNumbers = true): string {
    value = value?.trim();
    if (!value?.length) return value;

    // Handle snake_case, kabob-case, and dot.case
    let newString = value.replace(/([-_.])/g, ' ');

    // Handle PascalCase and camelCase, keeping acronyms intact
    newString = newString.replace(/([A-Z])([a-z0-9])/g, ' $1$2');

    // Handle numbers after words
    if (separateNumbers) {
      newString = newString.replace(/([a-z])([0-9])/ig, '$1 $2');
    }

    // Remove extraneous spaces
    newString = newString.replace('  ', ' ').trim();

    if (titleCase) {
      const titleCasedArray: string[] = [];
      for (let word of newString.split(' ')) {
        titleCasedArray.push(`${word[0].toUpperCase()}${word.slice(1)}`);
      }
      newString = titleCasedArray.join(' ');
    } else {
      newString = `${newString[0].toUpperCase()}${newString.slice(1)}`;
    }

    return newString;
  }
}
