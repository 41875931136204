import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'request-login-modal',
  templateUrl: './request-login-modal.component.html'
})
export class RequestLoginModalComponent {
  constructor(
    private readonly modal: NgbActiveModal,
    private readonly router: Router

  ) { }

  requestLogin() {
    this.modal.close();
    this.router.navigateByUrl('/request/access');
  }

  cancel() {
    this.modal.dismiss();
  }
}
