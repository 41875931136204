import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  get nativeWindow(): WindowExtensions & typeof globalThis {
    return window;
  }

  isLocalhost() {
    return this.nativeWindow.location.hostname == 'localhost';
  }
}

export interface WindowExtensions extends Window {
  google?: any;
  EprotectIframeClient?: any;
}