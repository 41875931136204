import { Directive, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

// this directive sets a default value on an ngModel when the value is null or empty after a user leaves a control
@Directive({
  selector: '[defaultValue]'
})
export class DefaultValueDirective {

  @Input() defaultValue: any;

  constructor(private readonly model: NgModel) {
  }

  @HostListener('blur') onBlur() {
    const val = this.model.value;
    if (Object.isUndefined(val) || ((typeof val == 'string') && String.isUndefinedOrEmpty(val))) {
      this.model.update.emit(this.defaultValue);
    }
  }
}
