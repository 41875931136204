import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';

@Directive({
  selector: '[appMaxLength]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxLengthValidator),
    multi: true
  }]
})
export class MaxLengthValidator implements Validator {
  @Input() appMaxLength?: number;

  validate(control: UntypedFormControl): { [key: string]: any; } {
    if (Object.isUndefined(this.appMaxLength)) {
      return null;
    }

    if ((control.value && control.value.length > this.appMaxLength)) {
      return { appMaxLength: { valid: false } };
    }

    return null;
  }

}
