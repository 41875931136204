export class EnumHelper {
  static compareValue(val: string | number, enumType: object, enumVal: number) {
    const normalizedVal = EnumHelper.normalizeValue(val, enumType);
    const normalizedEnumVal = EnumHelper.normalizeValue(enumVal, enumType);

    if (normalizedVal === normalizedEnumVal) { return 0; }

    return normalizedVal < enumVal ? -1 : 1;
  }

  static equals(val: string | number, enumType: object, enumVal: number) {
    return EnumHelper.compareValue(val, enumType, enumVal) === 0;
  }

  static doesNotEqual(val: string | number, enumType: object, enumVal: number) {
    return EnumHelper.compareValue(val, enumType, enumVal) !== 0;
  }

  static lessThan(val: string | number, enumType: object, enumVal: number) {
    return EnumHelper.compareValue(val, enumType, enumVal) === -1;
  }

  static lessThanOrEqual(val: string | number, enumType: object, enumVal: number) {
    return EnumHelper.compareValue(val, enumType, enumVal) <= 0;
  }

  static greaterThan(val: string | number, enumType: object, enumVal: number) {
    return EnumHelper.compareValue(val, enumType, enumVal) === 1;
  }

  static greaterThanOrEqual(val: string | number, enumType: object, enumVal: number) {
    return EnumHelper.compareValue(val, enumType, enumVal) >= 0;
  }

  static normalizeValue(val: string | number, enumType: object) {
    return 'string' === typeof val ? enumType[val] : val;
  }

  static enumToList(enumeration: object) {
    return Object.values(enumeration)
      .filter((value) => typeof value === 'string')
      .map((value: string) => (value));
  }
}
