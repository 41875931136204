<div class="choice-wrapper d-flex flex-column align-items-stretch"
     [ngClass]="{ 'has-error': hasError, 'has-success': hasSuccess }">
  <div class="text-center">
    <span *ngIf="!useMarkdownLabel else markdownLabel" class="form-label big-label"
          [ngClass]="{ 'required': isRequired }">
      {{label | getresource}}
    </span>
    <span *ngIf="isLicensedAndBonded" class="form-label big-label mt-0">{{subLabel | getresource}}</span>
    <ng-template #markdownLabel>
      <markdown ngPreserveWhitespaces [data]="label | getresource" class="markdown-chop-margin big-label"
                [ngClass]="{ 'required': isRequired }"></markdown>
    </ng-template>
    <tooltip *ngIf="hasTooltip" [title]="tooltipTitle" [tip]="tooltipMessage" class="inline-tooltip"></tooltip>
  </div>
  <div class="text-center help-block" *ngIf="hasRequiredError">
    {{'validation.errors.required' | getgroupresource : 'default'}}
  </div>
  <div class="text-center help-block" *ngIf="hasValueError">
    {{'validation.errors.value-list' | getgroupresource : 'default'}}
  </div>
  <div #choiceRow class="row justify-content-center" [ngClass]="{'mini': mini}">
    <div *ngFor="let choice of choices; index as i" class="item p-0" [ngClass]="getSelectedCss(choice)">
      <input type="radio" [attr.name]="name + '-choice'" id="{{ name + '-choice-radio-' + i}}" (blur)="handleBlur()"
             class="backing-radio-input" [value]="choice" [(ngModel)]="selected" (ngModelChange)="select(choice, i)">
      <a class="d-flex flex-column text-center justify-content-center" (click)="select(choice, i)"
         id="{{ name + '-choice-' + i }}">
        <span class="fa {{ choice.iconClass }}" *ngIf="choice.iconClass"></span>
        <img class="d-block align-self-center" [src]="choice.imgSrc" *ngIf="choice.imgSrc">
        <span>{{ choice.label | getresource | breakOnSlash }}</span>
      </a>
    </div>
  </div>
</div>