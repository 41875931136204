import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  private static readonly Separator = '-';

  transform(value: string): string {

    if (String.isUndefinedOrEmpty(value))
      return '';

    value = (typeof value === 'string') ? value : String(value);
    value = value.replace(/[^0-9.]/g, '');

    switch (value.length) {
      case 10:
        return value.substr(0, 3) + PhoneNumberPipe.Separator +
          value.substr(3, 3) + PhoneNumberPipe.Separator +
          value.substr(6);
      case 11:
        return value[0] + PhoneNumberPipe.Separator +
          value.substr(1, 3) + PhoneNumberPipe.Separator +
          value.substr(4, 3) + PhoneNumberPipe.Separator +
          value.substr(7);
      default:
        return value;
    }
  }
}
