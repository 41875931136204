<div class="modal-header">Notice</div>
<div class="modal-body">
  <p>
    This portal is intended for potential and current retail clients of RPS. If you are an insured working with RPS,
    please visit one of the following:
  </p>
  <dl class="row">
    <dt class="col-sm-3">Public Website</dt>
    <dd class="col-sm-9"><a href="https://rpsins.com">https://rpsins.com</a></dd>
    <dt class="col-sm-3">Claims</dt>
    <dd class="col-sm-9"><a href="https://rpsins.com/resources/rps-claims">https://rpsins.com/resources/rps-claims</a>
    </dd>
    <dt class="col-sm-3">Payments</dt>
    <dd class="col-sm-9"><a href="https://rpsins.com/resources/pay-online">https://rpsins.com/resources/pay-online</a>
    </dd>
  </dl>
  <p>To proceed with requesting a login, please click below.</p>
</div>
<div class="modal-footer revert-btn d-flex align-items-end">
  <button type="button" class="btn btn-default me-3" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-action" (click)="requestLogin()">Request Retailer Login</button>
</div>