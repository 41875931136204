import { Injectable } from '@angular/core';
import { Debug } from '../debug';
import { WindowService } from '../window.service';

@Injectable()
export class SessionService {
  private globalPrefix = 'PC';

  public constructor(private readonly window: WindowService) { }

  // Returns the number of items in session storage
  public get length() {
    return sessionStorage.length;
  }

  // Non-global prefixes are url-specific to prevent naming collisions between similar pages
  // eg: /retailer/statements => retailer.statements
  public get prefix() {
    return this.window.nativeWindow.location.pathname.substring(1).toLowerCase().replace(/\//g, '.');
  }

  // All prefixes default to page-specific values to prevent collisions
  public createSessionKey(key: string, global = false, customPrefix = '') {
    let prefix: string;
    if (customPrefix) {
      prefix = customPrefix;
    } else if (global) {
      prefix = this.globalPrefix;
    } else {
      prefix = this.prefix;
    }
    return `[${prefix}]${key}`;
  }

  public setItem(key: string, value: any, global = false, customPrefix = '') {
    const sessionKey = this.createSessionKey(key, global, customPrefix);
    sessionStorage.setItem(sessionKey, JSON.stringify({ value }));
  }

  public getItem<T>(key: string, defaultValue?: T, global = false, customPrefix = '') {
    const sessionKey = this.createSessionKey(key, global, customPrefix);
    const data = sessionStorage.getItem(sessionKey);

    if (data !== null) {
      let value: T;
      try {
        value = JSON.parse(data)?.value as T;
      } catch {
        // Values should be validated before saved to session.
        // But this could still happen if session values are altered in browswer.
        value = (Object.isDefined(defaultValue) ? defaultValue : null) as T;
        sessionStorage.setItem(sessionKey, JSON.stringify({ value }));
        Debug.debug(`Loading session object failed: ${key}`, data);
      }
      // ! For local debugging only, keep commented in git (clogs up the console)
      // Debug.debug(`Loading session object: ${key}`, returnObject);
      return value;
    }

    if (Object.isDefined(defaultValue)) {
      return defaultValue as T;
    }

    return null as T;
  }

  public removeItem(key: string, global = false, customPrefix = '') {
    sessionStorage.removeItem(this.createSessionKey(key, global, customPrefix));
  }

  // ! Caution: Clears ALL session storage, for every page!
  public clear() {
    sessionStorage.clear();
  }

  public key(index: number) {
    return sessionStorage.key(index);
  }
}
