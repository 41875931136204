import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'einSsn'
})
export class EinSsnPipe implements PipeTransform {
  private static readonly Separator = '-';

  transform(value: string, type?: string): string {
    if (String.isUndefinedOrEmpty(value)) return '';

    value = typeof value === 'string' ? value : String(value);
    value = value.replace(/[^0-9.]/g, '');

    if (type == 'ein') {
      // if ein then xx-xxxxxxx format
      return value.substr(0, 2) + EinSsnPipe.Separator + value.substr(2, 7);
    }
    // if ssn then xxx-xx-xxxx format
    return value.substr(0, 3) + EinSsnPipe.Separator + value.substr(3, 2) + EinSsnPipe.Separator + value.substr(5, 4);
  }
}
