import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[integer]',
})
export class IntegerDirective {
  // second row is for IE11's "interpretation" of key codes
  private static readonly allowedSpecialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp', 'Delete',
    'Left', 'Right', 'Down', 'Up', 'Del'
  ];

  private readonly allowedShortcutKeys = ['c', 'a', 'x', 'v'];
  private readonly numberPattern = /[0-9]+/;

  @HostListener('keydown', ['$event']) onKeyDown(evt: KeyboardEvent) {
    if (!(IntegerDirective.allowedSpecialKeys.some(x => String.equalsIgnoreCase(x, evt.key))
      || this.numberPattern.test(evt.key)
      || this.isShortcut(evt.ctrlKey, evt.key))) {
      evt.preventDefault();
    }
  }

  private isShortcut(isCtrlDown: boolean, key: string) {
    return isCtrlDown && this.allowedShortcutKeys.some(x => String.equalsIgnoreCase(x, key));
  }
}
