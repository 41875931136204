import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Resource, ResourceConverter } from '../text-resources';
import { MessageModalButtonMode, MessageModalButtonOptions } from './message-modal-button-options';

@Component({
  selector: 'notification-message-modal',
  templateUrl: './message-modal.component.html'
})
export class NotificationMessageModalComponent implements OnChanges {

  // these three are technically @Input because they come from the notification service
  title: string | Resource;
  message: string | Resource;
  buttonOptions: MessageModalButtonOptions;

  cancelTitle: string | Resource;
  cancelVisible: boolean;
  confirmTitle: string | Resource;

  constructor(private readonly instance: NgbActiveModal) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const optsChange = changes['buttonOptions'];
    if (Object.isUndefined(optsChange)) {
      return;
    }

    const opts: MessageModalButtonOptions = optsChange.currentValue;

    if (Object.isUndefined(opts) || opts.mode === MessageModalButtonMode.Close) {
      this.cancelVisible = false;
      this.confirmTitle = Object.isDefined(opts) && ResourceConverter.hasTextOrKey(opts.confirmTitle)
        ? opts.confirmTitle
        : 'modal.button.close';
    } else { // if (opts.mode == MessageModalButtonMode.ConfirmCancel)
      this.cancelVisible = true;
      this.cancelTitle = ResourceConverter.hasTextOrKey(opts.cancelTitle) ? opts.cancelTitle : 'modal.button.cancel';
      this.confirmTitle = ResourceConverter.hasTextOrKey(opts.confirmTitle) ? opts.confirmTitle : 'modal.button.confirm';
    }
  }

  confirm() {
    this.instance.close({ confirm: true });
  }

  cancel() {
    this.instance.dismiss({ dismissed: true });
  }
}
