import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { take } from 'rxjs';
import { environment } from '../environments/environment';
import { Debug, NotificationService } from './core';
import { TimeService } from './time.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  readonly showDevMenu: boolean;

  constructor(
    private readonly gtmService: GoogleTagManagerService,
    private readonly notifySvc: NotificationService,
    private readonly timeService: TimeService
  ) {
    this.showDevMenu = environment.showDevBar && !environment.production;
  }

  async ngOnInit() {
    await this.gtmService.addGtmToDom();

    this.timeService.getCurrentTime().pipe(take(1)).subscribe({
      next: (data) => {
        if (data?.datetime) {
          const local = new Date();
          const timeServer = new Date(data.datetime).toLocaleString();
          const timeDiff = Math.floor((local.getTime() - new Date(timeServer).getTime()) / 1000 / 60);
          if (Math.abs(timeDiff) > 10) {
            this.notifySvc.showToast(`Your computer's system time is incorrect and could cause issues using our website, including premature session expiration. Please adjust your system clock to match the current time, which is ${timeServer}, and try again. If you need assistance, please contact Customer Care.`);
          }
        }
      },
      error: (err) => {
        Debug.error('Unable to get current time from public time server:', err);
      }
    });
  }
}