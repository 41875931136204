import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

// https://stackoverflow.com/questions/32051273/angular-and-debounce
@Directive({
  selector: '[debounce]'
})
export class DebounceDirective implements OnInit, OnDestroy {
  @Output() public onDebounce = new EventEmitter<any>();

  @Input('debounce') debounceTime = 500;

  private isFirstChange = true;
  private ngUnsubscribe = new Subject<void>();

  constructor(public model: NgControl) { }

  ngOnInit() {
    this.model.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(this.debounceTime),
        distinctUntilChanged((prev, current) => {
          return isEqual(prev, current);
        })
      )
      .subscribe(modelValue => {
        if (this.isFirstChange) {
          this.isFirstChange = false;
          if (this.model.dirty) {
            // When value is changed before initially set.
            this.onDebounce.emit(modelValue);
          }
        } else {
          this.onDebounce.emit(modelValue);
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
