import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription, filter, firstValueFrom, takeUntil, tap } from 'rxjs';
import { Debug } from './debug';
import { AuthHttpInterceptor } from './profile';

@Injectable({
  providedIn: 'root'
})
export class AutoCancelHttpService implements OnDestroy {
  private triggerCancellation = new Subject<void>();
  private sub: Subscription;
  private cancelableList = new Map<string, Subject<void>>();

  // Will auto-trigger upon page change for components which don't use the provided get/post methods
  navigationDetected$ = this.triggerCancellation.asObservable();

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart)
    ).subscribe(() => {
      this.triggerCancellation.next();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.cancelableList.forEach((v, k) => {
      this.triggerCancel(k);
    });
  }

  /**
 * Similar to HttpService get method, except it will auto-cancel upon page change
 * And cancel duplicate calls to the same endpoint if call is marked as force distinct
 * @template T
 * @param url string
 * @param [enableAuth] boolean (true)
 * @param [forceDistinct] boolean (false)
 * @returns
 */
  get<T>(url: string, enableAuth = true, forceDistinct = false): Promise<T> {
    if (forceDistinct) {
      return this.cancelableGet(url, enableAuth);
    }
    const get = this.http.get<T>(
      url,
      (enableAuth) ? AuthHttpInterceptor.AddAuthHttpHeader : {}
    ).pipe(takeUntil(this.navigationDetected$));
    return firstValueFrom(get, { defaultValue: undefined });
  }

  /**
   * Similar to HttpService post method, except it will auto-cancel upon page change
   * @template T
   * @param url string
   * @param body any
   * @param [enableAuth] boolean (true)
   * @returns
   */
  post<T>(url: string, body: any, enableAuth = true): Promise<T> {
    const post = this.http.post<T>(
      url,
      body,
      (enableAuth) ? AuthHttpInterceptor.AddAuthHttpHeader : {}
    ).pipe(takeUntil(this.navigationDetected$));
    return firstValueFrom(post, { defaultValue: undefined });
  }

  private cancelableGet<T>(url: string, enableAuth: boolean): Promise<T> {
    const position = url.indexOf('?');
    const endPoint = position === -1 ? url : url.substring(0, position);
    const processCancel$ = new Subject<void>();

    if (this.cancelableList.has(endPoint)) {
      Debug.log(`Cancelling the previous call to the same endpoint (${endPoint}) call`);
      this.triggerCancel(endPoint);
    }
    this.cancelableList.set(endPoint, processCancel$);
    const get = this.http.get<T>(
      url,
      (enableAuth) ? AuthHttpInterceptor.AddAuthHttpHeader : {}
    ).pipe(
      takeUntil(this.navigationDetected$),
      takeUntil(processCancel$),
      tap(() => {
        const found = this.cancelableList.get(endPoint);
        if (found) { // Check if it is not deleted yet
          found.complete();
          this.cancelableList.delete(endPoint);
        }
      }));
    return firstValueFrom(get, { defaultValue: undefined });
  }

  private triggerCancel(endPoint: string) {
    const found = this.cancelableList.get(endPoint);
    if (found) { // Check if it is not deleted yet
      found.next();
      found.complete();
      this.cancelableList.delete(endPoint);
    }
  }
}
