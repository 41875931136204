import { Component, Input } from '@angular/core';

@Component({
  selector: 'panel-arrow',
  templateUrl: './panel-arrow.component.html'
})
export class PanelArrowComponent {
  @Input() activeIds: readonly string[] | string;
  @Input() targetId: string;
  @Input() id: string;

  constructor() { }

  get arrowClass() {
    const ids = [].concat(this.activeIds);
    return ids.indexOf(this.targetId) > -1
      ? 'fa-chevron-up'
      : 'fa-chevron-down';
  }

  get arrowId() {
    return this.id + '-arrow';
  }
}
