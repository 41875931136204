import { Directive, EventEmitter, forwardRef, HostListener, Output } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';
import { PhoneNumberPipe } from '../pipes';

@Directive({
  selector: '[ngModel][phoneNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberValidator),
      multi: true
    }
  ]
})
export class PhoneNumberValidator implements Validator {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  private static readonly regEx = /^(([1])[- ]?)?\(?(\d{3})[-)]?[- ]?(\d{3})[- ]?(\d{4})$/;
  private static readonly allowedSpecialKeys = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowLeft', 'ArrowRight'];
  private static readonly allowedShortcutKeys = ['a', 'z', 'x', 'c', 'v'];
  private static readonly allowedPhoneNumberKeys = /[0-9\-()]+/;
  private static readonly transformed = /[-()]/g;

  constructor(private readonly phoneNumberPipe: PhoneNumberPipe) { }

  validate(control: UntypedFormControl): { [key: string]: any; } {
    if (String.isUndefinedOrEmpty(control.value)) {
      return null;
    }

    const remaining = control.value.replace(PhoneNumberValidator.transformed, '');
    return PhoneNumberValidator.regEx.test(remaining) ? null : { phoneNumber: true };
  }

  @HostListener('blur', ['$event']) onBlur(evt: FocusEvent) {
    const value = (evt.target as HTMLInputElement).value.replace(PhoneNumberValidator.transformed, '');
    this.ngModelChange.emit(this.phoneNumberPipe.transform(value));
  }

  @HostListener('keydown', ['$event']) onKeyDown(evt: KeyboardEvent) {
    if (
      !(
        PhoneNumberValidator.allowedSpecialKeys.some((x) => String.equalsIgnoreCase(x, evt.key)) ||
        PhoneNumberValidator.allowedPhoneNumberKeys.test(evt.key) ||
        this.isShortcut(evt.ctrlKey, evt.key)
      )
    ) {
      evt.preventDefault();
    }
  }

  private isShortcut(isCtrlDown: boolean, key: string) {
    return isCtrlDown && PhoneNumberValidator.allowedShortcutKeys.some((x) => String.equalsIgnoreCase(x, key));
  }
}
