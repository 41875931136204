import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[ngModel][validateStreet]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => StreetNumberValidator),
    multi: true
  }]
})
export class StreetNumberValidator implements Validator {

  @Input() validateStreet?: boolean;

  validate(control: AbstractControl) {
    if (Object.isUndefined(control.value) || !this.validateStreet) {
      return null;
    }

    const streetNum = parseInt(control.value);
    // does it contain a number and not only a number
    const valid = !isNaN(streetNum) && (('' + streetNum) !== control.value);
    return valid ? null : { street: true };
  }
}
