export class Region {
  public code: string;
  public name: string;
  public districts: string[];

  constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }
}

export class RegionData {
  [key: string]: Region;
}
