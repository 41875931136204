import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakOnSlash'
})
export class BreakOnSlashPipe implements PipeTransform {
  transform(value: any): any {
    return value.toString().replace(/\//g, '/\u200b');
  }
}
