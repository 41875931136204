import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ErrorMessageConfig } from '@app/core/forms/input-validation/default-error-messages';
import { GeoService, RegionData } from '../../../geo';
import { AbstractValueAccessor, makeProvider } from '../../abstract-value-accessor';
import { Address } from '../../address';
import { AddressComponentOptions, AddressManualLabelOptions } from '../options';

@Component({
  selector: 'address-manual',
  templateUrl: './manual.component.html',
  providers: [makeProvider(() => ManualComponent)],
  viewProviders: [{ provide: ControlContainer, deps: [NgForm], useExisting: NgForm }]
})
export class ManualComponent extends AbstractValueAccessor implements OnInit, OnChanges {

  private static readonly DefaultLabels: AddressManualLabelOptions = {
    addressLineOne: 'address.address-line-one',
    addressLineTwo: 'address.address-line-two',
    unitNumber: 'address.unit-number',
    postalCode: 'address.postal-code',
    locality: 'address.locality',
    region: 'address.region',
    district: 'address.district'
  };

  private static readonly DefaultOptions: AddressComponentOptions = {
    includeUnitNumber: false,
    requireUnitNumber: false,
    includeAddressLineTwo: false,
    includeDistrict: false,
    requireDistrict: false,
    requireValidStreet: false
  };

  readonly postalCodeErrorConfig: ErrorMessageConfig = {
    pattern: { key: 'address.error.postal-code', group: 'default' }
  };

  @Input() name: string;
  @Input() labelOptions?: AddressManualLabelOptions;
  @Input() isRequired?: boolean;
  @Input() isDisabled?: boolean;
  @Input() options?: AddressComponentOptions;

  address: Address;
  labels: AddressManualLabelOptions;
  regionList: { code: string, name: string; }[];
  currentDistrictList: string[];

  private regionData: RegionData;
  private oldRegion: string;
  private initFinished: boolean;

  constructor(private readonly geoService: GeoService) {
    super();
    this.isRequired = false;
    this.isDisabled = false;
    this.currentDistrictList = [];
    this.regionList = [];
    this.labels = ManualComponent.DefaultLabels;
    this.address = {} as Address;
    this.options = ManualComponent.DefaultOptions;
  }

  protected onValueSet(value: any) {
    this.address = Object.isDefined(value) ? value : {};
    if (this.initFinished) {
      this.updateDistrictList(true);
    }
  }

  ngOnInit() {
    this.geoService.getRegionList().then(data => {
      this.regionData = data;
      this.regionList = Object.keys(data).map(k => {
        const r = data[k];
        return { code: r.code, name: r.name };
      });
      this.updateDistrictList();
      this.initFinished = true;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const labelChange = changes.labelOptions;
    if (Object.isDefined(labelChange)) {
      this.labels = this.merge<AddressManualLabelOptions>(ManualComponent.DefaultLabels, labelChange.currentValue);
    }

    const optsChange = changes.optsChange;
    if (Object.isDefined(optsChange)) {
      this.options = this.merge<AddressComponentOptions>(ManualComponent.DefaultOptions, changes.optsChange.currentValue);
    }
  }

  private merge<T>(defaults: T, changes: T): T {
    const result: JsonObject = {};
    const compare: JsonObject = Object.isDefined(changes) ? changes : {};
    for (const k in defaults) {
      result[k] = compare[k] || defaults[k];
    }
    return result as T;
  }

  get requireUnitNumber() {
    return this.isRequired && (this.options.requireUnitNumber);
  }

  get requireDistrict() {
    return this.isRequired && (this.options.requireDistrict);
  }

  getDistrictLabel() {
    let labelResource = this.labels.district;

    if (Object.isDefined(this.value) && String.isNotEmpty(this.value.region)) {
      if (this.value.region === 'AK') {
        labelResource = 'address.district.borough';
      } else if (this.value.region === 'LA') {
        labelResource = 'address.district.parish';
      }
    }

    return labelResource;
  }

  // propagates changes to individual fields
  handleInputChange() {
    this.fireOnTouched();
    // occurs if the value was originally undefined/null
    if (this.address !== this.value) {
      this.value = this.address;
    } else {
      this.fireOnChange();
    }
  }

  handleRegionChange() {
    this.updateDistrictList();
    this.handleInputChange();
  }

  /**
   * @param keepCurrentDistrict This parameter should be used when the model changes since
   * the old region value will not match and we'll want to keep the model's district.
   */
  private updateDistrictList(keepCurrentDistrict = false) {

    let region = '';
    if (Object.isDefined(this.value) && String.isNotEmpty(this.value.region)) {
      region = this.value.region.toLowerCase();
      const regionInst = this.regionData[region];
      this.currentDistrictList = Object.isDefined(regionInst) ? regionInst.districts : [];
    } else {
      this.currentDistrictList = [];
    }

    /* it's possible that
        1) we're loading for the first time and don't want to clear the district (hence oldRegion check)
        2) the region was changed due to user input
        3) the model has changed in which case oldRegion should once again be nulled out and treated as a first load
     **/
    if (!keepCurrentDistrict && String.isNotEmpty(this.oldRegion) && (region !== this.oldRegion)) {
      this.value.district = null;
    }

    this.oldRegion = region;
  }
}
