import { Injectable, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Debug } from '../debug';
import { ModalSpinnerService } from '../forms/modal-spinner/modal-spinner.service';
import { Resource, ResourceService } from '../text-resources';
import { NotificationErrorModalComponent } from './error-modal.component';
import { NotificationMarkdownModalComponent } from './markdown-modal.component';
import { MessageModalButtonOptions } from './message-modal-button-options';
import { NotificationMessageModalComponent } from './message-modal.component';

export enum ToastType {
  Info = 0,
  Success,
  Warning,
  Error
}

@Injectable()
export class NotificationService {
  constructor(private readonly toasterService: ToastrService,
    private readonly modalService: NgbModal,
    private readonly modalSpinner: ModalSpinnerService,
    private readonly resourceService: ResourceService) {
  }

  showToast(message: string, title?: string, type?: ToastType, timeOut?: number) {
    const config: Partial<IndividualConfig> = Object.isDefined(timeOut) ? { timeOut } : undefined;
    switch (type) {
      case ToastType.Error:
        return this.toasterService.error(message, title, config);
      case ToastType.Warning:
        return this.toasterService.warning(message, title, config);
      case ToastType.Success:
        return this.toasterService.success(message, title, config);
      case ToastType.Info:
        return this.toasterService.info(message, title, config);
      default:
        return this.toasterService.show(message, title, config);
    }
  }

  /**
   * Shows a toast that uses text resources for the message and title.
   * @param message an ungrouped resource key or a text resource object
   * @param title an ungrouped resource key or a text resource object
   * @param type the type of toast to show
   */
  async showResourceToast(message: string | TextResource, title?: string | TextResource, type?: ToastType, timeOut?: number) {
    const [msgText, titleText] = await Promise.all([this.loadResource(message), this.loadResource(title)]);
    this.showToast(msgText, titleText, type, timeOut);
  }

  private async loadResource(resource: string | TextResource): Promise<string> {
    if (Object.isUndefined(resource)) {
      return undefined;
    } else if (typeof resource === 'string') {
      // ungrouped resources are loaded when the resource service is created - no need to load beforehand
      return this.resourceService.getResource(resource);
    } else if (String.isNotEmpty(resource.group)) {
      // groups may not be preloaded so we need to ensure that they exist before accessing them
      await this.resourceService.load(resource.group);
    }

    return Object.isUndefined(resource.formatVariables)
      ? this.resourceService.getResource(resource.key, resource.group)
      : this.resourceService.getFormattedResource(resource.key, resource.group, resource.formatVariables);
  }

  showModalMessage(title: string | Resource, message: string | Resource, buttonOptions?: MessageModalButtonOptions): Promise<any> {
    return this.showModalTemplate<any>(NotificationMessageModalComponent, { title, message, buttonOptions });
  }

  showMarkdownModalMessage(markDownHeaderKey: string | Resource, markDownBodyKey: string | Resource, buttonOptions?: MessageModalButtonOptions): Promise<any> {
    return this.showModalTemplate<any>(NotificationMarkdownModalComponent, { markDownHeaderKey, markDownBodyKey, buttonOptions });
  }

  showModalError(title: string | Resource, message: string | Resource, details: string[]): Promise<any> {
    return this.showModalTemplate<any>(NotificationErrorModalComponent, { title, message, details });
  }

  showModalTemplate<T>(componentType: any, locals?: JsonObject, modalOptions: NgbModalOptions = {}): Promise<T> {
    this.modalSpinner.stop(true);

    const defaultOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    const options = { ...defaultOptions, ...modalOptions };

    const modal = this.modalService.open(componentType, options);

    if (Object.isDefined(locals)) {
      this.assignLocals(locals, modal.componentInstance);
    }

    return modal.result;
  }

  private assignLocals(locals: JsonObject, instance: JsonObject) {
    let changes: SimpleChanges = {};

    for (const key in locals) {
      const val = locals[key];
      instance[key] = val;
      changes[key] = {
        currentValue: val,
        previousValue: undefined,
        firstChange: false,
        isFirstChange: () => false
      };
    }

    if (Object.isDefined(instance.ngOnChanges)) {
      Debug.debug('Sending locals to modal:', changes);
      instance.ngOnChanges(changes);
    }
  }
}

export interface TextResource extends Resource {
  formatVariables?: string | number | Array<string | number>;
}
