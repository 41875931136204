<div [ngSwitch]="displayType">

  <div *ngSwitchCase="allDisplayTypes.AutoComplete" class="form-section">
    <div class="faux-material-form-group" validateInput>
      <address-autocomplete [name]="name" [label]="labels.autocomplete" [hideLabel]="labels.hideAutocompleteLabel"
                            [ngModel]="address" (ngModelChange)="handleAddressChange($event)" [isRequired]="isRequired"
                            [isDisabled]="isDisabled" [shouldValidateStreet]="options.requireValidStreet"
                            [completeAddress]="completeAddressOptions" #addrAuto="ngModel"></address-autocomplete>
      <a id="{{name}}-manual-toggle" *ngIf="displayTypeCanBeChangedByUser" (click)="setManualDisplay()">
        Click here to manually enter address instead
      </a>
    </div>
    <div class="faux-material-form-group" *ngIf="options.includeUnitNumber" validateInput>
      <label for="{{name}}-unitNumber" class="form-label" [ngClass]="{'required':requireUnitNumber}">
        {{labels.unitNumber | getresource}}
      </label>
      <input type="text" name="{{name}}-unitNumber" id="{{name}}-unitNumber" class="form-control"
             [(ngModel)]="address.unitNumber" (ngModelChange)="handleUnitNumberChange()" [required]="requireUnitNumber"
             [disabled]="isDisabled" #unitNumber="ngModel" />
    </div>
  </div>

  <div *ngSwitchCase="allDisplayTypes.Manual" class="form-section">
    <address-manual [name]="name" [ngModel]="address" (ngModelChange)="handleAddressChange($event)"
                    [isRequired]="isRequired" [isDisabled]="isDisabled" [labelOptions]="labels" [options]="options"
                    [completeAddress]="completeAddressOptions" #addrManual="ngModel"></address-manual>
    <a id="{{name}}-autocomplete-toggle" *ngIf="displayTypeCanBeChangedByUser" (click)="setAutocompleteDisplay()">
      Back to address lookup
    </a>
  </div>

  <div *ngSwitchDefault class="form-section">
    <address-display [name]="name" [ngModel]="address"></address-display>
  </div>

</div>