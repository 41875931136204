import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env';
import { DirectoryService } from '../directory/directory.service';
import { WindowService } from '../window.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private readonly profileService: ProfileService,
    private readonly directoryService: DirectoryService,
    private readonly windowService: WindowService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuth(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuth(childRoute, state);
  }

  private async checkAuth(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.bypassAuth) {
      return true;
    }
    this.profileService.isProtectedRoute = true;
    const profile = await this.profileService.getProfile();
    if (!profile.isAuthenticated) {
      this.profileService.expiredSessionRedirect();
      return false;
    }

    const routePermissions: string[] = route.data.permissions || [];

    if (routePermissions.length && !routePermissions.every(p => profile.permissions.includes(p))) {
      const headerData = this.directoryService.getDefinition('header', '^1.0.4');
      const producerLookupUrl = environment.rampProducerLookupUri;
      const redirectUrl: string = headerData?.homeUrl || producerLookupUrl;
      this.windowService.nativeWindow.location.assign(redirectUrl);
      return false;
    }

    if (
      // If they're still onboarding
      profile.permissions.includes('restricted-user')
      // And they're trying to access a non-onboarding url
      && !(state.url.startsWith('/onboarding') || state.url.startsWith('/portal-client/onboarding'))
    ) {
      // Just say no to hackers
      return false;
    }

    return profile.isAuthenticated;
  }
}
