import { Injectable } from '@angular/core';
import { ODataQueryBuilder } from './odata-query-builder';

/* [2/22/2018] Robert
 *
 * The intent with this class (and supporting classes) is to provide an easy to use ODATA query/filter
 * builder that can help cut down on repeated null/value checking if statements and provide a chainable
 * "builder" type interface. In other words:

    builder.top(5).filter.equals('foo', null).or().equals('bar', 'matt damon').query.build()

    will yield "$top=5&$filter=bar eq 'matt damon'" because a value for foo wasn't provided or this:

    builder.top(5).filter.equals('foo', null).query.build()

    will yield "$top=5" because a value for foo wasn't provided so a filter isn't needed. This removes the need
    for the typical "if user provided value then add predicate" if statements.
 */

@Injectable()
export class ODataQueryBuilderService {
  create(allowEmptyStrings = false) {
    return new ODataQueryBuilder(allowEmptyStrings);
  }
}
