<span class="input-group date-picker">
  <input type="text" class="form-control ps-2" ngbDatepicker [(ngModel)]="dtpValue"
         (ngModelChange)="onDtpValueChanged()" [name]="name" [id]="name" [placeholder]="placeholderLabel"
         [placement]="placement" [minDate]="dtpMinDate" [maxDate]="dtpMaxDate" #d="ngbDatepicker"
         [disabled]="isDisabled" [readonly]="isReadOnly" maxlength="10" (blur)="clearIfInvalid()" />
  <span class="input-group-btn">
    <button type="button" class="btn btn-default btn-effective border-0" id="{{name + '_btn'}}" (click)="d.toggle()"
            (blur)="fireOnTouched()" [disabled]="isDisabled">
      <i class="fa fa-calendar fa-lg"></i>
    </button>
  </span>
</span>