import { formatCurrency, formatDate, formatNumber } from '@angular/common';
import { Component, ElementRef, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AimNewOrRenewal, AimService, AimSubmission, AimSubmissionStage, ExportType, NameValuePair, PolicySubmissionStages, QuoteSubmissionStages } from '@app/core/aim';
import { environment } from '@env';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { isNumber } from 'lodash';
import { Subject } from 'rxjs';
import { ApiErrorOptions, ApiErrorService } from '../../api-error.service';
import { Debug } from '../../debug';
import { DownloadOptions } from '../../download/download.interfaces';
import { DownloadService } from '../../download/download.service';
import { NotificationService, ToastType } from '../../notification-service';
import { DefaultTransformBuilder, ODataQueryBuilder, ODataQueryBuilderService, ODataQueryFilterBuilder, ODataResponse } from '../../odata';
import { HumanReadablePipe, TruncatePipe } from '../../pipes';
import { SessionService } from '../../storage/session.service';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';
import { DateRangeOptions, DateRangeResult } from '../date-range-picker/date-range-picker.interfaces';
import { FilterListItem } from '../filter-list/filter-list.interfaces';
import { MultiCheckSearchSelectorComponent } from '../multi-check-search-selector/multi-check-search-selector.component';
import { MultiCheckSearchOptions, MultiCheckSearchResult } from '../multi-check-search-selector/multi-check-search-selector.interface';
import { DataRangeFilter, MultiSelectFilter } from './data-filter.interfaces';
import { SortBy, TableSortDirective } from './table-sort.directive';
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);

@Component({
  selector: 'submissions-list',
  template: ''
})
export class SubmissionsListBaseComponent {
  protected showPolicies = true; // If false, show quotes
  protected searchFilter: string; // Current search string
  protected loadingCount = 0; // When previous api call gets canceled, it will return no data. Keep showing 'loading' until new call is complete
  protected initialLoad = true; // Prevents an initial "No data found" when it's still loading data
  protected rowData: AimSubmission[] = [];

  protected retailerData: NameValuePair<string>[] = [];
  protected rowsTotal = 0; // Total results
  protected sort: SortBy = null; // Sort column/direction
  protected exportInProgress = false;
  protected customSessionPrefix: string = '';
  private _page = 1;
  private _pageSize = 25;
  private _maxSize = 12; // pager size
  private _screenWidth: number;
  private _logPrefix = '[SubmissionsListBase]';

  // Search/Filtering properties
  private readonly maxValue = 100000000000; // Maximum Value user can input in premium fields
  protected multiCheckChange = new Subject<void>(); // For setting debounce on multi-check box values change
  protected dateRangeFilters: DataRangeFilter<Date>[]; // Current Expiration date range
  protected multiSelectFilters: MultiSelectFilter<string>[];
  protected activeRenewalFilters: AimNewOrRenewal[] = [];
  protected activeStageFilters: AimSubmissionStage[] = [];
  protected sessionId: string;
  protected activeFilters: FilterListItem[] = []; // Populates the filter-list component
  protected boundFilter: DataRangeFilter<Date>; // Current Bound date range (only applies to policies)
  protected retailerFilter: MultiSelectFilter<string>;
  protected receivedFilter: DataRangeFilter<Date>; // Current received date range (only applies to quotes)
  protected effectiveFilter: DataRangeFilter<Date>; // Current Effective date range
  protected expirationFilter: DataRangeFilter<Date>; // Current Expiration date range
  protected dateFilterPlaceholder = '';
  protected selectedDateFieldName: string; // Determines whether or not the calendar icon is displayed
  protected premiumFilter: DataRangeFilter<number>; // Current Premium range
  protected premiumFrom: number;
  protected premiumTo: number;
  protected dateFieldList: string[]; // Populated with date filter options based on policy/quote toggle
  protected dateFilter: string;
  protected renewalFilterOptions: AimNewOrRenewal[] = ['New Business', 'Renewal'];
  protected stageFilterOptions: AimSubmissionStage[];
  protected tooltips?: Map<string, string>;

  // Year to date settings - for now add all dates except expiration
  private readonly today = new Date();
  private readonly firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
  private readonly expiration = 'expiration';

  @ViewChildren(TableSortDirective) headers: QueryList<TableSortDirective>; // Fetches all sortable headers
  @ViewChild('submissionsTable') table: ElementRef<HTMLTableElement>;

  constructor(
    protected readonly aim: AimService,
    protected readonly route: ActivatedRoute,
    protected readonly session: SessionService,
    private readonly truncate: TruncatePipe,
    private readonly window: Window,
    private readonly dl: DownloadService,
    protected readonly error: ApiErrorService,
    protected readonly notify: NotificationService,
    protected readonly odata: ODataQueryBuilderService,
    protected readonly router: Router,
    private readonly exportType: ExportType
  ) {
  }

  // Detect screen size change - currently used for adjusting paging maxSize.
  @HostListener('window:resize', ['$event'])
  protected onWindowResize() {
    this._screenWidth = window.innerWidth;
    this._maxSize = this._screenWidth < 400 ? 3 : this._screenWidth < 576 ? 7 : 12;
  }

  protected init(logPrefix: string) {
    this.initDateFields();
    this._logPrefix = `[${logPrefix}]`;
    this.onWindowResize();
  }

  protected initFilters(savedSessionId: string) {
    this.initialLoad = true;
    this.resetFilters(false); // Sets initial filter values
    if (savedSessionId == this.sessionId) {
      return this.loadSavedFilters(); // Reapplies saved filters (resets upon session close, eg: closing the browser)
    } else {
      this.updateActiveFilters();
      return this.populateDataWithoutInvalidSort();
    }
  }

  protected initDateFields() {
    // Build a date field list which reflects currently displayed table data
    // Allow only applicable stage filters for policies or quotes
    if (this.showPolicies) {
      this.dateFieldList = ['bound'];
      this.stageFilterOptions = PolicySubmissionStages;
    } else {
      this.dateFieldList = ['received'];
      this.stageFilterOptions = QuoteSubmissionStages;
    }
    this.dateFieldList.push('effective', 'expiration');
  }

  // Load filters from session storage
  protected loadSavedFilters() {
    Debug.debug(`${this._logPrefix} Loading filter settings..`);
    this._page = this.getSessionItem<number>('page', 1); // Set directly
    this._pageSize = this.getSessionItem<number>('pageSize', 25); // Set directly
    this.activeFilters = this.getSessionItem<FilterListItem[]>('activeFilters', []);
    this.boundFilter = this.setDateFields(this.getSessionItem<DataRangeFilter<Date>>('boundFilter', this.boundFilter));
    this.receivedFilter = this.setDateFields(this.getSessionItem<DataRangeFilter<Date>>('receivedFilter', this.receivedFilter));
    this.effectiveFilter = this.setDateFields(this.getSessionItem<DataRangeFilter<Date>>('effectiveFilter', this.effectiveFilter));
    this.expirationFilter = this.setDateFields(this.getSessionItem<DataRangeFilter<Date>>('expirationFilter', this.expirationFilter));

    let renewalFromSession = this.getSessionItem<AimNewOrRenewal[]>('activeRenewalFilters', this.activeRenewalFilters);
    this.activeRenewalFilters = this.renewalFilterOptions.filter(renewal => renewalFromSession.includes(renewal));

    let stagesFromSession = this.getSessionItem<AimSubmissionStage[]>('activeStageFilters', this.activeStageFilters);
    this.activeStageFilters = this.stageFilterOptions.filter(stage => stagesFromSession.includes(stage));

    this.loadSavedPremium();
    this.loadValidSearch();
    this.loadSavedMultiSelectFilters();
    this.loadSort();
    this.updateActiveFilters();
    return this.populateDataWithoutInvalidSort();
  }

  // remove sort if result set would fail because it has too many records
  private async removeInvalidSort(oDataQuery: string) {
    if (!this.sort) {
      return;
    }
    const maxOrderByCount = 10000; // value should match submission controller EnableQueryWithRestrictions value
    const sort = this.sort; // save sort to reapply if count valid
    this.sort = null; // remove sort from raw count query
    const count = await this.getSubmissionsCount(oDataQuery);
    if (count <= maxOrderByCount) {
      this.sort = sort;
      this.setSortHeaders(sort);
    }
    return count;
  }

  private async populateDataWithoutInvalidSort() {
    let oDataQuery = this.buildQuery();
    const count = await this.removeInvalidSort(oDataQuery);
    await this.populateData(oDataQuery, count);
  }

  private async getSubmissionsCount(oDataQuery: string) {
    try {
      const count = await this.aim.getSubmissionsCount(oDataQuery);
      Debug.debug(`${this._logPrefix} count response:`, count);
      return count;
    } catch (e) {
      this.error.processError(e, 'getSubmissionsCount');
    }
  }

  protected getSessionItem<T>(key: string, defaultValue: T | null = null) {
    return this.session.getItem<T>(key, defaultValue, false, this.customSessionPrefix);
  }

  protected loadSort() {
    this.sort = this.getSessionItem<SortBy>('sort');
  }

  // Session storage returns strings, so this converts dates back to Date types en mass
  private setDateFields(dateFilter: DataRangeFilter<Date>) {
    if (dateFilter.from) { dateFilter.from = new Date(dateFilter.from); }
    if (dateFilter.to) { dateFilter.to = new Date(dateFilter.to); }
    if (dateFilter.fromDefault) { dateFilter.fromDefault = new Date(dateFilter.fromDefault); }
    if (dateFilter.toDefault) { dateFilter.toDefault = new Date(dateFilter.toDefault); }
    if (dateFilter.min) { dateFilter.min = new Date(dateFilter.min); }
    if (dateFilter.max) { dateFilter.max = new Date(dateFilter.max); }
    return dateFilter;
  }

  protected resetFilters(resetSession = true) {
    Debug.debug(`${this._logPrefix} Resetting filters`);
    this.searchFilter = '';
    this.premiumFilter = { field: 'premium', from: null, to: null };

    this.boundFilter = { field: 'bound', from: null, to: null };
    this.receivedFilter = { field: 'received', from: null, to: null };

    // Set a default expiration filter to keep results within reason
    const twoYearsAgo = new Date(Date.now() - (365 * 86400 * 2000));
    if (this.showPolicies) {
      this.effectiveFilter = { field: 'effective', from: null, to: null };
      this.expirationFilter = { field: 'expiration', from: twoYearsAgo, to: null, fromDefault: twoYearsAgo };
    } else {
      this.effectiveFilter = { field: 'effective', from: twoYearsAgo, to: null, fromDefault: twoYearsAgo };
      this.expirationFilter = { field: 'expiration', from: null, to: null };
    }
    this.dateRangeFilters = [this.boundFilter, this.receivedFilter, this.effectiveFilter, this.expirationFilter];
    this.retailerFilter = { field: 'retailer', plurals: { singular: 'retailer', plural: 'retailers' }, items: null, selectedKeys: null, nameField: 'producer/name', valueField: 'producer/id' };
    this.multiSelectFilters = [this.retailerFilter];

    this.activeRenewalFilters = [];

    // Set default stage filters so that inactive submissions are not picked up
    this.activeStageFilters = this.showPolicies ? ['Active', 'Binding'] : ['Active', 'Quoting', 'Renewing'];

    this.searchFilter = '';

    for (const filter of this.dateRangeFilters) {
      this.setFilterDefault(filter);
    }

    this.premiumFrom = this.premiumFilter.from;
    this.premiumTo = this.premiumFilter.to;

    if (resetSession) this.updateSavedFilters();
  }

  // Save filters in session storage
  protected updateSavedFilters(): void {
    Debug.debug(`${this._logPrefix} Saving filter settings..`);
    this.setSessionItem('sort', this.sort);
    this.setSessionItem('page', this.page);
    this.setSessionItem('pageSize', this.pageSize);
    this.setSessionItem('activeFilters', this.activeFilters);
    this.setSessionItem('searchFilter', this.searchFilter);
    this.setSessionItem('boundFilter', this.boundFilter);
    this.setSessionItem('receivedFilter', this.receivedFilter);
    this.setSessionItem('effectiveFilter', this.effectiveFilter);
    this.setSessionItem('expirationFilter', this.expirationFilter);
    this.setSessionItem('premiumFilter', this.premiumFilter);
    this.setSessionItem('activeStageFilters', this.activeStageFilters);
    this.setSessionItem('activeRenewalFilters', this.activeRenewalFilters);
    const multiSelectFilterData = this.multiSelectFilters.map(({ field, selectedKeys, searchText }) => ({ field, selectedKeys, searchText }));
    this.setSessionItem('multiSelectFilters', multiSelectFilterData);
    this.setSessionItem('sessionSaved', this.sessionId);
    this.updateActiveFilters();
    this.populateDataWithoutInvalidSort();
  }

  private setSessionItem(key: string, value: any) {
    return this.session.setItem(key, value, false, this.customSessionPrefix);
  }

  public updateActiveFilters() {
    let newFilterList: FilterListItem[] = [];

    // Search text filter
    if (this.searchFilter) {
      Debug.debug(`${this._logPrefix} Adding filter for text search`, this.searchFilter);
      newFilterList.push({ label: `Contains: ${this.truncate.transform(this.searchFilter, 30)}`, field: 'search' });
    }
    let filterItem: FilterListItem | null;

    // Renewal filter
    for (const activeRenewalFilter of this.activeRenewalFilters) {
      filterItem = { label: `${activeRenewalFilter}`, field: activeRenewalFilter };
      Debug.debug(`${this._logPrefix} Adding renewal filter: ${activeRenewalFilter}`);
      newFilterList.push(filterItem);
    }

    // State filters
    for (const activeStageFilter of this.activeStageFilters) {
      filterItem = { label: `Stage: ${activeStageFilter}`, field: activeStageFilter };
      Debug.debug(`${this._logPrefix} Adding stage filter: ${activeStageFilter}`);
      newFilterList.push(filterItem);
    }

    // Date range filters
    let activeDateFilters = 0;
    for (const dateField of this.dateFieldList) {
      const filterField = this[`${dateField}Filter`] as DataRangeFilter<Date>; // Dynamically select the correct filter and reinstate type-checking
      filterItem = this.getDateRangeFilter(filterField);
      if (filterItem) {
        activeDateFilters++;
        Debug.debug(`${this._logPrefix} Adding filter for ${filterField.field}`, filterField);
        newFilterList.push(filterItem);
      }
    }

    // Premium filter
    if (this.premiumFilter.from != null || this.premiumFilter.to != null) {
      filterItem = this.getCurrencyRangeFilter(this.premiumFilter);
      if (filterItem) {
        Debug.debug(`${this._logPrefix} Adding filter for premium`, this.premiumFilter);
        newFilterList.push(filterItem);
      }
    }

    for (const filter of this.multiSelectFilters) {
      if (filter.selectedKeys && filter.selectedKeys.length > 0) {
        if (!(filter.items && filter.items.length == filter.selectedKeys.length)) {
          filterItem = { label: `${HumanReadablePipe.prototype.transform(filter.plurals.plural)}: ${filter.selectedKeys.length} Selected`, field: filter.field };
          newFilterList.push(filterItem);
        }
      }
    }

    // If all filters have been removed, revert to default filters to limit results
    // !CAUTION: If there are no default filters, this will cause a race condition! Remove this if default filters are ever disabled
    if (!newFilterList.length) {
      Debug.debug('[SubmissionsList] No active filters found, resetting..');
      this.resetFilters();
      return;
    }

    this.activeFilters = newFilterList; // Overwrite the entire value in order to trigger onChanges (using .push won't trigger it)
    this.updateDateFilterPlaceholder(activeDateFilters);
  }

  protected updateDateFilterPlaceholder(activeFilters: number) {
    if (activeFilters) {
      this.dateFilterPlaceholder = `${activeFilters} of ${this.dateFieldList.length} filters active`;
    } else {
      this.dateFilterPlaceholder = 'Select field to filter';
    }
  }

  // Sets the column sort/direction (triggered by click on the header)
  protected setSort(sort: SortBy) {
    if (sort.direction != '') {
      this.sort = sort;
    } else {
      this.sort = null;
    }
    this.setSortHeaders(sort);
    this.updateSavedFilters();
  }

  // Sync headers to the new sort setting
  private setSortHeaders(sort: SortBy) {
    this.headers.forEach((header) => {
      if (header.fieldName == sort.name) {
        header.sortOrder = sort.direction;
      } else {
        header.sortOrder = '';
      }
    });
  }

  // Fetches and populates the table data
  protected async populateData(oDataQuery: string, count: number | null = null) {
    this.loadingCount++;
    oDataQuery = await this.recalculatePage(count, oDataQuery);
    let result: ODataResponse<AimSubmission>;
    try {
      result = await this.aim.getSubmissions(oDataQuery);
      Debug.debug(`${this._logPrefix} populateData response:`, result);
    } catch (e) {
      this.error.processError(e, 'getSubmissions');
    } finally {
      this.rowData = result?.value ?? [];
      this.rowsTotal = result?.['@odata.count'] ?? 0;
      this.loadingCount--;
      this.initialLoad = false;
    }
  }

  // Fetches and populates the table data
  protected async populateGroupedData(oDataQuery: string, filter: MultiSelectFilter<string>) {
    let items: any[] = [];
    let skip = 0;
    const pageSize = 1000;

    try {
      while (true) {
        const result = await this.aim.getSubmissions(`${oDataQuery}&$top=${pageSize}&$skip=${skip}`);
        if (!Array.isArray(result) || result.length === 0) {
          break;
        }
        Debug.debug(`${this._logPrefix} populateGroupedData response:`, result);
        if (filter.field === 'retailer') {
          items.push(...result.map(data => ({ name: data.producer.id, value: data.producer.name })));
        }
        if (result.length < pageSize) {
          break; // Exit the loop if all records have been retrieved
        }
        skip += pageSize;
      }
    } catch (e) {
      this.error.processError(e, 'getSubmissions');
    }
    finally {
      filter.items = items?.sort((a, b) => a.value.localeCompare(b.value));
    }
  }

  // adjust current page to fit in result count
  // count may have already been fetched when checking the $orderby
  private async recalculatePage(count: number | null, oDataQuery: string) {
    if (this._page === 1) {
      return oDataQuery; // first page is always valid, no need to recalculate
    }
    if (count === null) {
      // fetch count
      count = await this.getSubmissionsCount(oDataQuery);
    }
    if (!count) {
      this._page = 1;
      return this.replaceODataSkip(oDataQuery);
    }
    const minRecordsforValidCurrentPage = 1 + (this._page - 1) * this._pageSize;
    if (count < minRecordsforValidCurrentPage) {
      this._page = Math.ceil(count / this._pageSize); // move current page to last possible page
      return this.replaceODataSkip(oDataQuery);
    }
    return oDataQuery;
  }

  private replaceODataSkip(oDataQuery: string) {
    const skip = (this._page - 1) * this._pageSize;
    if (this._page === 1) {
      return oDataQuery.replace(/&?\$skip=[\d]*/, '');
    }
    return oDataQuery.replace(/\$skip=[\d]*/, `$skip=${skip.toString()}`);
  }

  // Returns a filter-list compatible object for dates
  private getDateRangeFilter(filterField: DataRangeFilter<Date>): FilterListItem | null {
    const prettyName = HumanReadablePipe.prototype.transform(filterField.field);
    if (filterField.from && filterField.to) {
      if (dayjs(filterField.from).isSame(filterField.to, 'day')) {
        return { label: `${prettyName}: Equals ${formatDate(filterField.from, 'MM/dd/yyyy', environment.locale)}`, field: filterField.field };
      }
      const selectedDates = `${formatDate(filterField.from, 'MM/dd/yyyy', environment.locale)} - ${formatDate(filterField.to, 'MM/dd/yyyy', environment.locale)}`;
      if (dayjs(filterField.from).isSame(this.firstDayOfYear, 'day') && dayjs(filterField.to).isSame(this.today, 'day')) {
        return { label: `${prettyName}: YTD (${selectedDates})`, field: filterField.field };
      }
      return { label: `${prettyName}: ${selectedDates}`, field: filterField.field };
    } else if (filterField.to) {
      return { label: `${prettyName}: On or prior to ${formatDate(filterField.to, 'MM/dd/yyyy', environment.locale)}`, field: filterField.field };
    } else if (filterField.from) {
      return { label: `${prettyName}: On or after ${formatDate(filterField.from, 'MM/dd/yyyy', environment.locale)}`, field: filterField.field };
    }
    return null;
  }

  // Returns a filter-list compatible object for currency
  private getCurrencyRangeFilter(filterField: DataRangeFilter<number>): FilterListItem {
    const prettyName = HumanReadablePipe.prototype.transform(filterField.field);
    if (isNumber(filterField.from) && isNumber(filterField.to)) {
      if (filterField.to === filterField.from) {
        return {
          label: `${prettyName}: Equals ${formatCurrency(filterField.to, environment.locale, '$')}`,
          field: filterField.field
        };
      }
      return {
        label: `${prettyName}: ${formatCurrency(filterField.from, environment.locale, '$')} - ${formatCurrency(filterField.to, environment.locale, '$')}`,
        field: filterField.field
      };
    } else if (isNumber(filterField.to)) {
      return {
        label: `${prettyName}: At most ${formatCurrency(filterField.to, environment.locale, '$')}`,
        field: filterField.field
      };
    } else if (isNumber(filterField.from)) {
      return {
        label: `${prettyName}: At least ${formatCurrency(filterField.from, environment.locale, '$')}`,
        field: filterField.field
      };
    }
    return null;
  }

  // Sanity check for entered premium filter values
  protected checkMinPremiumValues(validateOnly = false) { // Returns true if there is an error
    let errorMsg: string;
    if (this.premiumFrom !== null) {
      if (isNumber(this.premiumFrom) && isFinite(this.premiumFrom)) {
        this.premiumFrom = Number(this.premiumFrom.toFixed(2));
        if (this.premiumFilter.to != null && this.premiumFrom > this.premiumFilter.to) {
          errorMsg = 'Min premium must be less than or equal to maximum (or left blank)';
        } else if (this.premiumFrom > this.maxValue) {
          errorMsg = 'Min premium is too high';
        } else if (this.premiumFrom < -this.maxValue) {
          errorMsg = 'Min premium is too low';
        }
      } else {
        errorMsg = 'Min premium is not a number';
      }
    }

    if (errorMsg) {
      if (!validateOnly) {
        this.notify.showToast(errorMsg, 'Premium filter is invalid', ToastType.Warning);
      }
      return true;
    }

    if (!validateOnly && this.premiumFrom != this.premiumFilter.from) { // Only update if the value changed
      this.premiumFilter.from = this.premiumFrom;
      // For when previous premium validation failed with PremiumFrom is higher than PremiumTo
      if (this.premiumTo != this.premiumFilter.to && Math.abs(this.premiumTo) <= this.maxValue && this.premiumFilter.from <= this.premiumTo) {
        Debug.debug(`${this._logPrefix} Updating PremiumTo filter along with PremiumFrom:`, this.premiumTo, this.premiumFilter.to);
        this.premiumFilter.to = this.premiumTo;
      }
      this.updateSavedFilters();
    }
    return false;
  }

  protected checkMaxPremiumValues(validateOnly = false) { // Returns true if there is an error
    let errorMsg: string;
    if (this.premiumTo !== null) {
      if (isNumber(this.premiumTo) && isFinite(this.premiumTo)) {
        this.premiumTo = Number(this.premiumTo.toFixed(2));
        if (this.premiumFilter.from != null && this.premiumTo < this.premiumFilter.from) {
          errorMsg = 'Max premium must be greater than or equal to minimum (or left blank)';
        } else if (this.premiumTo > this.maxValue) {
          errorMsg = 'Max premium is too high';
        } else if (this.premiumTo < -this.maxValue) {
          errorMsg = 'Max premium is too low';
        }
      } else {
        errorMsg = 'Max premium is not a number';
      }
    }

    if (errorMsg) {
      if (!validateOnly) {
        this.notify.showToast(errorMsg, 'Premium filter is invalid', ToastType.Warning);
      }
      return true;
    }

    if (!validateOnly && this.premiumTo != this.premiumFilter.to) { // Only update if the value changed
      this.premiumFilter.to = this.premiumTo;
      // For when previous premium validation failed with PremiumFrom is higher than PremiumTo
      if (this.premiumFrom != this.premiumFilter.from && Math.abs(this.premiumFrom) <= this.maxValue && this.premiumFilter.to >= this.premiumFrom) {
        Debug.debug(`${this._logPrefix} Updating PremiumFrom filter along with PremiumTo:`, this.premiumFrom, this.premiumFilter.from);
        this.premiumFilter.from = this.premiumFrom;
      }
      this.updateSavedFilters();
    }
    return false;
  }

  // Load premium filter from session and validate
  private loadSavedPremium() {
    const filterName = 'premiumFilter';
    this.premiumFilter = this.getSessionItem<DataRangeFilter<number>>(filterName, this.premiumFilter);
    this.premiumFrom = this.premiumFilter?.from || null;
    this.premiumTo = this.premiumFilter?.to || null;
    if (this.premiumFilter && (this.checkMinPremiumValues(true) || this.checkMaxPremiumValues(true))) {
      // Values should be validated before saved to session.
      // But this could still happen if session values are altered in browswer.
      Debug.debug('Validation on premium loaded from session failed');
      this.premiumFrom = this.premiumFilter.from = this.premiumFilter.fromDefault || null;
      this.premiumTo = this.premiumFilter.to = this.premiumFilter.toDefault || null;
      this.setSessionItem(filterName, this.premiumFilter);
    }
  }

  // Load search filter from session and validate
  private loadValidSearch(): void {
    const filterName = 'searchFilter';
    this.searchFilter = this.getSessionItem<string>(filterName, '');
    if (this.searchFilter != '' && (this.searchFilter.length < 3 || this.searchFilter.length > 50)) {
      Debug.debug('Validation on searchFilter loaded from session failed');
      this.searchFilter = '';
      this.setSessionItem(filterName, this.searchFilter);
    }
  }

  // Load multi select filters from session and validate
  private loadSavedMultiSelectFilters() {
    const sessionMultiSelectFilters = this.getSessionItem<MultiSelectFilter<string>[]>('multiSelectFilters', this.multiSelectFilters);

    if (!sessionMultiSelectFilters || sessionMultiSelectFilters.length === 0) {
      return;
    }

    for (const savedFilter of sessionMultiSelectFilters) {
      if (typeof savedFilter.field !== 'string') {
        continue; // Skip the filter if field is not a string
      }

      const existingFilter = this.multiSelectFilters.find(filter => filter.field === savedFilter.field);
      if (!existingFilter) {
        continue; // Skip the filter if existingFilter does not exist
      }
      // Filter out selectedKeys that are not alphanumeric or that have a length not between 5 and 8 characters
      existingFilter.selectedKeys = savedFilter.selectedKeys ? savedFilter.selectedKeys.filter(key => /^[a-zA-Z0-9]{5,8}$/.test(key)) : null;
      existingFilter.searchText = typeof savedFilter.searchText === 'string' ? savedFilter.searchText : null;
    }
  }

  // Removes an active filter after the filter-list 'X' is pressed
  protected removeFilter(targetFilter: FilterListItem) {
    const fieldName = targetFilter?.field;
    if (fieldName) {
      if (this.stageFilterOptions.includes(fieldName)) {
        this.activeStageFilters = this.activeStageFilters.filter(x => x != fieldName);
      } else if (this.renewalFilterOptions.includes(fieldName)) {
        this.activeRenewalFilters = this.activeRenewalFilters.filter(x => x != fieldName);
      } else if (this.dateFieldList.includes(fieldName)) {
        const filterField = this[`${fieldName}Filter`] as DataRangeFilter<Date>; // Dynamically select the correct filter and reinstate type-checking
        if (filterField?.field) {
          if (dayjs(filterField.from).isSame(filterField.fromDefault, 'day') && dayjs(filterField.to).isSame(filterField.toDefault, 'day') && this.activeFilters.length == 1) {
            this.notify.showToast('To limit results, the default filter cannot be removed unless other filters are present.', 'Cannot remove default filter', ToastType.Warning);
            return;
          }
          filterField.from = null;
          filterField.to = null;
        }
      } else if (fieldName == 'search') {
        this.searchFilter = '';
      } else if (fieldName == 'premium') {
        this.setFilterDefault(this.premiumFilter);
        this.premiumTo = this.premiumFilter.to || null;
        this.premiumFrom = this.premiumFilter.from || null;
      }
      else if (fieldName === 'retailer') {
        const filter = this.multiSelectFilters.find(filter => filter.field === fieldName);
        filter.searchText = null;
        filter.selectedKeys = null;
        filter.items = null;
      }
      Debug.debug(`${this._logPrefix} Removed Filter: `, targetFilter);
      this.updateSavedFilters();
    }
  }

  // Handles clicking "export" button
  async downloadExport() {
    // Store orig values so they don't get permanently reset
    const origValues = { pageSize: this._pageSize, page: this._page };
    // Return all results (and not just the current page)
    this._pageSize = 0;
    this._page = 1;
    const odataQuery = this.buildQuery();
    // Set the original values directly so the data doesn't refresh
    this._page = origValues.page;
    this._pageSize = origValues.pageSize;
    try {
      this.exportInProgress = true;
      const response = await this.aim.getSubmissionsExport(odataQuery, this.exportType);
      Debug.debug(`${this._logPrefix} downloadExport response:`, response);
      const downloadOptions: DownloadOptions = {
        alternateFilename: `${(this.showPolicies ? 'Policy' : 'Quote')} export - ${new Date().toDateString()}.xlsx`,
        successMessage: 'Export complete'
      };
      this.dl.startBrowserDownload(response, downloadOptions);
    } catch (e) {
      const options: ApiErrorOptions = {
        title: 'Error downloading export',
        hiddenCodes: []
      };
      this.error.processError(e, 'downloadExport', options);
    }
    this.exportInProgress = false;
  }

  protected applyQueryOptions(qb: ODataQueryBuilder) {
    // Always include the total rows
    qb.count();

    // Fetch only the current page
    if (this.page > 1) {
      qb.skip(this.pageSize * (this.page - 1));
    }

    // Limit the results to the selected page size
    if (this.pageSize > 0) {
      qb.top(this.pageSize);
    }

    // Set the sort direction
    if (this.sort) {
      qb.order(this.sort.name, this.sort.direction == 'asc');
    }
  }

  protected applyQueryFilter(
    filter: ODataQueryFilterBuilder<ODataQueryBuilder> | ODataQueryFilterBuilder<DefaultTransformBuilder>,
    searchFields: string[] = [],
    odataApply = false) {

    // Limit results to either policies or quotes
    if (this.showPolicies) {
      filter.isTrue('isBound');
    } else {
      filter.isFalse('isBound');
    }

    // if odataApply then the only filter that is applied is the isBound filter
    if (odataApply) {
      return;
    }

    // Renewal field
    if (this.activeRenewalFilters.length && this.activeRenewalFilters.length != this.renewalFilterOptions.length) {
      if (this.activeRenewalFilters.includes('Renewal')) {
        filter.and().isTrue('isRenewal');
      }
      else {
        filter.and().isFalse('isRenewal');
      }
    }

    // Stage field
    if (this.activeStageFilters.length && this.activeStageFilters.length != this.stageFilterOptions.length) {
      filter.and().beginGroup();
      for (const activeStage of this.activeStageFilters) {
        filter.or().equal('stage', activeStage);
      }
      filter.endGroup();
    }

    // Date fields

    for (const dateField of this.dateFieldList) {
      // Dynamically select the correct filter and reinstate type-checking
      const filterField = this[`${dateField}Filter`] as DataRangeFilter<Date>;
      filter.and().beginGroup();
      if (filterField.from && filterField.to && dayjs(filterField.from).isSame(filterField.to, 'day')) {
        filter.and().equal(filterField.field, filterField.to);
      } else {
        if (filterField.from) {
          filter.and().greaterEqual(filterField.field, filterField.from);
        }
        if (filterField.to) {
          filter.and().lessEqual(filterField.field, filterField.to);
        }
      }
      filter.endGroup();
    }

    // Premium field
    if (this.premiumFilter.from != null || this.premiumFilter.to != null) {
      filter.and().beginGroup();
      if (this.premiumFilter.from !== null && this.premiumFilter.to !== null && this.premiumFilter.from === this.premiumFilter.to) {
        filter.and().equal(this.premiumFilter.field, this.premiumFilter.from);
      } else {
        if (this.premiumFilter.from !== null) {
          filter.and().greaterEqual(this.premiumFilter.field, this.premiumFilter.from);
        }
        if (this.premiumFilter.to !== null) {
          filter.and().lessEqual(this.premiumFilter.field, this.premiumFilter.to);
        }
      }
      filter.endGroup();
    }

    for (const item of this.multiSelectFilters) {
      if (item.selectedKeys?.length && item.items?.length !== item.selectedKeys.length) {
        filter.and().isIn(item.valueField, item.selectedKeys);
      }
    }


    // text search
    const isSearchTextPremium = this.searchFilter.match(/(?=.*?\d)^([$]?[-]|([-]?[$]))?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{0,})?$/) !== null; // decimal number with optional dollar sign and negative with optional comma groupings
    const parsedDate = isSearchTextPremium ? null : this.parseDate(this.searchFilter);
    if (searchFields.length > 0 || isSearchTextPremium || parsedDate) {
      filter.and().beginGroup();

      // text search premium
      if (isSearchTextPremium) {
        const premiumSearchString = this.searchFilter.replace(/[$,]/g, ''); // remove dollar sign and commas
        const firstTwo = this.searchFilter.substring(0, 2);
        const field = 'cast(premium, \'Edm.String\')';
        if (firstTwo.indexOf('$') >= 0 || firstTwo.indexOf('-') >= 0) {
          filter.or().startsWith(field, premiumSearchString);
        } else if (premiumSearchString.match(/^[0]+$/g)) {
          // when searching for all zeroes you get almost all results since most premiums end with .0000
          // This ignores decimals like .0000 when searching 0000 but allows 10000.0000 and 10000.1234
          filter.or().contains('cast(cast(round(premium), \'Edm.Int32\'), \'Edm.String\')', premiumSearchString); // this won't match 123.0001 if searching for 000 but this is probably the closest we'll get for now since indexof is broken on casted columns
        } else {
          filter.or().contains(field, premiumSearchString);
        }
        filter.or().equal('round(premium)', +premiumSearchString); // check for rounded amount since that is what we display
      }

      // text search dates
      if (parsedDate) {
        const dates = [
          'effective',
          'expiration'
        ];
        if (this.showPolicies) {
          dates.push('bound');
        } else {
          dates.push('received');
        }
        for (const dateField of dates) {
          filter.or().equal(dateField, parsedDate);
        }
      }

      // string fields
      for (const searchField of searchFields) {
        filter.or().contains(searchField, this.searchFilter);
      }
      filter.endGroup();
    }
  }

  protected buildQuery() {
    const query = this.odata.create();
    this.applyQueryOptions(query);
    this.applyQueryFilter(query.filter);
    return query.build();
  }

  protected parseDate(dateString: string) {
    // Various date formats we should be able to parse unambiguously
    // Most of these seem to parse fine under YYYY-MM-DD regardless of the actual format and the fact that we are using strict mode ¯\_(ツ)_/¯
    const acceptableParseFormats = [
      'YYYY-MM-DD',
      'YYYY-M-D',
      'YYYY-MMM-D',
      'YYYY-MMMM-D',
      'YY-MM-DD',
      'YY-M-D',
      'YY-MMM-D',
      'YY-MMMM-D',
      'MM-DD-YYYY',
      'MMM-DD-YYYY',
      'MMMM-DD-YYYY',
      'DD-MM-YYYY',
      'DD-MMM-YYYY',
      'DD-MMMM-YYYY',
      'M-D-YYYY',
      'D-M-YYYY',
      'M-D-YY',
      'DD-MM-YY',
      'MM-DD-YY',
      'MMMM-DD--YY',
      'MMMM-D--YY'
    ]; // in order
    dateString = dateString.replace(/[./_, ]/g, '-'); // replace delimiter with dash, keep dash because the date formats are less ambiguous than removing it entirely
    dateString = dateString.replace(/--/g, '-'); // replace double dashes as they don't seem to get recognized in the format strings
    dateString = dateString.replace(/(st)|(nd)|(rd)|(th)/g, ''); // replace ordinals as dayjs Do doesn't seem to work
    const maxValidDateLength = 17; // ex after replacements above: September-22-2024
    if (dateString.length > maxValidDateLength) {
      dateString = dateString.substring(0, maxValidDateLength); // remove extra characters outside of possible max length
    }
    const timeStart = dateString.indexOf('T');
    if (timeStart >= 0) {
      dateString = dateString.substring(0, timeStart); // remove time if max length replacement didn't remove it already
    }

    // try to parse the cleaned up date string
    let parsedDate: Dayjs = dayjs(dateString, acceptableParseFormats, true);
    if (parsedDate.isValid()) {
      return parsedDate.toDate();
    }
    return null;
  }

  protected get page() {
    return this._page;
  }

  // Intercept setting of the page so we can trigger a refresh
  protected set page(page: number) {
    if (page !== this.page) {
      this._page = page;
      this.scrollToTop();
      this.updateSavedFilters();
    }
  }

  protected scrollToTop(allTheWayUp = false) {
    if (allTheWayUp) {
      this.window.scrollTo(0, 0);
    } else {
      this.table.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  protected get pageSize() {
    return this._pageSize;
  }

  protected get maxSize() {
    return this._maxSize;
  }

  protected get smallDevice() {
    return this._screenWidth <= 767;
  }

  protected get combineDateFilters() {
    return this._screenWidth <= 1003;
  }

  protected get showScrollButton() {
    return this.smallDevice || this.pageSize >= 25;
  }

  // Intercept setting of the page size so we can trigger a refresh
  protected set pageSize(pageSize: number) {
    if (pageSize !== this.pageSize) {
      if (this.rowsTotal > pageSize || this.rowsTotal > this._pageSize) {
        this._pageSize = pageSize;
        this.scrollToTop();
        this.updateSavedFilters();
      } else { // Don't refresh data for no reason
        this._pageSize = pageSize;
      }
    }
  }

  // Opens a date-range-picker modal
  protected async openDateRangeModal(fieldName: string) {
    if (!fieldName || !this.dateFieldList.includes(fieldName)) {
      return;
    }
    const filter = this[`${fieldName}Filter`] as DataRangeFilter<Date>; // Dynamically select the correct filter and reinstate type-checking
    Debug.debug(`${this._logPrefix} Opening modal for ${filter.field}`, filter);
    const prettyName = HumanReadablePipe.prototype.transform(fieldName);
    const datePickerOptions: DateRangeOptions = {
      beginDate: filter.from,
      endDate: filter.to,
      beginDateLabel: `Begin ${prettyName} Date`,
      endDateLabel: `End ${prettyName} Date`,
      dateRangeToApply: fieldName === this.expiration ? null : { begin: this.firstDayOfYear, end: this.today, label: 'Year to Date' }
    };
    Debug.debug(`${this._logPrefix} Calling Date range picker:`, datePickerOptions);
    const result: DateRangeResult = await this.notify.showModalTemplate(DateRangePickerComponent, { options: datePickerOptions });
    if (result.isChanged) {
      filter.from = result.begin;
      filter.to = result.end;
      Debug.log(`${this._logPrefix} Updated filter: ${filter.field}`, filter);
      this.updateSavedFilters();
    }
  }

  protected async openMultiSelectSearchModal(fieldName: string) {
    if (!fieldName) {
      return;
    }
    const filter = this.multiSelectFilters.find(filter => filter.field === fieldName);
    if (!filter) {
      return;
    }

    Debug.debug(`${this._logPrefix} Opening modal for ${filter.field}`, filter);

    if (!filter.items || filter.items.length === 0) {
      const qb = this.odata.create();
      this.applyQueryFilter(qb.apply.filter, [], true);
      qb.apply.groupBy([filter.valueField, filter.nameField]);
      await this.populateGroupedData(qb.build(), filter);
    }

    const options: MultiCheckSearchOptions = {
      plurals: filter.plurals,
      items: filter.items,
      searchText: filter.searchText,
      selectedItems: filter.selectedKeys
    };

    const result: MultiCheckSearchResult = await this.notify.showModalTemplate(MultiCheckSearchSelectorComponent, { options: options });

    if (result.isChanged) {
      filter.searchText = result.searchText;
      filter.selectedKeys = result.selectedItems;
      this.updateActiveFilters();
      this.updateSavedFilters();
    }
  }

  // Determines quote/policy singular/plural text
  protected getResultText(): string {
    return (this.showPolicies) ? (this.rowsTotal === 1) ? 'policy' : 'policies' : (this.rowsTotal === 1) ? 'quote' : 'quotes';
  }

  protected filteredCountText() {
    if (this.activeFilters.length && !this.loading) {
      return `Narrowed to ${formatNumber(this.rowsTotal, environment.locale)} ${this.getResultText()}`;
    } else {
      return 'Loading...';
    }
  }

  // Finished loading and data exists
  protected get dataReady() {
    return !this.initialLoad && this.rowsTotal && this.loadingCount < 1;
  }

  // Data is Loading
  protected get loading() {
    return this.loadingCount > 0 || this.initialLoad;
  }

  // Returns true if a date is prior to today
  protected isExpired(expiration: Date): boolean {
    return new Date() > new Date(expiration);
  }

  private setFilterDefault<T>(filter: DataRangeFilter<T>) {
    filter.from = filter.fromDefault ?? null;
    filter.to = filter.toDefault ?? null;
  }
}