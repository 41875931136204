import { Resource } from "../text-resources";

export interface MessageModalButtonOptions {
  mode: MessageModalButtonMode;
  cancelTitle?: string | Resource;
  confirmTitle?: string | Resource;
}

export enum MessageModalButtonMode {
  Close,
  ConfirmCancel
}