import { Pipe, PipeTransform } from '@angular/core';
import { AbbreviateNumberOptions } from './abbreviate-number-options';

@Pipe({
  name: 'abbreviateNumber'
})
export class AbbreviateNumberPipe implements PipeTransform {
  private defaultOptions: AbbreviateNumberOptions = {
    base: 1000,
    currency: false,
    precision: 0,
    addtlSuffix: ''
  };

  private baseSuffixes = ['', 'K', 'M', 'B', 'T'];
  private binarySuffixes = ['', 'K', 'M', 'G', 'T'];

  transform(value: any, options?: AbbreviateNumberOptions): any {
    const config = { ...this.defaultOptions, ...options };
    let { base, currency, precision, addtlSuffix } = config;

    if (Object.isUndefined(value)) {
      return value;
    }

    if (currency) {
      value = this.tryParseCurrencyString(value);
    }

    if (isNaN(value)) {
      return value;
    }

    let pow = 0;
    while (value >= base) {
      value = value / base;
      pow++;
    }

    return (currency ? '$' : '') +
      this.round(value, precision) +
      (base == 1024 ? this.binarySuffixes[pow] : this.baseSuffixes[pow]) +
      addtlSuffix;
  }

  private tryParseCurrencyString(value: string) {
    return String(value).replace(/[$,]/g, '');
  }

  private round(value, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  }
}
