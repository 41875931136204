<form #multi="ngForm">
  <div *ngIf="useDropList; else checkboxList" class="multi-check-selector" ngbDropdown autoClose="outside">
    <div ngbDropdownToggle class="form-control deemphasized" [id]="name" data-toggle="dropdown" aria-expanded="false"
         tabindex="0" aria-haspopup="true">
      <span>{{getButtonText()}}</span>
      <i class="fa fa-angle-down ms-auto mt-auto pb-1 pe-1"></i>
    </div>
    <ul ngbDropdownMenu>
      <li ngbDropdownItem *ngIf="includeAllOption" (keydown.space)="onOptionAllSelected($event)">
        <label for="{{name}}_all">
          <checkbox-control [name]="name + '_all'" [ngModel]="selectAll"
                            (ngModelChange)="onToggleAll()"></checkbox-control>
          All
        </label>
      </li>
      <li ngbDropdownItem *ngFor="let option of options; index as i" (keydown.space)="onOptionSelected($event, option)"
          class="d-flex justify-content-between">
        <label for="{{name + '_' + option.id}}">
          <checkbox-control [name]="name + '_' + option.id" [(ngModel)]="option.checked"
                            (ngModelChange)="onOptionToggle($event, option)"></checkbox-control>
          {{option.label | getresource}}
        </label>
        <i *ngIf="tooltips?.get(option.label['key'] ?? option.label) || tooltips?.get(i) as tooltipContent"
           (click)="openTooltip(option.label, tooltipContent)" title="{{tooltipContent}}"
           class="fas fa-info-circle mt-2 ms-2"></i>
      </li>
    </ul>
  </div>

  <ng-template #checkboxList>
    <ul>
      <li *ngIf="includeAllOption">
        <label for="{{name}}_all">
          <checkbox-control [name]="name + '_all'" [ngModel]="selectAll"
                            (ngModelChange)="onToggleAll()"></checkbox-control>
          All
        </label>
      </li>
      <li *ngFor="let option of options">
        <label for="{{name + '_' + option.id}}">
          <checkbox-control [name]="name + '_' + option.id" [(ngModel)]="option.checked"
                            (ngModelChange)="onOptionToggle($event, option)"></checkbox-control>
          {{option.label | getresource}}
        </label>
      </li>
    </ul>
  </ng-template>
</form>