import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '@env';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ProfileService } from './profile.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  public static readonly AddAuthHttpHeader = Object.freeze({
    headers: {
      'addAuthorization': 'true'
    }
  });

  public static readonly AuthHttpHeaderNoCache = Object.freeze({
    headers: {
      'addAuthorization': 'true',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    }
  });

  constructor(private readonly injector: Injector) { }

  /**
   * Intercepts requests and adds an authorization header. 401 responses will be redirected to login.
   * Dev login credentials from local storage will automatically be retried and only redirected on additional failure or if they are missing.
   * @param req http request to authorize
   * @param next next handler
   * @returns http event
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requiresAuth = req.headers.get('addAuthorization');

    if (!String.equalsIgnoreCase(requiresAuth, 'true')) {
      return next.handle(req); // no auth needed
    }

    const profileSvc = this.injector.get<ProfileService>(ProfileService);
    const obvs = from(profileSvc.getProfile());

    return obvs.pipe(
      switchMap(profile => {
        if (profile.isAuthenticated) { // add authorization header if profile is authenticated
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${profile.accessToken.value}`
            }
          });
        }
        return next.handle(req)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status != 401 || environment.bypassAuth) {
                // throw any errors that are not 401
                return throwError(() => error);
              }

              // does the user have an active token?
              return from(profileSvc.getProfile())
                .pipe(switchMap((profile) => {
                  if (profile.isAuthenticated) {
                    // if so, throw the error - likely a "normal" API response code
                    return throwError(() => error);
                  } else {
                    profileSvc.expiredSessionRedirect(); // redirect to portal
                    return of(new HttpResponse()); // avoid additional toast
                  }
                }));
            }));
      })
    );
  }
}
