import { Component, Input } from '@angular/core';
import { AbstractValueAccessor, makeProvider } from '../abstract-value-accessor';

@Component({
  selector: 'checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss'],
  providers: [makeProvider(() => CheckboxControlComponent)]
})
export class CheckboxControlComponent extends AbstractValueAccessor {

  @Input() name: string;
  @Input() disabled?: boolean;

  @Input() forcedOverride?: boolean;

  public onTouched: any = () => { return; };

  inputValue: boolean;

  constructor() {
    super();
    this.inputValue = false;
  }

  writeValue(value) {
    if (value !== this.value) {
      super.writeValue(value);
      this.inputValue = Object.isDefined(value) && value;
    }
  }

  get isDisabled() {
    return Object.isDefined(this.disabled) && this.disabled;
  }

  handleInputChange() {
    this.value = this.inputValue;
  }

  toggle(evt) {
    if (this.isDisabled) return;
    evt.stopPropagation();
    evt.preventDefault();
    this.inputValue = !this.inputValue;
    this.value = this.inputValue;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
