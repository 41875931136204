
import { ForwardRefFn, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/* WARNING
 *
 * Use caution when changing this file as it is the base class for every custom component that makes use of NgModel.
 * If you need something more custom, consider implementing ControlValueAccessor in your component instead.
 * */

export type OnChangeCallback = (value: any) => void;
export type OnTouchCallback = () => void;

// https://stackoverflow.com/questions/34948961/angular-2-custom-form-input/34998780#34998780
export abstract class AbstractValueAccessor implements ControlValueAccessor {

  private _value: any = null;

  private _onChange: OnChangeCallback = () => { return; };
  private _onTouched: OnTouchCallback = () => { return; };

  // the properties are used by bindings
  get value(): any { return this._value; }
  set value(v: any) {
    if (this.changeValue(v)) {
      this.onValueSet(v);
    }
  }

  /**
   * Called by angular to write to the view due to programmatic changes.
   * @param value the value to set
   */
  writeValue(value: any) {
    // programmatic changes shouldn't emit _onChange
    if (this.changeValue(value, false)) {
      this.onValueSet(value);
    }
  }

  /**
   * Changes the value to the one supplied. This method will not call onValueSet.
   * @param value the new value
   * @param fireOnChange true to fire angular's change event
   */
  changeValue(value: any, fireOnChange = true): boolean {
    if (value !== this._value) {
      this._value = value;
      if (fireOnChange) {
        this._onChange(value);
      }
      return true;
    }
    else {
      return false;
    }
  }

  /* "override" in subclass to do something when the value is set. this is
   * more of a convenience (yet somewhat necessary) method. a subclass would
   * technically have to override registerOnChange and writeValue to accomplish
   * the same thing however both of those are required by the value accessor
   * interface and are better left alone.
   **/

  protected onValueSet(value: any) {
    // TODO: Find if this can go away (2/15/2023)
    return;
  }

  // called by angular
  registerOnChange(fn: OnChangeCallback) {
    this._onChange = fn;
  }

  fireOnChange() {
    this._onChange(this._value);
  }

  // called by angular
  registerOnTouched(fn: OnTouchCallback) {
    this._onTouched = fn;
  }

  // note: should be called directly by custom component (in some blur event) in order for it to reflect a touched state
  fireOnTouched() {
    this._onTouched();
  }
}

export function makeProvider(forwardRefFn: ForwardRefFn) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(forwardRefFn),
    multi: true
  };
}
