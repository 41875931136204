import { Injectable } from '@angular/core';
import { ModalSpinnerService, WindowService } from '@core/index';
import { environment } from 'environments/environment';

export function environmentUpdateStartupFactory(service: EnvironmentUpdateService) {
  return () => service.updateToLocalUrls();
}

@Injectable()
export class EnvironmentUpdateService {
  // private readonly localDirectoryApiUri = 'http://localhost:50471/';
  readonly localAimApiUri = 'http://localhost:33558/';
  readonly localDocumentApiUri = 'https://localhost:44332/';
  readonly localStatementApiUri = 'http://localhost:12062/';
  readonly localCertificateApiUri = 'http://localhost:54724/';
  readonly localPortalApiUri = 'http://localhost:26995/';
  readonly localProducersApiUri = 'http://localhost:7776/api/';
  readonly localProductApiUri = 'http://localhost:25474/api/';
  readonly localOnboardingApiUri = 'http://localhost:62770/';

  // private readonly LocalDirectoryKey = 'env.use-local.directory';
  private readonly LocalAimApiKey = 'env.use-local.aim-api';
  private readonly LocalDocumentApiKey = 'env.use-local.document-api';
  private readonly LocalStatementApiKey = 'env.use-local.statement-api';
  private readonly LocalCertificateApiKey = 'env.use-local.certificate-api';
  private readonly LocalDataKey = 'env.use-local.data';
  private readonly LocalPortalKey = 'env.use-local.portal';
  private readonly LocalProducersKey = 'env.use-local.producers-service';
  private readonly LocalProductsKey = 'env.use-local.products-service';
  private readonly LocalOnboardingKey = 'env.use-local.onboarding-service';

  constructor(
    private readonly windowService: WindowService,
    private readonly spinner: ModalSpinnerService
  ) { }

  updateToLocalUrls() {
    if (!this.isLocal()) { return; }

    if (this.useLocalAimApi) {
      environment.aimApiUri = this.localAimApiUri || environment.aimApiUri;
    }

    if (this.useLocalDocumentApi) {
      environment.documentApiUri = this.localDocumentApiUri || environment.documentApiUri;
    }

    if (this.useLocalStatementApi) {
      environment.statementApiUri = this.localStatementApiUri || environment.statementApiUri;
    }

    if (this.useLocalCertificateApi) {
      environment.certificateApiUri = this.localCertificateApiUri || environment.certificateApiUri;
    }

    if (this.useLocalPortal) {
      environment.portalApiUri = this.localPortalApiUri || environment.portalApiUri;
    }

    if (this.useLocalProducersApi) {
      environment.producersApiUri = this.localProducersApiUri || environment.producersApiUri;
    }

    if (this.useLocalProductsApi) {
      environment.productApiUri = this.localProductApiUri || environment.productApiUri;
    }

    if (this.useLocalOnboardingApi) {
      environment.useLocalOnboarding = this.useLocalOnboardingApi;
    }

    environment.useLocalData = this.useLocalData;
  }

  isLocal() {
    return this.windowService.nativeWindow.location.hostname === 'localhost';
  }

  get useLocalAimApi() {
    return this.getValue(this.LocalAimApiKey);
  }

  set useLocalAimApi(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalAimApiKey, newValue);
  }

  get useLocalDocumentApi() {
    return this.getValue(this.LocalDocumentApiKey);
  }

  set useLocalDocumentApi(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalDocumentApiKey, newValue);
  }

  get useLocalStatementApi() {
    return this.getValue(this.LocalStatementApiKey);
  }

  set useLocalStatementApi(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalStatementApiKey, newValue);
  }

  get useLocalCertificateApi() {
    return this.getValue(this.LocalCertificateApiKey);
  }

  set useLocalCertificateApi(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalCertificateApiKey, newValue);
  }

  get useLocalData() {
    return this.getValue(this.LocalDataKey);
  }

  set useLocalData(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalDataKey, newValue);
  }

  get useLocalPortal() {
    return this.getValue(this.LocalPortalKey);
  }

  set useLocalPortal(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalPortalKey, newValue);
  }

  get useLocalProducersApi() {
    return this.getValue(this.LocalProducersKey);
  }

  set useLocalProducersApi(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalProducersKey, newValue);
  }

  get useLocalProductsApi() {
    return this.getValue(this.LocalProductsKey);
  }

  set useLocalProductsApi(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalProductsKey, newValue);
  }

  get useLocalOnboardingApi() {
    return this.getValue(this.LocalOnboardingKey);
  }

  set useLocalOnboardingApi(newValue: boolean) {
    if (!this.isLocal()) { return; }
    this.setValue(this.LocalOnboardingKey, newValue);
  }

  private getValue(key: string): boolean {
    try {
      const storedVal = sessionStorage.getItem(key);
      return Boolean(JSON.parse(storedVal));
    } catch (err) {
      return false; // Probably a JSON parse error from the value not being defined - assume it's not set
    }
  }

  private setValue(key: string, newValue: boolean) {
    sessionStorage.setItem(key, newValue ? 'true' : 'false');
    this.spinner.start();
    this.windowService.nativeWindow.location.reload();
  }
}
