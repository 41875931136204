import { FilterBuilder, TransformBuilder } from "./odata.interfaces";

export class ODataTransformBuilder<Q, T extends FilterBuilder> implements TransformBuilder {
  private _groupBy: string[];

  // gets the filter builder to be used in the transform
  // note that this is different than the one for the overall odata query
  filter: T;

  constructor(readonly query: Q) {
    this._groupBy = [];
  }

  get hasTransform() {
    return this._groupBy.length > 0 || this.filter.hasFilter;
  }

  // groupby can have additional transformation functions like aggregate so this will need to be reworked if we add support for that
  groupBy(fields: string[]) {
    this._groupBy.push('groupby((');
    for (let field of fields) {
      if (this._groupBy.length > 1) this._groupBy.push(',');
      this._groupBy.push(field);
    }
    this._groupBy.push('))');
    return this;
  }

  copy(destination: string[]) {
    if (this.filter.hasFilter) {
      destination.push('filter(');
      this.filter.copyFilter(destination);
      destination.push(')');
    }
    if (this._groupBy.length) {
      destination.push('/');
      Array.prototype.push.apply(destination, this._groupBy);
    }
  }
}