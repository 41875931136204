import { Component, OnInit } from '@angular/core';
import { NotificationService, ProfileService, SuppressReloadService, WindowService } from '@app/core';
import { EnvironmentUpdateService } from '@app/environment-update.service';
import { environment } from '@env';
import { UserModalComponent } from './user-modal/user-modal.component';

@Component({
  selector: 'dev-bar',
  templateUrl: './dev-bar.component.html',
  styleUrls: ['./dev-bar.component.scss']
})
export class DevBarComponent implements OnInit {
  private static readonly ProductLinks: MenuOption[] = [
    { description: 'Chubb BOP', path: 'chubb-bop' },
    { description: 'Nautilus Artisan Contractors', path: 'nautilus-artisan' }
  ];

  products!: MenuOption[];
  showLocalOptions: boolean;
  suppressReloadPrompt: boolean;
  useLocalAimApi: boolean;
  useLocalDocumentApi: boolean;
  useLocalStatementApi: boolean;
  useLocalCertificateApi: boolean;
  useLocalData: boolean;
  useLocalPortal: boolean;
  useLocalProducers: boolean;
  useLocalProducts: boolean;
  useLocalOnboarding: boolean;
  managementAreas!: ManagementMenuOption[];
  isCollapsed = true;
  // Set the retailerId filter if it's in the URL, otherwise reset it
  retailerId: string;

  activeUser: string;

  constructor(
    private readonly profileService: ProfileService,
    private readonly windowService: WindowService,
    private readonly suppressReload: SuppressReloadService,
    private readonly environmentUpdate: EnvironmentUpdateService,
    private readonly notifySvc: NotificationService
  ) {
    this.managementAreas = this.createManagementAreas();
    this.products = DevBarComponent.ProductLinks;
    this.suppressReloadPrompt = this.suppressReload.getValue();
    this.useLocalAimApi = this.environmentUpdate.useLocalAimApi;
    this.useLocalDocumentApi = this.environmentUpdate.useLocalDocumentApi;
    this.useLocalStatementApi = this.environmentUpdate.useLocalStatementApi;
    this.useLocalCertificateApi = this.environmentUpdate.useLocalCertificateApi;
    this.useLocalData = this.environmentUpdate.useLocalData;
    this.useLocalPortal = this.environmentUpdate.useLocalPortal;
    this.useLocalProducers = this.environmentUpdate.useLocalProducersApi;
    this.useLocalProducts = this.environmentUpdate.useLocalProductsApi;
    this.useLocalOnboarding = this.environmentUpdate.useLocalOnboardingApi;
    this.showLocalOptions = this.environmentUpdate.isLocal();
  }

  async ngOnInit() {
    // Enables adding retailer ID to devmenu links
    const url: string = this.windowService.nativeWindow.location.pathname;
    const matches = url.match(/\/a[0-9]{7}\//i);
    this.retailerId = (matches?.length) ? matches[0] : '';
    await this.setLoginUser();
  }

  async login() {
    try {
      await this.notifySvc.showModalTemplate(UserModalComponent);
    }
    catch (e) {
      return; // modal cancel
    }

    this.profileService.devOAuthLogin();
  }

  logout() {
    this.profileService.devOAuthLogout();
  }

  async cookieLogin() {
    if (!this.profileService.hasLoginCookies()) {
      this.notifySvc.showToast('.MYRPSV2 cookie not present. Portal must be running on localhost for this to work.');
      return;
    } else if (environment.bypassAuth) {
      this.notifySvc.showToast('Turn environment.bypassAuth off in order to use the .MYRPSV2 cookie');
      return;
    }

    this.profileService.startPolling();
    if (!await this.setLoginUser()) {
      this.profileService.stopPolling();
      this.notifySvc.showToast('Unable to load user profile; cookie likely expired or invalid.');
    }
  }

  updatePromptSuppression(newVal: boolean) {
    this.suppressReload.setValue(newVal);
  }

  updateLocalAimApiUse(newVal: boolean) {
    this.environmentUpdate.useLocalAimApi = newVal;
  }

  updateLocalDocumentApiUse(newVal: boolean) {
    this.environmentUpdate.useLocalDocumentApi = newVal;
  }

  updateLocalStatementApiUse(newVal: boolean) {
    this.environmentUpdate.useLocalStatementApi = newVal;
  }

  updateLocalCertificateApiUse(newVal: boolean) {
    this.environmentUpdate.useLocalCertificateApi = newVal;
  }

  updateLocalDataUse(newVal: boolean) {
    this.environmentUpdate.useLocalData = newVal;
  }

  updateLocalPortalUse(newVal: boolean) {
    this.environmentUpdate.useLocalPortal = newVal;
  }

  updateLocalProducersUse(newVal: boolean) {
    this.environmentUpdate.useLocalProducersApi = newVal;
  }

  updateLocalProductsUse(newVal: boolean) {
    this.environmentUpdate.useLocalProductsApi = newVal;
  }

  updateLocalOnboardingUse(newVal: boolean) {
    this.environmentUpdate.useLocalOnboardingApi = newVal;
  }

  private async setLoginUser() {
    const prof = await this.profileService.getProfile();

    if (Object.isDefined(prof) && prof.isAuthenticated) {
      this.activeUser = prof.firstName + ' ' + prof.lastName;
      return true;
    } else {
      this.activeUser = null;
      return false;
    }
  }

  private createManagementAreas() {
    return [
      { description: 'Accounting', route: 'accounting' },
      { description: 'Edit Content', route: 'content' },
      { description: 'User', route: 'users' },
      { description: 'Products', route: 'products' }
    ] as ManagementMenuOption[];
  }
}

interface MenuOption {
  description: string;
  path: string;
}

interface ManagementMenuOption extends MenuOption {
  route: string;
}