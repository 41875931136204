import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Debug } from '@app/core/debug';
import { SingularPluralPair } from './paginator.interfaces';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() total: number;
  @Input() page = 1;
  @Output() pageChange = new EventEmitter<number>();
  @Input() pageSize = 25;
  @Output() pageSizeChange = new EventEmitter<number>();
  @Input() plurals?: SingularPluralPair;

  constructor(
    private readonly window: Window
  ) { }

  // Intercept setting of the page so we can trigger a refresh
  protected setPage(page: number) {
    if (page !== this.page) {
      Debug.debug('[Paginator] Emitting page event:', page);
      this.page = page;
      this.pageChange.emit(page);
    }
  }

  // Intercept setting of the page size so we can trigger a refresh
  protected setPageSize(pageSize: number) {
    if (pageSize !== this.pageSize) {
      Debug.debug('[Paginator] Emitting page size event:', pageSize);
      if (this.total > pageSize || this.total > this.pageSize) {
        this.pageSize = pageSize;
        this.pageSizeChange.emit(pageSize);
      }
    }
  }

  scrollToTop() {
    this.window.scrollTo(0, 0);
  }
}
