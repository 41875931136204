import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { merge } from 'lodash';

@Injectable()
export class RouterStateService {

  constructor(private router: Router) { }

  collateRouterState() {
    // this is probably not correct but the documentation is realllly bad
    const snap = this.router.routerState.snapshot.root;
    const data = { ...snap.data };
    const params = { ...snap.params };
    let c = snap.firstChild;
    while (c) {
      merge(data, c.data);
      merge(params, c.params);
      c = c.firstChild;
    }
    return { params, data } as RouterStateData;
  }
}

export class RouterStateData {
  data: JsonObject;
  params: JsonObject;
}
