import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthHttpInterceptor, Debug, ODataResponse, QueryResult } from '@app/core';
import { DownloadOptions, DownloadPollingLinks } from '@app/core/download/download.interfaces';
import { DownloadService } from '@app/core/download/download.service';
import { environment } from '@env';
import { firstValueFrom, of } from 'rxjs';
import { ApiErrorService } from '../api-error.service';
import { AutoCancelHttpService } from '../auto-cancel-http.service';
import {
  AimAccountingSummary,
  AimDriverSchedule,
  AimInsured,
  AimOpenInvoice,
  AimPolicyDocument,
  AimPropertySchedule,
  AimRenewal,
  AimRetailer,
  AimRetailerContact,
  AimSubmission,
  AimSubmissionStageCodes,
  AimTeam,
  AimUser,
  AimVehicleSchedule,
  AimVersion,
  ExportType
} from './aim.interfaces';

@Injectable({
  providedIn: 'root'
})
export class AimService {
  readonly documentQueueName = 'document'; // Name of the polling queue for the download service
  private submissionStageCache: AimSubmissionStageCodes[]; // Store status codes and descriptions for stages

  constructor(
    private readonly dl: DownloadService,
    private readonly cancel: AutoCancelHttpService,
    private readonly http: HttpClient,
    private readonly error: ApiErrorService
  ) { }

  /**
 * Fetches a JSON array of submission overview data, using local data if that option is enabled
 * @param [odataQuery] '' string: Optional oData query string
 * @returns Promise Odata query result
 */
  getSubmissions(odataQuery = '') {
    Debug.debug('getList', `OdataQuery: ${odataQuery}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local submission list data');
      return this.cancel.get<ODataResponse<AimSubmission>>('assets/specData/retailer/submissions/submissionsList.json');
    }
    odataQuery = String.prepend(odataQuery, '?');
    return this.cancel.get<ODataResponse<AimSubmission>>([environment.aimApiUri, 'odata/submission/', odataQuery].join(''), true, true);
  }

  /**
   * Fetches a JSON array of submission overview data, using local data if that option is enabled
   * @param [odataQuery] '' string: Optional oData query string
   * @returns Promise of raw count
   */
  getSubmissionsCount(odataQuery = '') {
    Debug.debug('getSubmissionsCount', `OdataQuery: ${odataQuery}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local submission list count');
      return Promise.resolve(5428383);
    }
    odataQuery = String.prepend(odataQuery, '?');
    return this.cancel.get<number>([environment.aimApiUri, 'odata/submission/$count', odataQuery].join(''));
  }

  /**
   * Fetches a single submission overview, using local data if that option is enabled
   * @param [submissionId] string: Submission (quote) ID
   * @returns Promise query result
   */
  getSubmission(submissionId = '') {
    Debug.debug('getSubmission', `SubmissionId: ${submissionId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local submission data');
      return this.cancel.get<AimSubmission>('assets/specData/retailer/submissions/submission.json');
    }
    return this.cancel.get<AimSubmission>([environment.aimApiUri, 'api/submission/', submissionId].join(''));
  }

  /**
 * Fetches a JSON array of open invoices data, using local data if that option is enabled
 * @param [odataQuery] '' string: Optional oData query string
 * @returns Promise Odata query result
 */
  getOpenInvoices(odataQuery = '') {
    Debug.debug('getList', `OdataQuery: ${odataQuery}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local open invoices list');
      return this.cancel.get<ODataResponse<AimOpenInvoice>>('assets/specData/retailer/open-invoices/openInvoicesList.json');
    }
    odataQuery = String.prepend(odataQuery, '?');
    return this.cancel.get<ODataResponse<AimOpenInvoice>>([environment.aimApiUri, 'odata/invoice', odataQuery].join(''), true, true);
  }

  /**
  * Gets an xlsx-formatted list of filtered results
  * @returns Promise w/HttpResponse object containing CSV data
  *          On error: HTTP error object,
  *          (`200 OK` is the expected response),
  */
  getSubmissionsExport(odataQuery = '', exportType = ExportType.Retailer) {
    Debug.debug('getExportedList', `OdataQuery: ${odataQuery}`);
    if (environment?.useLocalData) {
      Debug.log('Returning empty export file');
      return new HttpResponse<Blob>({ body: new Blob(), status: 501, statusText: 'Not implemented' });
    }
    odataQuery = String.prepend(odataQuery, '?');
    return firstValueFrom(this.http.get(
      [environment.aimApiUri, 'odata/submission/', odataQuery].join(''),
      {
        responseType: 'blob',
        observe: 'response',
        headers: {
          'addAuthorization': 'true',
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Rps-Export-Type': exportType
        }
      }
    ));
  }

  /**
   * Fetches a JSON array of producer/agency/retailer details, using local data if that option is enabled
   * @param [producerId] string: Agency code (eg: 'A0006181')
   * @returns Observable query result
   */
  getProducer(producerId: string) {
    Debug.debug('getProducer', `Fetching producer data for ${producerId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local producer data');
      return this.cancel.get<AimRetailer>('assets/specData/retailer/submissions/producer.json');
    } else {
      return this.cancel.get<AimRetailer>([environment.aimApiUri, 'api/Producer/', producerId].join(''));
    }
  }

  /**
 * Fetches a JSON array of submission accounting summary data, using local data if that option is enabled
 * @param [submissionId] string: SubmissionID
 * @returns Promise query result
 */
  getSummary(submissionId: string) {
    Debug.debug('getSummary', `Fetching accounting summary for ${submissionId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local accounting summary data');
      return this.cancel.get<AimAccountingSummary>('assets/specData/retailer/submissions/accountingSummary.json');
    }
    return this.cancel.get<AimAccountingSummary>([environment.aimApiUri, 'api/Submission/', submissionId, '/invoice-summary'].join(''));
  }

  /**
   * Fetches a JSON array of user data, using local data if that option is enabled
   * @param [userId] string: UserID
   * @returns Promise query result
   */
  getUser(userId: number) {
    Debug.debug('getUser', `Fetching user data for ${userId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local user data');
      return this.cancel.get<AimUser>('assets/specData/retailer/submissions/user.json');
    }
    return this.cancel.get<AimUser>([environment.aimApiUri, 'api/User/', userId].join(''));
  }

  /**
   * Fetches a JSON array of user data, using local data if that option is enabled
   * @param [odataQuery] '' string: Optional oData query string
   * @returns Promise Odata query result
   */
  getUsers(odataQuery = '') {
    Debug.debug('getUsers', `OdataQuery: ${odataQuery}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local user data');
      return this.cancel.get<ODataResponse<AimUser>>('assets/specData/retailer/submissions/userList.json');
    }
    odataQuery = String.prepend(odataQuery, '?');
    return this.cancel.get<ODataResponse<AimUser>>([environment.aimApiUri, 'odata/user/', odataQuery].join(''));
  }

  /**
   * Fetches a JSON array of submission overview data, using local data if that option is enabled
   * @param [insuredId] string: InsuredID from submission data
   * @returns Promise query result
   */
  getInsured(insuredId: string | number) {
    Debug.debug('getInsured', `Fetching insured data for ${insuredId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local insured data');
      return this.cancel.get<AimInsured>('assets/specData/retailer/submissions/insured.json');
    }
    return this.cancel.get<AimInsured>([environment.aimApiUri, 'api/Insured/', insuredId].join(''));
  }

  /**
   * Fetches a JSON array of submission overview data, using local data if that option is enabled
   * @param [submissionId] string: submissionID from submission data
   * @returns Promise query result
   */
  getActiveVersion(submissionId: string) {
    Debug.debug('getActiveVersion', `Fetching version data for ${submissionId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local version data');
      return this.cancel.get<AimVersion>('assets/specData/retailer/submissions/version.json');
    }
    return this.cancel.get<AimVersion>([environment.aimApiUri, 'api/Submission/', submissionId, '/active-version'].join(''));
  }

  /**
   * Fetches a JSON array of submission overview data, using local data if that option is enabled
   * @param [submissionId] string: submissionID from submission data
   * @returns Promise query result
   */
  getVersions(submissionId: string) {
    Debug.debug('getVersionList', `Fetching versions data for ${submissionId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local versions data');
      return this.cancel.get<QueryResult<AimVersion>>('assets/specData/retailer/submissions/versionList.json');
    }
    return this.cancel.get<QueryResult<AimVersion>>([environment.aimApiUri, 'api/Submission/', submissionId, '/version'].join(''));
  }

  /**
   * Fetches an QueryResult-formatted JSON array of vehicle schedule data, using local data if that option is enabled
   * @param [submissionId] string: SubmissionId from submission data
   * @returns Promise query result
   */
  getVehicles(submissionId: string) {
    Debug.debug('getVehicleList', `Fetching vehicle data for ${submissionId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local vehicle data');
      return this.cancel.get<QueryResult<AimVehicleSchedule>>('assets/specData/retailer/submissions/vehicleList.json');
    }
    return this.cancel.get<QueryResult<AimVehicleSchedule>>([environment.aimApiUri, 'api/Submission/', submissionId, '/vehicles'].join(''));
  }

  /**
   * Fetches an QueryResult-formatted JSON array of driver schedule data, using local data if that option is enabled
   * @param [submissionId] string: SubmissionId from submission data
   * @returns Promise query result
   */
  getDrivers(submissionId: string) {
    Debug.debug('getDriverList', `Fetching driver data for ${submissionId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local driver data');
      return this.cancel.get<QueryResult<AimDriverSchedule>>('assets/specData/retailer/submissions/driverList.json');
    }
    return this.cancel.get<QueryResult<AimDriverSchedule>>([environment.aimApiUri, 'api/Submission/', submissionId, '/drivers'].join(''));
  }

  /**
   * Fetches an QueryResult-formatted JSON array of property schedule data, using local data if that option is enabled
   * @param [submissionId] string: SubmissionId from submission data
   * @returns Promise query result
   */
  getProperties(submissionId: string) {
    Debug.debug('getPropertyList', `Fetching property data for ${submissionId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local property data');
      return this.cancel.get<QueryResult<AimPropertySchedule>>('assets/specData/retailer/submissions/propertyList.json');
    }
    return this.cancel.get<QueryResult<AimPropertySchedule>>([environment.aimApiUri, 'api/Submission/', submissionId, '/properties'].join(''));
  }

  /**
   * Fetches a JSON object of renewal details including an array of the full history, using local data if that option is enabled
   * @param [quoteId] string: quoteId from submission data
   * @returns Promise query result
   */
  getRenewalHistory(quoteId: string) {
    Debug.debug('getRenewalHistory', `Fetching renewal history data for ${quoteId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local renewal history data');
      return this.cancel.get<AimRenewal>('assets/specData/retailer/submissions/renewalHistory.json');
    } else {
      return this.cancel.get<AimRenewal>([environment.aimApiUri, 'api/submission/', quoteId, '/renewal-history'].join(''));
    }
  }

  /**
   * Fetches a JSON object of retailer contact data, using local data if that option is enabled
   * @param [contactId] number: ContactID from submission data, [retailerId] string: retailer.Id
   * @returns Promise query result
   */
  getRetailerContact(retailerId: string, contactId: number) {
    Debug.debug('getRetailerContact', `Fetching contact data for ${contactId} of ${retailerId}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local contact data');
      return this.cancel.get<AimRetailerContact>('assets/specData/retailer/submissions/retailerContact.json');
    }
    return this.cancel.get<AimRetailerContact>([environment.aimApiUri, 'api/Producer/', retailerId, '/contacts/', contactId].join(''));
  }

  /**
   * Fetches a JSON array of stages and their status codes, using local data if that option is enabled and format the codes and descriptions, then cache it.
   * @param [includeCode] boolean: include statuscode if true
   * @returns Promise of submission Stage Codes with descriptions
  */
  async getSubmissionStageCodes(includeCode = true) {
    Debug.debug('getSubmissionStageCodes', 'Fetching getSubmissionStageCodes');

    if (environment?.useLocalData) {
      Debug.log('Returning local stage codes data');
      this.submissionStageCache = await this.cancel.get<AimSubmissionStageCodes[]>('assets/specData/retailer/submissions/submissionStageList.json');
    } else {
      try {
        if (!this.submissionStageCache) {
          this.submissionStageCache = await this.cancel.get<AimSubmissionStageCodes[]>([environment.aimApiUri, 'api/SubmissionStage'].join(''));
        }
      } catch (e) {
        this.error.processError(e, 'getSubmissionStages');
      }
    }
    return new Map<string, string>(this.submissionStageCache?.map(s => [s.id, (s.codes.map(c => includeCode ? `${c.id}: ${c.name}` : c.name)).join('\n')]));
  }

  /**
   * Fetches a JSON array of policy documents, using local data if that option is enabled
   * @param [id] string: quoteId/policyNumber from submission data
   * @param [allowAll] bool: true if request is from producer web and false if from retailer
   * @returns Promise query result
   */
  getDocuments(id: string, allowAll = false) {
    Debug.debug('getDocumentList', `Fetching document data for ${id}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local versions data');
      return this.cancel.get<QueryResult<AimPolicyDocument>>('assets/specData/retailer/submissions/documentList.json');
    }
    return this.cancel.post<QueryResult<AimPolicyDocument>>(
      [environment.documentApiUri, 'api/find'].join(''),
      { QuoteId: id, AllowAll: allowAll }
    );
  }

  /**
  * Downloads a policy document, no local data option available
  * Requires the download service (startBrowserDownload) to initiate browser download
  * @param [id] string: documentId from documentList data
  * @returns Promise w/HttpResponse blob containing the document file
  *          On error: HTTP error object,
  *          (`200 OK` is the expected response),
  */
  downloadDocument(id: string) {
    Debug.debug('downloadDocument', `Document ID: ${id}`);
    if (environment?.useLocalData) {
      Debug.log('Returning empty document file');
      return of(new HttpResponse<Blob>({ body: new Blob(), status: 501, statusText: 'Not implemented' }));
    }
    return this.http.get(
      [environment.documentApiUri, 'api/', id, '/download'].join(''),
      { responseType: 'blob', observe: 'response', headers: { 'addAuthorization': 'true' } }
    );
  }

  /**
   * Fetches a polling link for a queued download and begins polling
   * Will initiate download upon completion
   * Displays a toast if any errors occur
   * @param [idArray] string[]: Array of documentId's to queue for download
   * @returns Void, but will throw any errors it encounters in HttpErrorResponse format
   */
  async downloadDocumentZip(idArray: string[], options: DownloadOptions = {}) {
    Debug.debug('downloadDocumentZip', `Array: ${idArray}`);
    let result: DownloadPollingLinks;
    if (environment?.useLocalData) {
      result = { url: 'http://localhost:4200' };
    } else {
      result = await firstValueFrom(this.http.post<{ url: string; }>(
        [environment.documentApiUri, 'api/zip'].join(''),
        { ids: idArray },
        AuthHttpInterceptor.AddAuthHttpHeader
      ));
    }
    Debug.debug('downloadDocumentZip', 'Queue Response:', result);
    if (result.url) {
      if (result.cancelUrl) {
        options.pollingOptions.cancelUrl = result.cancelUrl;
      }
      this.dl.queueDownload(result.url, this.documentQueueName, options);
    } else {
      const error = new HttpErrorResponse({ status: 500, statusText: 'Unexpected server response' });
      throw error;
    }
  }

  /**
 * Fetches a JSON array of team details, using local data if that option is enabled
 * @param [odataQuery] '' string: Optional oData query string
 * @returns Promise Odata query result
 */
  getTeamDetails(odataQuery = '') {
    Debug.debug('getTeamDetails', `OdataQuery: ${odataQuery}`);
    if (environment?.useLocalData) {
      Debug.log('Returning local team details');
      return this.cancel.get<ODataResponse<AimTeam>>('assets/specData/retailer/submissions/team.json');
    }
    odataQuery = String.prepend(odataQuery, '?');
    return this.cancel.get<ODataResponse<AimTeam>>([environment.aimApiUri, 'odata/team/', odataQuery].join(''));
  }
}
