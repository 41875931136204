import { Resource } from '@core/index';

export interface AddressComponentOptions {
  includeAddressLineTwo?: boolean;

  includeUnitNumber?: boolean;
  requireUnitNumber?: boolean;

  includeDistrict?: boolean;
  requireDistrict?: boolean;

  requireValidStreet?: boolean;
  requireCompleteAddress?: boolean;
}

export interface AddressFieldComponentOptions extends AddressComponentOptions {
  allowDisplayTypeChange?: boolean;
  displayType?: AddressFieldDisplayType;
}

export interface AddressManualLabelOptions {
  addressLineOne?: string | Resource;
  addressLineTwo?: string | Resource;
  unitNumber?: string | Resource;
  postalCode?: string | Resource;
  locality?: string | Resource;
  region?: string | Resource;
  district?: string | Resource;
}

export interface AddressFieldLabelOptions extends AddressManualLabelOptions {
  autocomplete?: string | Resource;
  hideAutocompleteLabel?: boolean;
}

export enum AddressFieldDisplayType {
  AutoComplete,
  Manual,
  TextOnly
}
