import { Component } from '@angular/core';
import { ModalSpinnerService } from './modal-spinner.service';

@Component({
  selector: 'modal-spinner',
  templateUrl: './modal-spinner.component.html',
  styleUrls: ['./modal-spinner.component.scss']
})
export class ModalSpinnerComponent {

  constructor(protected spinner: ModalSpinnerService) { }
}
