// interface for security/api/role/get/{id}

export interface Role {
  id?: number;
  name: string;
  description: string;
  appliedBy: AppliedBy;
  memberCount?: number;
  permissions?: string[];
  routes?: { [key: string]: string; };
}

export enum AppliedBy {
  None = 0,
  Employee = 1,
  Retailer = 2,
  Carrier = 4,
  All = Employee | Retailer | Carrier
}