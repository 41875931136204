
/* The LowerCaseUrlSerializer handles the case sensitivity of the URL path for everything but the base href.
 * Unfortunately, the base href is also case sensitive. /Portal-client/other/path will not be routed correctly
 * like /portal-client/other/path would be.
 **/

// https://stackoverflow.com/a/62994358
export function baseHrefFactory() {
  // in stage and production the app is hosted out of /portal-client. if this changes, this will have to change
  const expectedBaseHref = '/portal-client';
  const currentBaseHref = window.location.pathname.substring(0, expectedBaseHref.length);
  if (String.equalsIgnoreCase(expectedBaseHref, currentBaseHref) && expectedBaseHref !== currentBaseHref) {
    // The two base hrefs are the same, but not the same case.
    // Redirect the user to the correct base href.
    const newLocation = window.location.href.replace(currentBaseHref, expectedBaseHref);
    window.location.replace(newLocation);
  }
}