import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ProfileService, UserProfile, UserType, WindowService } from "@app/core";
import { environment } from "@env";

@Injectable({
  providedIn: 'root'
})
export class HomeRedirectService {

  constructor(
    private readonly router: Router,
    private readonly profile: ProfileService,
    private readonly windowService: WindowService) {

  }

  //convenience function
  isLocalhost() {
    return this.windowService.isLocalhost();
  }

  //convenience function
  async activeUser() {
    return this.profile.getProfile();
  }

  async redirect(profile: UserProfile = null) {
    if (!profile) {
      profile = await this.profile.getProfile();
    }

    if (this.windowService.isLocalhost()) {
      this.router.navigateByUrl('/');
    } else if (profile.userType == UserType.Internal) {
      if (profile.roles.includes('RampAdmin')) {
        this.windowService.nativeWindow.location.assign(environment.rampAdminHomeUri);
      } else if (profile.isAccountExec) {
        this.router.navigateByUrl('/producer/home');
      } else {
        // let portal deal with them
        this.windowService.nativeWindow.location.assign(environment.myrpsUri + '/portal/account');
      }
    } else if (profile.permissions.includes('restricted-user')) {
      this.router.navigateByUrl('/onboarding/resume');
    } else {
      // assume external
      this.router.navigateByUrl('/retailer/home');
    }
  }
}