import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';

@Directive({
  selector: '[minValue]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MinValueValidator),
    multi: true
  }]
})
export class MinValueValidator implements Validator {
  @Input() minValue?: number;

  validate(control: UntypedFormControl): { [key: string]: any; } {
    if (Object.isUndefined(this.minValue)) {
      return null;
    }

    if ((isNaN(control.value)) || (!isFinite(control.value)) || (control.value < this.minValue)) {
      return { maxValue: { valid: false } };
    }

    return null;
  }

}
