import { formatDate } from '@angular/common';
import { environment } from '@env';
import { FilterBuilder } from './odata.interfaces';

export class ODataQueryFilterBuilder<T> implements FilterBuilder {
  private openGroups: number;
  private filter: (string | boolean | Date | number | null)[];

  constructor(private readonly _query: T, private readonly allowEmptyStrings = false) {
    this.filter = [];
    this.openGroups = 0;
  }

  // Performs a pure boolean comparison (no quotes)
  isTrue(field: string) {
    return this.appendComparison(field, 'eq', true);
  }

  // Performs a pure boolean comparison (no quotes)
  isFalse(field: string) {
    return this.appendComparison(field, 'eq', false);
  }

  // Performs a pure null comparison (no quotes)
  isNull(field: string) {
    return this.appendComparison(field, 'eq', 'null', false);
  }

  // Performs a pure not-null comparison (no quotes)
  notNull(field: string) {
    return this.appendComparison(field, 'ne', 'null', false);
  }

  equal(field: string, value: number | string | Date | boolean) {
    return this.appendComparison(field, 'eq', value);
  }

  notEqual(field: string, value: number | string | Date | boolean) {
    return this.appendComparison(field, 'ne', value);
  }

  greaterEqual(field: string, value: number | string | Date | boolean) {
    return this.appendComparison(field, 'ge', value);
  }

  greaterThan(field: string, value: number | string | Date | boolean) {
    return this.appendComparison(field, 'gt', value);
  }

  lessEqual(field: string, value: number | string | Date | boolean) {
    return this.appendComparison(field, 'le', value);
  }

  lessThan(field: string, value: number | string | Date | boolean) {
    return this.appendComparison(field, 'lt', value);
  }

  contains(field: string, value: string | number) {
    return this.buildContains(field, value, false);
  }

  notContains(field: string, value: string | number) {
    return this.buildContains(field, value, true);
  }

  buildContains(field: string, value: string | number, not: boolean) {
    if (field && this.hasValidValue(value)) {
      const notString: string = not ? 'not ' : '';
      if (typeof value === 'string') {
        this.filter.push(`${notString}contains(${field}, '${this.escapeString(value)}')`);
      } else {
        this.filter.push(`${notString}contains(${field}, ${value})`);
      }
    }
    return this;
  }

  startsWith(field: string, value: string) {
    if (field && this.hasValidValue(value)) {
      this.filter.push(`startswith(${field}, '${this.escapeString(value)}')`);
    }
  }

  isIn(field: string, values: number[] | string[], useLegacyOdata: boolean = false) {
    if (field && Array.isNotEmpty<number | string>(values)) {
      this.filter.push(useLegacyOdata ? `in(${field}, [` : `${field} in(`);
      for (let i = 0; i < values.length; i++) {
        if (i > 0) this.filter.push(',');
        if (typeof values[i] === 'string') {
          this.filter.push(`'${this.escapeString(<string>values[i])}'`);
        } else {
          this.filter.push(`${values[i]}`);
        }
      }
      this.filter.push(useLegacyOdata ? '])' : ')');
    }
    return this;
  }

  private appendComparison(field: string, operator: string, value: number | string | Date | boolean, encloseStringInQuotes = true) {
    if (field && this.hasValidValue(value)) {
      this.filter.push(`${field} ${operator}`);
      if (encloseStringInQuotes && typeof value == 'string') {
        this.filter.push(` '${this.escapeString(value)}'`);
      } else if (value instanceof Date) {
        this.filter.push(` ${formatDate(value, 'yyyy-MM-dd', environment.locale)}`); // Odata requires this specific date format
      } else {
        this.filter.push(` ${value}`);
      }
    }
    return this;
  }

  private hasValidValue(value: any) {
    return Object.isDefined(value)
      && (typeof value != 'string'
        || value.length
        || this.allowEmptyStrings);
  }

  private escapeString(value: string) {
    if (value?.length) {
      value = value.replace(/'/g, '\'\''); // Odata requires single quotes to be escaped
      value = encodeURIComponent(value);
    }
    return value;
  }

  get query() {
    return this._query;
  }

  get hasOpenGroups() {
    return this.openGroups > 0;
  }

  get hasFilter() {
    return this.filter.length > 0;
  }

  copyFilter(destination: string[]) {
    if (this.hasFilter) {
      Array.prototype.push.apply(destination, this.filter);
      this.fixHangingJoin(destination);
    }
  }

  beginGroup() {
    this.openGroups++;
    this.filter.push('(');
    return this;
  }

  endGroup() {
    if (this.openGroups > 0) {
      this.openGroups--;

      this.fixHangingJoin(this.filter);

      const last = this.filter[this.filter.length - 1];
      if (last !== '(') {
        this.filter.push(')');
      } else {
        // remove empty group
        this.filter.pop();
      }
    }
    return this;
  }

  and() {
    this.join(' and ', ' or ');
    return this;
  }

  or() {
    this.join(' or ', ' and ');
    return this;
  }

  private join(value: string, replace: string) {
    if (!this.filter.length) return;

    const last = this.filter[this.filter.length - 1];
    // prevents a join within a group for a predicate that wasn't appended
    if (last !== '(') {
      if (last === replace) {
        // handles the case where a join was followed by a join: and().or()
        this.filter[this.filter.length - 1] = value;
      } else if (last !== value) {
        // only apply it if one wasn't already applied: and().and()
        this.filter.push(value);
      }
    }
  }

  // fix predicate followed by join with predicate that wasn't appended
  private fixHangingJoin(parts: (string | boolean | Date | number | null)[]) {
    if (!parts.length) return;

    const last = parts[parts.length - 1];

    if (last === ' and ' || last === ' or ') {
      parts.pop();
    }
  }
}
