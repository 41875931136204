import { environment } from '@env';

export enum DebugLevel {
  Disabled = 0,
  Debug,
  Log,
  Info,
  Warn,
  Error,
  Critical
}

/** Exposes console functions that will only produce output in debug environments
 * !These methods are ignored unless `environment?.debugLevel` in the environment.ts file
 * ! is less than or equal to the logging level (except for 0, which means disabled)
 * Calls to these methods will not impact production, so they can remain in the code, BUT
 * try to remove calls which are no longer necessary, as too many will clog up the consoles of other devs
 */
export class Debug {
  /**
   * Writes a message to the console if the assertion is false. (only runs if `environment?.debugLevel <= 1`)
   * @param assertion the result of a logical test; if false the assertion will be written to the console
   * @param message a string message or object to output to the console
   * @param optionalParams optional objects to use for string message substitution (if supported by browser)
   */
  public static assert(assertion: boolean, message?: any, ...optionalParams: any[]): void {
    if (environment?.debugLevel && environment.debugLevel == DebugLevel.Debug) {
      console.assert(assertion, message, optionalParams);
    }
  }

  /** Outputs messages or objects to the console (only runs if `environment?.debugLevel == 1`). Note that this
   * function uses console debug so make sure your browser is set to verbose logging.
   * @param message a message or object to output to the console
   * @param optionalParams optional objects to use for string message substitution (if supported by browser)
   */
  public static debug(message: any, ...optionalParams: any[]): void {
    if (environment?.debugLevel && environment.debugLevel == DebugLevel.Debug) {
      if (Array.isEmpty(optionalParams)) {
        console.debug(message);
      } else {
        console.debug(message, optionalParams);
      }
    }
  }

  /** Outputs messages or objects to the console (only runs if `environment?.debugLevel <= 2`)
   * @param message a message or object to output to the console
   * @param optionalParams optional objects to use for string message substitution (if supported by browser)
   */
  public static log(message: any, ...optionalParams: any[]): void {
    if (environment?.debugLevel && environment.debugLevel <= DebugLevel.Log) {
      if (Array.isEmpty(optionalParams)) {
        console.log(message);
      } else {
        console.log(message, optionalParams);
      }
    }
  }

  /** Outputs messages or objects to the console (only runs if `environment?.debugLevel <= 3`)
   * @param message a message or object to output to the console
   * @param optionalParams optional objects to use for string message substitution (if supported by browser)
   */
  public static info(message: any, ...optionalParams: any[]): void {
    if (environment?.debugLevel && environment.debugLevel <= DebugLevel.Info) {
      if (Array.isEmpty(optionalParams)) {
        console.log(message);
      } else {
        console.log(message, optionalParams);
      }
    }
  }

  /** Outputs messages or objects to the console (only runs if `environment?.debugLevel <= 4`)
   * @param message a message or object to output to the console
   * @param optionalParams optional objects to use for string message substitution (if supported by browser)
   */
  public static warn(message: any, ...optionalParams: any[]): void {
    if (environment?.debugLevel && environment.debugLevel <= DebugLevel.Warn) {
      if (Array.isEmpty(optionalParams)) {
        console.warn(message);
      } else {
        console.warn(message, optionalParams);
      }
    }
  }

  /** Outputs messages or objects to the console (only runs if `environment?.debugLevel <= 5`)
   * @param message a message or object to output to the console
   * @param optionalParams optional objects to use for string message substitution (if supported by browser)
   */
  public static error(message: any, ...optionalParams: any[]): void {
    if (environment?.debugLevel && environment.debugLevel <= DebugLevel.Error) {
      if (Array.isEmpty(optionalParams)) {
        console.error(message);
      } else {
        console.error(message, optionalParams);
      }
    }
  }

  /** Outputs messages or objects to the console (only runs if `environment?.debugLevel <= 6`)
   * @param message a message or object to output to the console
   * @param optionalParams optional objects to use for string message substitution (if supported by browser)
   */
  public static critical(message: any, ...optionalParams: any[]): void {
    if (environment?.debugLevel && environment.debugLevel <= DebugLevel.Critical) {
      if (Array.isEmpty(optionalParams)) {
        console.error(message);
      } else {
        console.error(message, optionalParams);
      }
    }
  }
}
