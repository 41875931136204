import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Debug } from '../../debug';
import { FilterListItem } from './filter-list.interfaces';

@Component({
  selector: 'filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnChanges {
  internalItems: FilterListItem[];

  @Input() resultsLabel: string;
  @Input() alwaysShowResults: boolean = false;
  @Input() items: any[];
  @Input() labelProperty?: string;
  @Input() unselectAllText?: string;
  @Output() clearItem = new EventEmitter<any>();
  @Output() clearAll = new EventEmitter<void>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.isDefined(changes.items)) {
      if (!Array.isArray(changes.items.currentValue)) {
        this.internalItems = [];
        return;
      }

      this.internalItems = changes.items.currentValue.map(val => {
        Debug.debug('Updating filter item:', val);
        return {
          label: typeof val === 'string' ? val : val[this.labelProperty],
          origItem: val
        };
      });
    }
  }

  unselectItem(item: FilterListItem) {
    Debug.debug('Removing filter item:', item);
    this.clearItem.emit(item.origItem);
  }

  unselectAll() {
    Debug.debug('Clearing all filter items');
    this.clearAll.emit();
  }

  get showResults() {
    return this.internalItems.length > 0 || this.alwaysShowResults;
  }

  get hasFilters() {
    return this.internalItems.length > 0;
  }
}
