import { Debug, DirectoryService, ProfileService } from '@core/index';
import { lastValueFrom, switchMap } from 'rxjs';
import { environment } from '../environments/environment';

export function profileServiceStartupFactory(profileService: ProfileService, directoryService: DirectoryService) {
  let promise: Promise<any>;
  if (environment.production || (!profileService.useDevModeLogin && environment.pollProfileService)) {
    profileService.startPolling();
    promise = lastValueFrom(directoryService.load());
  } else if (profileService.useDevModeLogin) {
    // executed this way so that dev startup can override polling if a stored refresh token exists
    // if the env is set to poll the profile service, we don't want to attempt an oauth login
    promise = lastValueFrom(profileService.devStartup(!environment.pollProfileService)
      .pipe(
        switchMap(prof => {
          if (environment.pollProfileService) {
            if (!prof) {
              Debug.log('profileServiceStartupFactory', 'No stored persona; polling profile service');
              profileService.startPolling();
            } else {
              Debug.log('profileServiceStartupFactory', 'Profile polling cancelled; using stored persona');
            }
          }
          return directoryService.load();
        })
      ));
  }

  return () => promise;
}
