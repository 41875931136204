import { Injectable } from '@angular/core';
import { firstValueFrom, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalSpinnerService {
  private readonly updateDelay = 500; // in milliseconds
  private activeLayers = 0;

  start() {
    this.activeLayers++;
    if (this.activeLayers == 1) {
      this.spin();
    }
  }

  stop(stopAll = false) {
    if (stopAll) {
      this.activeLayers = 0;
    }

    if (this.activeLayers) {
      this.activeLayers--;
    }
  }

  get enabled() {
    return this.activeLayers > 0;
  }

  private async spin() {
    // Set a minimum update interval to prevent high CPU usage
    await firstValueFrom(timer(this.updateDelay, -1));

    if (this.activeLayers) {
      // Queue up the next update
      this.spin();
    }
  }
}
